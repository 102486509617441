const fullScreenLoader = {
  root: {
    zIndex: "1700",
    display: "flex",
    flexDirection: "column",
  },
  description: {
    color: "#FFF",
  },
  success: {
    color: "#5ce691",
    width: "45px",
    height: "45px",
  },
  error: {
    color: "#e65055",
    width: "45px",
    height: "45px",
  },
};

export default fullScreenLoader;
