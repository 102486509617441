import React, { useEffect, useState, useRef } from "react";
import {
  Box,
  Grid,
  makeStyles,
  Fab,
  Container,
  CircularProgress,
  FormControl,
  InputLabel,
  Select,
} from "@material-ui/core";
import { useDispatch } from "react-redux";
import { dashboardActions } from "../../dashboard.actions";
import { dashboardService } from "../../dashboard.service";
import Pagination from "@material-ui/lab/Pagination";
import { GLOBAL_CONSTANTS } from "../../../../shared/constants/global.constants";
import listLessonsStyles from "./ListLessons.styles";
import AddIcon from "@material-ui/icons/Add";
import { useHistory } from "react-router-dom";
import LessonSnippet from "../../components/LessonSnippet/LessonSnippet";
import en from "../../../../../assets/languages/en.json";
import ConfirmModal from "../../../../shared/components/ConfirmModal/ConfirmModal";
import { v4 as uuidv4 } from "uuid";
import Tooltip from "@material-ui/core/Tooltip";

const useStyles = makeStyles(listLessonsStyles);

const ListLessons = () => {
  const dispatch = useDispatch();
  const styles = useStyles();
  const history = useHistory();

  const [page, setPage] = useState(GLOBAL_CONSTANTS.STEP_TYPE.DEFAULT_FIRST_STEP);

  const [count, setCount] = useState(null);
  const [lessons, setLessons] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [weekFilter, setWeekFilter] = useState(0);
  const [isOpen, setIsOpen] = useState({
    state: false,
    id: null,
  });
  const [confirm, setConfirm] = useState(false);
  const isMount = useIsMount();

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  useEffect(() => {
    if (!isMount) {
      fetchLessons();
    }
  }, [page]);

  const fetchLessons = async () => {
    setIsLoading(true);
    const filter = weekFilter > 0 ? weekFilter : "";
    const response = await dashboardService.getLessonsWithPagination(page, filter);
    if (response.success) {
      setCount(response.success.data.data.results.pagination.max_page);
      setLessons(response.success.data.data.results.page);
    }
    setIsLoading(false);
  };

  const handleIconClick = () => {
    history.push("/create-lesson");
  };

  useEffect(() => {
    console.log("[]");
    dispatch(dashboardActions.emptyLessonData());
    fetchLessons();
  }, []);

  const weeksOption = Array.from(Array(GLOBAL_CONSTANTS.NUMBER_OF_ACADEMIC_WEEKS).keys());

  const handleWeekChange = async (event) => {
    setWeekFilter(event.target.value);
  };

  useEffect(() => {
    if (!isMount) {
      setPage(1);
      fetchLessons();
    }
  }, [weekFilter]);

  return (
    <>
      <Grid contaier>
        <Grid item xs={12} classes={{ root: styles.filter }}>
          <FormControl>
            <InputLabel htmlFor="age-native-simple">{en.lesson.filterByAcademicWeek}</InputLabel>
            <Select
              native
              onChange={handleWeekChange}
              fullWidth
              classes={{
                select: styles.weekSelector,
                selectMenu: styles.weekMenu,
              }}
              inputProps={{
                name: "age",
                id: "age-native-simple",
              }}
              label="Academic week"
            >
              {weeksOption.map((week, _index) => (
                <option value={_index}>
                  {_index === 0 ? `${en.label.emptyLessonWeek}` : `${en.label.lessonWeek} ${_index}`}
                </option>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      {isLoading ? (
        <div className={styles.spinnerContainer}>
          <CircularProgress color="primary" />
        </div>
      ) : (
        <Box my={5} mx="auto">
          <Container maxWidth="lg">
            <Grid container>
              <Grid item xs={12}>
                <Grid container classes={{ root: styles.container }}>
                  {lessons.map((lesson, _index) => (
                    <Grid key={lesson.id} item xs={12} sm={6} md={4}>
                      <LessonSnippet
                        lesson={lesson}
                        fetchLessons={fetchLessons}
                        setIsOpen={setIsOpen}
                        setConfirm={setConfirm}
                        confirm={confirm}
                        isOpen={isOpen}
                      />
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            </Grid>

            <div className={styles.tansparentnBackground}>
              <Grid container className={styles.footerStyle}>
                <Grid item xs={9} sm={9} md={11}>
                  <Pagination count={count} page={page} onChange={handlePageChange} color="primary" />
                </Grid>
                <Grid item xs={3} sm={3} md={1} classes={{ item: styles.fabIcon }}>
                  <Tooltip title="Add New ">
                    <Fab color="primary" aria-label="add" onClick={handleIconClick}>
                      <AddIcon size="medium" />
                    </Fab>
                  </Tooltip>
                </Grid>
              </Grid>
            </div>
          </Container>
        </Box>
      )}
      <ConfirmModal
        isOpen={isOpen}
        body={en.lesson.confirm.deleteBody}
        title={en.lesson.confirm.deleteTitle}
        handleClose={() =>
          setIsOpen((prevState) => {
            return {
              ...prevState,
              state: false,
            };
          })
        }
        handleDelete={() => setConfirm(true)}
      />
    </>
  );
};

const useIsMount = () => {
  const isMountRef = useRef(true);
  useEffect(() => {
    isMountRef.current = false;
  }, []);
  return isMountRef.current;
};

export default ListLessons;
