import React, { useState, useRef } from "react";
import ItemsCarousel from "react-items-carousel";
import StepTip from "../StepTip/StepTIp";
import en from "../../../../../assets/languages/en.json";
import { Button } from "@material-ui/core";
import styles from "./Carousel.module.scss";
import { GLOBAL_CONSTANTS } from "../../../../shared/constants/global.constants";

const Carousel = () => {
  const containerRef = useRef(null);
  const [activeItemIndex, setActiveItemIndex] = useState(0);
  const list = Object.keys(en.carouselIntro).map(function (key, _index) {
    if (_index === 0) {
      return null;
    }
    if (_index > 4) {
      return (
        <div className={styles.stepTips}>
          <StepTip
            key={_index}
            index={_index}
            title={en.carouselIntro[key].title}
            content={en.carouselIntro[key].content}
          />
        </div>
      );
    }
    return (
      <div className={styles.stepTips}>
        <StepTip
          key={_index}
          index={_index}
          title={en.carouselIntro[key].title}
          content={en.carouselIntro[key].content}
        />
      </div>
    );
  });

  return (
    <div className={styles.container} ref={containerRef}>
      <ItemsCarousel
        requestToChangeActive={setActiveItemIndex}
        activeItemIndex={activeItemIndex}
        infiniteLoop={true}
        numberOfCards={Math.floor(
          containerRef.current?.offsetWidth / GLOBAL_CONSTANTS.WIDTH.TIP
        )}
        gutter={5}
        leftChevron={
          <Button>
            <p className={styles.container__chevronLeft}>{"<"}</p>
          </Button>
        }
        rightChevron={
          <Button>
            <p className={styles.container__chevronRight}>{">"}</p>
          </Button>
        }
        chevronWidth={GLOBAL_CONSTANTS.WIDTH.CHEVRON}
      >
        {list}
      </ItemsCarousel>
    </div>
  );
};

export default Carousel;
