import React, { useState, useEffect } from "react";
import {
  TextField,
  Grid,
  Paper,
  Box,
  Typography,
  makeStyles,
  Popover,
  Snackbar,
} from "@material-ui/core";
import SearchRoundedIcon from "@material-ui/icons/SearchRounded";
import { Alert } from "@material-ui/lab";
import searchBoxStyles from "./SearchBox.styles";
import en from "../../../../../assets/languages/en.json";
import { googleSearchBuilder } from "../../../../shared/helpers/googleSearchBuilder";
import { dashboardService } from "../../dashboard.service";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useDispatch, useSelector } from "react-redux";
import { dashboardActions } from "../../dashboard.actions";
import InfoRoundedIcon from "@material-ui/icons/InfoRounded";
import { GLOBAL_CONSTANTS } from "../../../../shared/constants/global.constants";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import { datetime } from "../../../../shared/helpers/datetime";

const useStyles = makeStyles(searchBoxStyles);
let severity = GLOBAL_CONSTANTS.SEVERITY.SUCCESS;

const SearchBox = ({ keywords }) => {
  const styles = useStyles();

  const [topic, setTopic] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [currentTopic, setCurrentTopic] = useState("");

  const existingTopic = useSelector((state) => {
    if (state.dashboardReducer.topic.length > 0) {
      return state.dashboardReducer.topic;
    }
    return "";
  });

  useEffect(() => {
    if (existingTopic.length > 0) {
      setTopic(existingTopic);
      setCurrentTopic(existingTopic);
    }
  }, [existingTopic]);

  const duration = useSelector((state) => {
    let total = 0;
    for (const stepDuration in state.dashboardReducer.duration) {
      total += state.dashboardReducer.duration[stepDuration];
    }
    return datetime.convertMinutesToHours(total);
  });

  const dispatch = useDispatch();

  const handleTopicChange = (event) => {
    setTopic(event.target.value);
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();

    if (isLoading) return;
    setCurrentTopic(topic);

    if (topic.length > 0) {
      setIsLoading(true);

      const queries = googleSearchBuilder.multipleSearch(keywords, topic);
      const response = await dashboardService.fetchResults(queries);

      if (response.success) {
        dispatch(dashboardActions.setLessonContent(response.success));
        dispatch(dashboardActions.setLessonTopic(topic));
        setSuccess(true);
      }
      if (response.error) {
        severity = GLOBAL_CONSTANTS.SEVERITY.ERROR;
        setError(true);
      }

      setIsLoading(false);
    }
  };

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const handleCloseAlert = (event, reason) => {
    if (reason === GLOBAL_CONSTANTS.CLICK_EVENTS.CLICKAWAY) {
      return;
    }

    success ? setSuccess(false) : setError(false);
  };

  const isOpen = Boolean(anchorEl);

  return (
    <>
      <div className={styles.searchForm}>
        <form onSubmit={handleFormSubmit}>
          <Paper elevation={3}>
            <Box py={1} px={3}>
              <Grid container justify="center" alignItems="center">
                <Grid item xs={10}>
                  <Typography
                    align="center"
                    variant="body2"
                    className={styles.gridStyle}
                  >
                    {en.lesson.duration}
                    {duration ?? 0}
                  </Typography>
                  <Typography align="center" className={styles.topic}>
                    {en.lesson.topic}
                    <div
                      aria-owns={
                        isOpen
                          ? GLOBAL_CONSTANTS.CLICK_EVENTS_MOUSE_OVER_POPOVER
                          : undefined
                      }
                      aria-haspopup="true"
                      onMouseEnter={handlePopoverOpen}
                      onMouseLeave={handlePopoverClose}
                    >
                      <InfoRoundedIcon />
                    </div>
                  </Typography>

                  <TextField
                    type="text"
                    name="topic"
                    value={isLoading ? en.searching : topic}
                    onChange={handleTopicChange}
                    disabled={isLoading}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment>
                          <IconButton>
                            <SearchIcon onClick={handleFormSubmit} />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              </Grid>
            </Box>
          </Paper>
        </form>
      </div>
      <Popover
        id="mouse-over-popover"
        open={isOpen}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        className={styles.popover}
        classes={{
          paper: styles.paper,
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        {en.lesson.info}
      </Popover>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        open={success || error}
        autoHideDuration={GLOBAL_CONSTANTS.DELAY.SNACKBAR}
        onClose={handleCloseAlert}
      >
        <Alert severity={severity}>
          {success && en.alert.googleSearchSuccess}
          {error && en.alert.googleSearchError}
        </Alert>
      </Snackbar>
    </>
  );
};

export default SearchBox;
