import customClasses from '../../../../shared/helpers/customClasses';

const profileCardStyles = {
  form: {
    ...customClasses.form,
    position: 'relative',
    
  },
  root: {
    height:"44em",
  },
  form__passLabel: {
    textAlign: "left",
  },
  icon: {
    fontSize: '1.8rem',
  },
  form__button: {
    position: 'absolute',
    top: '38.5em',
    left:'40%',
  },
};

export default profileCardStyles;