const theme = {
  overrides: {
    MuiToolbar: {
      root: {
        justifyContent: "space-between",
      },
    },
    MuiTypography: {
      colorPrimary: {
        color: "#f7931f",
      },
      colorSecondary: {
        color: "#433F40",
      },
      colorTextPrimary: {
        color: "#1976D2",
      },
      white: {
        color: "#fff",
      },
    },
  },
  palette: {
    primary: {
      main: "#f7931f",
      iconColor: "#333",
      unEditedStep: "#d8d8d8d8",
      tabs: "#675A3B",
      notes: "#FDFDC8",
      Acquiring: "#FFEECA",
      Producing: "#FD8B7C",
      Discussing: "#FF7EBC",
      Collaborating: "#00D8FF",
      Practising: "#00D6B1",
      Inquiring: "#C3FBF2",
    },
    secondary: {
      main: "#2196f3",
    },
    custom: {
      light: "#7F7C4F",
      main: "#FFFFFF",
      dark: "#7F7C4F",
    },
  },
};

export default theme;
