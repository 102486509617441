import React from "react";
import PropTypes from "prop-types";
import { makeStyles, useTheme } from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import ImagePicker from "./ImagePicker";
import HighlightOffRoundedIcon from "@material-ui/icons/HighlightOffRounded";
import Dropzone from "../DropZone/DropZone";
import Modal from "@material-ui/core/Modal";
import { Button, Grid } from "@material-ui/core";
import TabPanel from "./Tabs/TabPanel";
import TabIndex from "./Tabs/TabIndex";
import stepType from "../../../../shared/constants/step.constants";
import imagePickerStyles from "./ImagePicker.Styles";

export default function SimpleTabs({
  handleImageUpload,
  file,
  description,
  accept,
  fileName,
  handleImageDelete,
  inputs,
  setInputs,
}) {
  const theme = useTheme();
  const classes = imagePickerStyles(theme);
  const [value, setValue] = React.useState(0);
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <Button
        variant="contained"
        color="primary"
        disableRipple
        onClick={handleOpen}
        className={classes.btn}
      >
        Upload Cover Image
      </Button>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
      >
        <div className={classes.root}>
          <AppBar position="static">
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="simple tabs example"
            >
              <Tab
                label="Upload  Image"
                {...TabIndex(0)}
                className={classes.firstTab}
              />
              <Tab
                label="Choose an image from  gallery"
                className={classes.secondTab}
                {...TabIndex(1)}
              />

              <Box m={1} className={classes.quitButton}>
                <HighlightOffRoundedIcon
                  fontSize="large"
                  onClick={handleClose}
                />
              </Box>
            </Tabs>
          </AppBar>
          <TabPanel value={value} index={0} className={classes.tabPanelStyle}>
            <Grid container>
              <Grid item xs={12}>
                <Dropzone
                  handleImageUpload={handleImageUpload}
                  file={file}
                  description={description}
                  accept={accept}
                  fileName={fileName}
                  handleClose={handleClose}
                  handleImageDelete={handleImageDelete}
                  lessonForm={true}
                  stepTypeFile={stepType.IMAGE_FROM_LESSON_FORM}
                  inputs={inputs}
                />
              </Grid>
              <Grid item xs={12}>
                <div className={classes.divStyle}>
                  <Box marginTop={8}>
                    <Button
                      variant="contained"
                      color="primary"
                      disableRipple
                      onClick={handleClose}
                    >
                      Next
                    </Button>
                  </Box>
                </div>
              </Grid>
            </Grid>
          </TabPanel>
          <TabPanel
            value={value}
            index={1}
            className={classes.tabPanelImagePicker}
          >
            <Grid container>
              <Grid item xs={12}>
                <ImagePicker
                  handleImageUpload={handleImageUpload}
                  handleClose={handleClose}
                  inputs={inputs}
                  setInputs={setInputs}
                />
              </Grid>
            </Grid>
          </TabPanel>
        </div>
      </Modal>
    </>
  );
}
