import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { authActions } from "../../auth.actions";
import { GLOBAL_CONSTANTS } from "../../../../shared/constants/global.constants";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Alert from "@material-ui/lab/Alert";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import en from "../../../../../assets/languages/en.json";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import signUpStyles from "./SIgnUp.styles";

const useStyles = makeStyles(signUpStyles);

const SignUp = ({ setForm }) => {
  const styles = useStyles();
  const [inputs, setInputs] = useState({
    name: "",
    surname: "",
    email: "",
    password: "",
    confirmPassword: "",
  });

  const [hasSignUpAttempt, setHasSignUpAttempt] = useState(false);
  const [show, setShow] = useState(false);
  const [passwordError, setPasswordError] = useState({
    passwordStrength: false,
    passwordMatch: false,
  });
  const [error, setError] = useState(false);

  const isLoading = useSelector((state) => state.authReducer.loading);
  const isPending = useSelector(
    (state) => state.authReducer.confirmationPending
  );
  const dispatch = useDispatch();

  useEffect(() => {
    if (isLoading) {
      setHasSignUpAttempt(true);
    }
  }, [isLoading]);

  useEffect(() => {
    if (hasSignUpAttempt && !isPending && !isLoading) {
      setError(true);
      setHasSignUpAttempt(false);
    }
    if (!isLoading && isPending) {
      setShow(true);
    }
  }, [hasSignUpAttempt, isLoading]);

  const handleInputChange = (e) => {
    setInputs((prevInputs) => {
      return {
        ...prevInputs,
        [e.target.name]: e.target.value,
      };
    });
  };

  const validatePassword = (password, confirmPassword) => {
    const arePasswordsMatching = password === confirmPassword;
    const hasPasswordSpecialChars = new RegExp(
      GLOBAL_CONSTANTS.REGEX.PASSWORD_VALIDATION
    ).test(password);
    const isPasswordLong =
      password.length > GLOBAL_CONSTANTS.PASSWORD_MINIMUM_LENGTH;
    const isPasswordStrong = hasPasswordSpecialChars && isPasswordLong;

    return {
      arePasswordsMatching,
      isPasswordStrong,
    };
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    setPasswordError({
      passwordStrength: false,
      passwordMatch: false,
    });

    const validation = validatePassword(
      inputs.password,
      inputs.confirmPassword
    );
    if (!validation.arePasswordsMatching) {
      setPasswordError((prevPasswordError) => {
        return {
          ...prevPasswordError,
          passwordMatch: true,
        };
      });
    }
    if (!validation.isPasswordStrong) {
      setPasswordError((prevPasswordError) => {
        return {
          ...prevPasswordError,
          passwordStrength: true,
        };
      });
    }
    if (validation.arePasswordsMatching && validation.isPasswordStrong) {
      dispatch(authActions.signUp(inputs));
    }
  };

  const handleConfirmationModal = () => {
    setForm(true);
    dispatch(authActions.closeConfirmationModal);
  };

  useEffect(() => {
    let timer = null;
    if (error) {
      timer = setTimeout(() => {
        setError(false);
      }, 2000);
    }

    return () => clearTimeout(timer);
  }, [error]);

  return (
    <>
      <Grid container justify="center" className={styles.root}>
        <Grid item>
          <Typography variant="h3" color="primary" className={styles.label}>
            {en.authentication.labelSignUp}
          </Typography>
          <Grid item xs={12}>
            {isLoading ? (
              <div className={styles.spinner}>
                <CircularProgress />
              </div>
            ) : (
              <form onSubmit={handleSubmit} className={styles.form}>
                <TextField
                  label={en.label.name}
                  fullWidth
                  required
                  type="text"
                  value={inputs.name}
                  onChange={handleInputChange}
                  name="name"
                />
                <TextField
                  label={en.label.surname}
                  fullWidth
                  required
                  type="text"
                  value={inputs.surname}
                  onChange={handleInputChange}
                  name="surname"
                />
                <TextField
                  label={en.label.email}
                  placeholder={en.label.emailExample}
                  fullWidth
                  required
                  type="email"
                  value={inputs.username}
                  onChange={handleInputChange}
                  name="email"
                />
                <TextField
                  label={en.label.password}
                  fullWidth
                  required
                  type="password"
                  value={inputs.password}
                  onChange={handleInputChange}
                  name="password"
                  error={passwordError.passwordStrength ? true : false}
                  helperText={
                    passwordError.passwordStrength
                      ? en.authentication.passwordStrength
                      : null
                  }
                />
                <TextField
                  label={en.label.confirmPassword}
                  fullWidth
                  required
                  type="password"
                  value={inputs.confirmPassword}
                  onChange={handleInputChange}
                  name="confirmPassword"
                  error={passwordError.passwordMatch ? true : false}
                  helperText={
                    passwordError.passwordMatch
                      ? en.authentication.passwordMatch
                      : null
                  }
                />
                <Button
                  type="submit"
                  disableRipple
                  variant="contained"
                  className={styles.button}
                >
                  <Typography color="secondary">
                    {en.authentication.labelSignUp}
                  </Typography>
                </Button>
              </form>
            )}
          </Grid>
        </Grid>
        <Grid item>
          {error && (
            <Alert severity="error">{en.authentication.erroSignUp}</Alert>
          )}
        </Grid>
      </Grid>
      <Dialog
        disableBackdropClick
        open={isPending}
        onClose={handleConfirmationModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{en.modal.auth}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {en.authentication.confirmModal}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleConfirmationModal} color="primary" autoFocus>
            {en.button.ok}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default SignUp;
