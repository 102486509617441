import { AXIOS_CONSTANTS } from "../constants/axios.constants";
import { GLOBAL_CONSTANTS } from "../constants/global.constants";
import { actionHelpers } from "../helpers/actionHelpers";
import { AUTH_ENDPOINTS } from "../../modules/login/auth.endpoints";
import axios from "axios";

let isRefreshing = false;
let failedQueue = [];

const processQueue = (error, token = null) => {
  failedQueue.forEach((prom) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });

  failedQueue = [];
};

const createInstanceWithInterceptor = (endpoint) => {
  const instance = AXIOS_CONSTANTS.createAxiosInstance(endpoint);
  instance.interceptors.request.use(
    (config) => {
      const accessToken = actionHelpers.getLocalStorage.accessToken();
      if (accessToken) {
        config.headers[
          GLOBAL_CONSTANTS.AUTHORIZATION_HEADER.AUTHORIZATION
        ] = accessToken;
      }
      return config;
    },
    (error) => {
      Promise.reject(error);
    }
  );

  instance.interceptors.response.use(null, function (error) {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
      if (isRefreshing) {
        return new Promise(function (resolve, reject) {
          failedQueue.push({ resolve, reject });
        })
          .then((token) => {
            originalRequest.headers[
              GLOBAL_CONSTANTS.AUTHORIZATION_HEADER.AUTHORIZATION
            ] = token;
            return instance(originalRequest);
          })
          .catch((err) => {
            return Promise.reject(err);
          });
      }

      originalRequest._retry = true;
      isRefreshing = true;

      const refreshToken = actionHelpers.getLocalStorage.refreshToken();
      return new Promise(function (resolve, reject) {
        axios
          .post(AUTH_ENDPOINTS.REFRESH_TOKEN, {
            refresh_token: refreshToken,
          })
          .then(({ data }) => {
            actionHelpers.setLocalStorage.accessToken(data.data.accessToken);
            instance.defaults.headers.common[
              GLOBAL_CONSTANTS.AUTHORIZATION_HEADER.AUTHORIZATION
            ] = data.data.accessToken;
            originalRequest.headers[
              GLOBAL_CONSTANTS.AUTHORIZATION_HEADER.AUTHORIZATION
            ] = data.data.accessToken;
            processQueue(null, data.data.token);
            resolve(instance(originalRequest));
          })
          .catch((err) => {
            processQueue(err, null);
            reject(err);
          })
          .finally(() => {
            isRefreshing = false;
          });
      });
    }

    return Promise.reject(error);
  });

  return instance;
};

export default createInstanceWithInterceptor;
