import React, { useEffect, useState } from "react";
import {
  Dialog,
  Tabs,
  Tab,
  makeStyles,
  Grid,
  Typography,
  Card,
  CardContent,
  Button,
} from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import { GLOBAL_CONSTANTS } from "../../constants/global.constants";
import en from "../../../../assets/languages/en.json";
import modalStyles from "./Modal.styles";
import ResultWindow from "../../../modules/Dashboard/components/ResultWindow/ResultWindow";
import MediaWindow from "../../../modules/Dashboard/components/MediaWindow/MediaWindow";
import DoubleArrowRoundedIcon from "@material-ui/icons/DoubleArrowRounded";
import HighlightOffRoundedIcon from "@material-ui/icons/HighlightOffRounded";
import CoverPreview from "../../../modules/Dashboard/components/CoverPreview/CoverPreview";
import { authActions } from "../../../modules/login/auth.actions";
import ZoomOutMapIcon from "@material-ui/icons/ZoomOutMap";
import { ReactComponent as ZoomInMapIcon } from "../../../../assets/svg/zoom-in.svg";
import { useParams, useHistory } from "react-router-dom";
import editIcon from "../../../../assets/svg/edit.svg";
import TextColor from "../../../modules/Dashboard/components/CoverPreview/TextColor";
import EditRoundedIcon from "@material-ui/icons/EditRounded";
import LearningTip from "./LearningTip";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import { useTheme } from "@material-ui/core";
import LinkIcon from "@material-ui/icons/Link";

const Modal = ({
  setIsModalOpen,
  isModalOpen,
  stepCount,
  lessonPreview,
  handleIncreaseStepCount,
  handleDecreaseStepCount,
  lessonId,
  editFromPreview,
}) => {
  const theme = useTheme();
  const styles = modalStyles(theme);
  const dispatch = useDispatch();
  const { id } = useParams();
  const [selected, setSelected] = useState(null);
  const [isPreview, setIsPreview] = useState(id);
  const fullWindow = useSelector((state) => state.authReducer.fullWindow);
  const url = useSelector((state) => state.dashboardReducer);
  const history = useHistory();
  const learningType = useSelector(
    (state) => state.dashboardReducer.stepLearningType[stepCount - 1]
  );

  const stepImage = useSelector(
    (state) =>
      state.dashboardReducer.stepImage[
        stepCount - GLOBAL_CONSTANTS.STEP_TYPE.DEFAULT_FIRST_STEP
      ]
  );

  const stepVideo = useSelector(
    (state) =>
      state.dashboardReducer.stepVideo[
        stepCount - GLOBAL_CONSTANTS.STEP_TYPE.DEFAULT_FIRST_STEP
      ]
  );
  const stepSlide = useSelector(
    (state) =>
      state.dashboardReducer.stepEmbeddedUrl[
        stepCount - GLOBAL_CONSTANTS.STEP_TYPE.DEFAULT_FIRST_STEP
      ]
  );
  let stepTitles = Object.values(en.steps);
  let stepTitleArray = [];
  {
    stepTitles.map((step, index) => {
      if (index !== 0) stepTitleArray.push(step.title);
    });
  }

  const stepPDF = useSelector(
    (state) =>
      state.dashboardReducer.stepPDF[
        stepCount - GLOBAL_CONSTANTS.STEP_TYPE.DEFAULT_FIRST_STEP
      ]
  );

  useEffect(() => {
    if (
      url?.selectedContent.length > 0 &&
      stepCount > GLOBAL_CONSTANTS.STEP_TYPE.DEFAULT_FIRST_STEP
    ) {
      for (const item of url?.selectedContent[stepCount - 2]) {
        if (item.checked) {
          setSelected(item);
          break;
        }
      }
    }
  }, [stepCount]);

  const backgroundColor = useSelector(
    (state) => state.dashboardReducer.backgroundColor
  );

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const substepOrder = useSelector((state) => {
    if (
      !state.dashboardReducer.substepOrder[
        stepCount - GLOBAL_CONSTANTS.STEP_TYPE.DEFAULT_FIRST_STEP
      ]
    ) {
      return GLOBAL_CONSTANTS.DEAFULT_SUBSTEP_ORDER;
    }
    return state.dashboardReducer.substepOrder[
      stepCount - GLOBAL_CONSTANTS.STEP_TYPE.DEFAULT_FIRST_STEP
    ];
  });

  const [tabs, setTabs] = useState(en.lesson.tabs);
  const [value, setValue] = useState(0);
  const [substeps, setSubsteps] = useState([]);

  const stepDescription = useSelector(
    (state) => state.dashboardReducer.stepDescriptions
  );

  const [note, setNote] = useState(
    stepDescription[stepCount + en.lesson.tabs[value]]
  );

  useEffect(() => {
    const newTabs = [];
    for (const [key, value] of Object.entries(substepOrder)) {
      newTabs[value] = en.lesson.tabs[key];
    }

    setTabs(newTabs);
  }, [substepOrder]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    setNote(stepDescription[stepCount + tabs[value]]);
  }, [value, substepOrder]);

  useEffect(() => {
    if (id) {
      let checkEmptyTabs = {
        Image: stepImage,
        'Videos/Quiz/Games': stepVideo,
        "Slides/Docs": stepSlide,
        "PDF documents": stepPDF,
      };

      const emptyTabs = Object.keys(checkEmptyTabs).filter(
        (single) => checkEmptyTabs[single] === undefined
      );

      const newTabs = en.lesson.tabs.filter((tab) => {
        return !emptyTabs.includes(tab);
      });

      setTabs(newTabs);
    }

    if (Object.keys(stepDescription).length > 0) {
      setNote(stepDescription[stepCount + tabs[value]]);
    }

    stepCount === 1 && setValue(0);
  }, [stepCount]);

  useEffect(() => {
    const list = [];

    tabs.forEach((tab, _index) => {
      switch (tab) {
        case en.lesson.tabs[0]:
          list[_index] = <ResultWindow stepCount={stepCount} />;
          break;
        case en.lesson.tabs[1]:
          list[_index] = (
            <MediaWindow stepCount={stepCount} type={en.lesson.tabs[1]} />
          );
          break;
        case en.lesson.tabs[2]:
          list[_index] = (
            <MediaWindow stepCount={stepCount} type={en.lesson.tabs[2]} />
          );
          break;
        case en.lesson.tabs[3]:
          list[_index] = (
            <MediaWindow stepCount={stepCount} type={en.lesson.tabs[3]} />
          );
          break;
        case en.lesson.tabs[4]:
          list[_index] = (
            <MediaWindow stepCount={stepCount} type={en.lesson.tabs[4]} />
          );
          break;
        default:
          return tabs;
      }
    });

    setSubsteps(list);
  }, [tabs, stepCount]);

  const handleRedirect = () => {
    stepCount === 3
      ? window.open(selected?.image?.contextLink, "_blank")
      : window.open(selected.link, "_blank");
  };

  const handleSlideRedirect = () => {
    if (url.stepEmbeddedUrl[stepCount - 1]) {
      const frameSource = url.stepEmbeddedUrl[stepCount - 1].match(
        GLOBAL_CONSTANTS.REGEX.SOURCE_EXTRACTION
      );
      window.open(frameSource[1], "_blank");
    }
  };

  const handleQuitClick = () => {
    setIsModalOpen(false);
  };
  const handleTabStep = (type) => {
    const lastTab = tabs.length - 1;
    const firstTab = tabs.length - lastTab - 1;

    const newValue =
      type === "increase"
        ? value == lastTab
          ? firstTab
          : value + 1
        : value == firstTab
        ? 0
        : value - 1;

    if (id) {
      if (type == "increase") {
        if (lastTab == value) {
          handleIncreaseStepCount();
        }
      } else {
        if (value == newValue) {
          handleDecreaseStepCount();
        }
      }
    }

    setValue(newValue);
  };

  return (
    <Dialog
      disableBackdropClick
      onClose={handleModalClose}
      open={isModalOpen}
      maxWidth={fullWindow ? "xl" : "md"}
      fullWidth
      fullScreen={id}
    >
      <div
        className={styles.tabContainer}
        style={{
          backgroundColor: learningType
            ? theme.palette.primary[learningType]
            : theme.palette.primary.main,
          display: stepCount === 1 ? "" : "flex",
        }}
      >
        {stepCount !== 1 && (
          <NavigateBeforeIcon
            fontSize="large"
            onClick={() => handleTabStep("decrease")}
          />
        )}
        <Tabs
          value={value}
          onChange={handleChange}
          classes={{
            root: learningType ? styles[learningType] : styles.default,
            indicator:
              stepCount !== GLOBAL_CONSTANTS.STEP_TYPE.DEFAULT_FIRST_STEP
                ? styles.indicator
                : styles.noIndicator,
          }}
          style={
            stepCount === 1 && !id
              ? {
                  background: backgroundColor,
                  color: TextColor(backgroundColor),
                  borderBottom: `1px solid ` + TextColor(backgroundColor),
                  width: "100%",
                }
              : id && stepCount === 1
              ? {
                  background: backgroundColor,
                  color: TextColor(backgroundColor),
                  width: "100%",
                }
              : {}
          }
        >
          {stepCount !== GLOBAL_CONSTANTS.STEP_TYPE.DEFAULT_FIRST_STEP
            ? tabs.map((tab, _index) => {
                if (tab === en.lesson.tabs[0]) {
                  return <Tab key={_index} label={tab} />;
                }
                if (tab === en.lesson.tabs[1]) {
                  return !stepImage ? (
                    <Tab key={_index} label={tab} />
                  ) : (
                    <Tab key={_index} label={tab + en.label.tick} />
                  );
                }
                if (tab === en.lesson.tabs[2]) {
                  return !stepVideo ? (
                    <Tab key={_index} label={tab} />
                  ) : (
                    <Tab key={_index} label={tab + en.label.tick} />
                  );
                }
                if (tab === en.lesson.tabs[3]) {
                  return !stepSlide ? (
                    <Tab key={_index} label={tab} />
                  ) : (
                    <Tab key={_index} label={tab + en.label.tick} />
                  );
                }
                if (tab === en.lesson.tabs[4]) {
                  return !stepPDF ? (
                    <Tab key={_index} label={tab} />
                  ) : (
                    <Tab key={_index} label={tab + en.label.tick} />
                  );
                }
              })
            : null}
        </Tabs>
        {!id && (
          <span
            className={
              !fullWindow ? styles.quitButton : styles.quitButtonFullWindow
            }
          >
            <HighlightOffRoundedIcon
              fontSize="large"
              onClick={handleQuitClick}
            />
          </span>
        )}
        {stepCount !== 1 && (
          <NavigateNextIcon
            fontSize="large"
            onClick={() => handleTabStep("increase")}
          />
        )}
      </div>

      <Grid
        container
        justify="space-between"
        classes={{ root: styles.stepDescription }}
        style={
          stepCount === 1
            ? {
                background: backgroundColor,
                color: TextColor(backgroundColor),
              }
            : { background: "" }
        }
      >
        <Grid item xs={6}>
          <Typography variant="h6">{stepTitleArray[stepCount - 1]}</Typography>
        </Grid>
        <Grid
          item
          xs={6}
          className={styles.iconsContainer}
          style={
            stepCount === 1
              ? {
                  background: backgroundColor,
                  color: TextColor(backgroundColor),
                  width: "100%",
                }
              : { background: "" }
          }
        >
          {!id && stepCount !== 1 && lessonPreview && (
            <img
              className={styles.editIcon}
              src={editIcon}
              onClick={() =>
                history.location.pathname.includes("create-lesson")
                  ? editFromPreview(stepCount, value)
                  : history.push("/create-lesson", {
                      lessonId,
                      step: stepCount,
                      subStep: value,
                    })
              }
            />
          )}
          {stepCount === 1 &&
            !id &&
            history.location.pathname.includes("lessons") && (
              <EditRoundedIcon
                onClick={() =>
                  history.push("/create-lesson", {
                    lessonId,
                    step: stepCount,
                    subStep: value,
                  })
                }
                style={
                  stepCount === 1
                    ? {
                        background: backgroundColor,
                        color: TextColor(backgroundColor),
                        paddingRight: "15px",
                      }
                    : { background: "" }
                }
              />
            )}

          {fullWindow ? (
            <span
              className={styles.windowSize}
              onClick={() => dispatch(authActions.setFullScreen)}
            >
              <ZoomInMapIcon />
            </span>
          ) : (
            <span
              className={styles.windowSize}
              onClick={() => dispatch(authActions.setFullScreen)}
            >
              {!id && <ZoomOutMapIcon />}
            </span>
          )}
        </Grid>
      </Grid>

      <Grid
        container
        justify="center"
        classes={{ root: styles.container }}
        spacing={8}
        style={
          stepCount === 1
            ? {
                background: backgroundColor,
                color: TextColor(backgroundColor),
              }
            : { background: "" }
        }
      >
        {!id && lessonPreview && (
          <div className={styles.previousArrow}>
            <DoubleArrowRoundedIcon
              className={styles.arrowSizing}
              onClick={handleDecreaseStepCount}
              style={
                stepCount === 1
                  ? {
                      color: TextColor(backgroundColor),
                    }
                  : { background: "" }
              }
            />
          </div>
        )}
        <Grid
          item
          xs={12}
          classes={{ item: styles.heroGrid }}
          style={
            stepCount === 1
              ? {
                  background: backgroundColor,
                  color: TextColor(backgroundColor),
                  height: id ? `calc(100vh - 112px)` : "",
                }
              : { background: "" }
          }
        >
          {stepCount !== GLOBAL_CONSTANTS.STEP_TYPE.DEFAULT_FIRST_STEP ? (
            substeps[value]
          ) : (
            <CoverPreview
              stepCount={GLOBAL_CONSTANTS.STEP_TYPE.DEFAULT_FIRST_STEP}
            />
          )}
        </Grid>
        {!id && lessonPreview && (
          <div className={styles.nextArrow}>
            <DoubleArrowRoundedIcon
              className={styles.arrowSizing}
              onClick={handleIncreaseStepCount}
              style={
                stepCount === 1
                  ? {
                      color: TextColor(backgroundColor),
                    }
                  : { background: "" }
              }
            />
          </div>
        )}
        {stepCount !== GLOBAL_CONSTANTS.STEP_TYPE.DEFAULT_FIRST_STEP && (
          <>
            <Grid item xs={12} classes={{ item: styles.notesGrid }}>
              <Card classes={{ root: styles.notes }}>
                <CardContent>
                  <Typography variant="h6" color="secondary">
                    {<span className={styles.notesLabel}>{en.label.note}</span>}
                    {en.label.noteColon}
                  </Typography>
                  <Typography
                    color="secondary"
                    component="span"
                    classes={{ root: styles.notesContent }}
                  >
                    {<div dangerouslySetInnerHTML={{ __html: note }} /> ||
                      en.label.notes}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} classes={{ item: styles.notesGrid }}>
              <Typography variant="h6" component="p" color="secondary">
                {en.label.links}
              </Typography>
              <Typography
                variant="body2"
                color="textPrimary"
                className={styles.linkStyles}
              >
                <span>
                  {en.label.siteLink.click}
                  <span className={styles.anchor} onClick={handleRedirect}>
                    {en.label.siteLink.here}
                  </span>
                  {en.label.siteLink.link}
                </span>
                <LinkIcon className={styles.mLinkIcon} />
              </Typography>
              <Typography
                variant="body2"
                color="textPrimary"
                className={styles.linkStyles}
              >
                <span>
                  {en.label.siteLink.click}
                  <span className={styles.anchor} onClick={handleSlideRedirect}>
                    {en.label.siteLink.here}
                  </span>
                  {en.label.slides.link}
                </span>
                <LinkIcon className={styles.mLinkIcon} />
              </Typography>
            </Grid>

            {!id ? (
              <Grid item xs={12}>
                <LearningTip />
              </Grid>
            ) : (
              <Grid item xs={12} />
            )}
          </>
        )}
      </Grid>
      {stepCount === 1 && id && (
        <Button
          className={styles.moveButton}
          color="primary"
          variant="contained"
          onClick={handleIncreaseStepCount}
        >
          {en.button.start}
        </Button>
      )}
    </Dialog>
  );
};

export default Modal;
