import DASHBOARD_CONSTANTS from "./dashboard.constants";

export const DASHBOARD_ENDPOINTS = {
  ADD_SUBJECT: process.env.REACT_APP_BASE_URL + "/subjects",
  UPDATE_USER: process.env.REACT_APP_BASE_URL + "/me/change-password",
  CREATE_LESSON: process.env.REACT_APP_BASE_URL + "/lessons",
  UPDATE_TUTORIAL_STATUS: process.env.REACT_APP_BASE_URL + "/tutorialStatus",
  DUPLICATE_LESSON: process.env.REACT_APP_BASE_URL + "/duplicate_lessons",
  GOOGLE_SEARCH:
    "https://www.googleapis.com/customsearch/v1?key=" +
    process.env.REACT_APP_GOOGLE_API_KEY +
    "&cx=" +
    process.env.REACT_APP_CX,
  GET_IMAGES: process.env.REACT_APP_BASE_URL + "/images",
  VERIFY_ACCOUNT: process.env.REACT_APP_BASE_URL + "/profile/verify",
};

export const DYNAMIC_DASHBOARD_ENDPOINTS = (endpoint, params) => {
  return `${endpoint}/${params}`;
};
