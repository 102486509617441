import React, { useState, useRef, useEffect } from "react";
import styles from "./DropZone.module.scss";
import en from "../../../../../assets/languages/en.json";
import PublishRoundedIcon from "@material-ui/icons/PublishRounded";
import { Grid } from "@material-ui/core";
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";

import { dashboardActions } from "../../dashboard.actions";
import { useSelector, useDispatch } from "react-redux";
import stepType from "../../../../shared/constants/step.constants";
const DropZone = ({
  handleImageUpload,
  file,
  description,
  accept,
  fileName,
  handleImageDelete,
  stepCount,
  lessonForm,
  stepTypeFile,
  inputs,
}) => {
  const uploadRef = useRef(null);
  const imageRef = useRef(null);

  const [imagePreview, setImagePreview] = useState(null);

  useEffect(() => {
    if (stepTypeFile === stepType.IMAGE_FROM_LESSON_FORM) {
      if (inputs.image) {
        setImagePreview(inputs.image);
      }
    }
  }, []);

  const dispatch = useDispatch();

  const dragOver = (event) => {
    event.preventDefault();
  };

  const dragEnter = (event) => {
    event.preventDefault();
  };

  const dragLeave = (event) => {
    event.preventDefault();
  };

  const [imageData, setImageData] = useState(null);
  const [image, setImage] = useState(null);
  const fileDrop = (event) => {
    event.preventDefault();
    handleImageUpload(event.dataTransfer.files[0]);
  };

  const handleZoneClick = () => {
    uploadRef.current.click();
  };

  const handleUploadChange = (event) => {
    handleImageUpload(event.target.files[0]);
  };

  const [picture, setPicture] = useState(null);
  const onChangePicture = (event) => {
    if (event.target.files[0]) {
      setPicture(event.target.files[0]);
      const reader = new FileReader();

      reader.addEventListener("load", () => {
        setImage(reader.result);
      });

      reader.readAsDataURL(event.target.files[0]);
    }
  };
  const uploadAndPreviewImage = (event) => {
    handleUploadChange(event);
    onChangePicture(event);
  };

  const handleUploadDelete = () => {
    setImagePreview(null);
    setImageData(null);
    if (handleImageDelete) {
      handleImageDelete();
      setImage();
    } else {
      dispatch(dashboardActions.deleteImage(stepCount - 1));
    }
  };
  const stepImage = useSelector((state) => state.dashboardReducer.stepImage);
  useEffect(() => {
    if (stepImage.hasOwnProperty([stepCount - 1]) && stepImage[stepCount - 1]) {
      const imageData = stepImage[stepCount - 1].split(`name${stepCount - 1}:`);
      setImageData({
        source: imageData[0],
        name: imageData[1],
      });
      setImage(imageData.source);
    }
  }, [stepImage]);

  const formImage = useSelector((state) => state.dashboardReducer.formContent);

  useEffect(() => {
    if (formImage) {
      setImage(formImage.image);
    }
  }, [formImage]);

  useEffect(() => {
    if (imagePreview) {
      imageRef.current.style.display = "block";
      return;
    }

    if ((!image || image?.length === 0) && imageRef.current) {
      imageRef.current.style.display = "none";
      return;
    }

    if (imageRef.current) {
      imageRef.current.style.display = "block";
    }
  }, [image]);

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <div
            className={styles["message"]}
            onClick={handleZoneClick}
            onDragOver={dragOver}
            onDragEnter={dragEnter}
            onDragLeave={dragLeave}
            onDrop={fileDrop}
          >
            <div className={styles["icon"]}>
              <PublishRoundedIcon />
            </div>
            <p>{description}</p>
            <p>{file?.name ?? fileName ?? en.file}</p>
          </div>
        </Grid>
        <input
          accept={accept}
          ref={uploadRef}
          className={styles.upload}
          onChange={uploadAndPreviewImage}
          type="file"
        />

        <Grid item xs={12}>
          <div className={styles.imageDiv}>
            {lessonForm ? (
              <>
                <img
                  src={image ?? imagePreview}
                  ref={imageRef}
                  className={styles["imagePreview"]}
                />
              </>
            ) : (
              <>
                {imagePreview && (
                  <img src={imagePreview} className={styles["imagePreview"]} />
                )}
              </>
            )}
            {stepTypeFile === stepType.IMAGE && imageData && (
              <img src={imageData?.source} className={styles["imagePreview"]} />
            )}
            {imageData && stepTypeFile === stepType.IMAGE && (
              <CloseRoundedIcon
                className={styles.deleteIcon}
                onClick={handleUploadDelete}
              />
            )}
            {(image || imagePreview) &&
              stepTypeFile === stepType.IMAGE_FROM_LESSON_FORM && (
                <CloseRoundedIcon
                  className={styles.deleteIcon}
                  onClick={handleUploadDelete}
                />
              )}
          </div>
        </Grid>
      </Grid>
    </>
  );
};

export default DropZone;
