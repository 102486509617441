import { GLOBAL_CONSTANTS } from "../../shared/constants/global.constants";
import createInstanceWithInterceptor from "./../../shared/helpers/axios.interceptor";
import {
  DASHBOARD_ENDPOINTS,
  DYNAMIC_DASHBOARD_ENDPOINTS,
} from "./dashboard.endpoints";

const submitSubject = async (title) => {
  const instance = createInstanceWithInterceptor(
    DASHBOARD_ENDPOINTS.ADD_SUBJECT
  );
  const response = {
    success: null,
    error: null,
  };
  try {
    const success = await instance.post(null, { title: title });
    response.success = success;
  } catch (error) {
    response.error = error;
  } finally {
    return response;
  }
};

const listSubjectLesson = async () => {
  const instance = createInstanceWithInterceptor(
    DASHBOARD_ENDPOINTS.ADD_SUBJECT
  );
  const response = {
    success: null,
    error: null,
  };
  try {
    const success = await instance.get(null);
    response.success = success;
  } catch (error) {
    response.error = error;
  } finally {
    return response;
  }
};

const updateUser = async ({ newPassword, confirmNewPassword }) => {
  const instance = createInstanceWithInterceptor(
    DASHBOARD_ENDPOINTS.UPDATE_USER
  );
  const response = {
    success: null,
    error: null,
  };
  try {
    const success = await instance.patch(null, {
      newPassword,
      confirmNewPassword,
    });
    response.success = success;
  } catch (error) {
    response.error = error;
  } finally {
    return response;
  }
};

const fetchResults = async (queries) => {
  const response = {
    success: null,
    error: null,
  };
  try {
    const success = await Promise.all(queries);
    response.success = success;
  } catch (error) {
    response.error = error;
  } finally {
    return response;
  }
};

const fetchResult = async (query) => {
  const response = {
    success: null,
    error: null,
  };
  try {
    const success = await query;
    response.success = success;
  } catch (error) {
    response.error = error;
  } finally {
    return response;
  }
};
const createLesson = async ({ lesson }) => {
  const instance = createInstanceWithInterceptor(
    DASHBOARD_ENDPOINTS.CREATE_LESSON
  );
  const response = {
    success: null,
    error: null,
  };
  try {
    const success = await instance.post(null, lesson);
    response.success = success;
  } catch (error) {
    response.error = error;
  } finally {
    return response;
  }
};

const getLessonsWithPagination = async (page, week) => {
  const instance = createInstanceWithInterceptor(
    DASHBOARD_ENDPOINTS.CREATE_LESSON
  );
  const response = {
    success: null,
    error: null,
  };
  try {
    const success = await instance.get(null, {
      params: {
        limit: GLOBAL_CONSTANTS.LESSONS_PAGINATION_LIMIT,
        page,
        week: week ? week : "",
      },
    });
    response.success = success;
  } catch (error) {
    response.error = error;
  } finally {
    return response;
  }
};

const getLessonById = async (lessonId) => {
  const instance = createInstanceWithInterceptor(
    DYNAMIC_DASHBOARD_ENDPOINTS(DASHBOARD_ENDPOINTS.CREATE_LESSON, lessonId)
  );
  const response = {
    success: null,
    error: null,
  };
  try {
    const success = await instance.get(null);
    response.success = success;
  } catch (error) {
    response.error = error;
  } finally {
    return response;
  }
};

const duplicateLesson = async (lessonId) => {
  const instance = createInstanceWithInterceptor(
    DYNAMIC_DASHBOARD_ENDPOINTS(DASHBOARD_ENDPOINTS.DUPLICATE_LESSON, lessonId)
  );
  const response = {
    success: null,
    error: null,
  };
  try {
    const success = await instance.post(null);
    response.success = success;
  } catch (error) {
    response.error = error;
  } finally {
    return response;
  }
};

const patchLessonById = async ({ id: lessonId, lesson }) => {
  const instance = createInstanceWithInterceptor(
    DYNAMIC_DASHBOARD_ENDPOINTS(DASHBOARD_ENDPOINTS.CREATE_LESSON, lessonId)
  );
  const response = {
    success: null,
    error: null,
  };
  try {
    const success = await instance.patch(null, {
      ...lesson,
    });
    response.success = success;
  } catch (error) {
    response.error = error;
  } finally {
    return response;
  }
};

const deleteLessonById = async (id) => {
  const instance = createInstanceWithInterceptor(
    DYNAMIC_DASHBOARD_ENDPOINTS(DASHBOARD_ENDPOINTS.CREATE_LESSON, id)
  );
  const response = {
    success: null,
    error: null,
  };
  try {
    const success = await instance.delete(null, {});
    response.success = success;
  } catch (error) {
    response.error = error;
  } finally {
    return response;
  }
};

const updateTutorialStatus = async () => {
  const instance = createInstanceWithInterceptor(
    DASHBOARD_ENDPOINTS.UPDATE_TUTORIAL_STATUS
  );
  const response = {
    success: null,
    error: null,
  };
  try {
    const success = await instance.patch(null);

    response.success = success;
  } catch (error) {
    response.error = error;
  } finally {
    return response;
  }
};

const getStaticImages = async (page) => {
  const instance = createInstanceWithInterceptor(
    DASHBOARD_ENDPOINTS.GET_IMAGES
  );
  const response = {
    success: null,
    error: null,
  };
  try {
    const success = await instance.get(null, {
      params: {
        page: page,
      },
    });

    response.success = success;
  } catch (error) {
    response.error = error;
  } finally {
    return response;
  }
};

const verifyAccount = async (token) => {
  const instance = createInstanceWithInterceptor(
    DASHBOARD_ENDPOINTS.VERIFY_ACCOUNT
  );
  const response = {
    success: null,
    error: null,
  };
  try {
    const success = await instance.post(null, { token });

    response.success = success;
  } catch (error) {
    response.error = error;
  } finally {
    return response;
  }
};

export const dashboardService = {
  submitSubject,
  listSubjectLesson,
  updateUser,
  fetchResults,
  fetchResult,
  createLesson,
  getLessonsWithPagination,
  getLessonById,
  patchLessonById,
  deleteLessonById,
  updateTutorialStatus,
  duplicateLesson,
  getStaticImages,
  verifyAccount,
};
