import React from "react";
import {
  Dialog,
  Grid,
  Typography,
  Box,
  Button,
  makeStyles,
} from "@material-ui/core";
import en from "../../../../assets/languages/en.json";
import confirmModalStyles from "./ConfirmModa.styles";

const useStyles = makeStyles(confirmModalStyles);

const ConfirmModal = ({ title, body, isOpen, handleClose, handleDelete }) => {
  const styles = useStyles();

  return (
    <Dialog
      disableBackdropClick
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={isOpen.state}
    >
      <Grid container>
        <Box p={3}>
          <Grid xs={12} classes={{ root: styles.titleContainer }}>
            <Typography variant="h6">{title}</Typography>
          </Grid>

          <Grid xs={12}>
            <Box m={2}>
              <Typography color="textSecondary" variant="body1">
                {body}
                <p className={styles.secondaryWarning}>
                  {en.lesson.confirm.undone}
                </p>
              </Typography>
            </Box>
          </Grid>
          <Grid xs={12} classes={{ root: styles.buttonsContainer }}>
            <Box display="inline" marginRight={2}>
              <Button
                variant="contained"
                color="primary"
                disableRipple
                onClick={handleClose}
              >
                {en.button.cancel}
              </Button>
            </Box>
            <Button
              variant="contained"
              color="primary"
              disableRipple
              onClick={() => handleDelete(isOpen.id)}
            >
              {en.button.confirm}
            </Button>
          </Grid>
        </Box>
      </Grid>
    </Dialog>
  );
};

export default ConfirmModal;
