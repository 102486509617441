import React, { useEffect, useState, useRef } from "react";
import {
  makeStyles,
  TextField,
  Grid,
  Button,
  Box,
  Select,
  FormControl,
  InputLabel,
} from "@material-ui/core";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { Autocomplete } from "@material-ui/lab";
import lessonFormStyles from "./LessonForm.styles";
import en from "../../../../../assets/languages/en.json";
import { dashboardService } from "../../dashboard.service";
import { useDispatch, useSelector } from "react-redux";
import { dashboardActions } from "../../dashboard.actions";
import MomentUtils from "@date-io/moment";
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { GLOBAL_CONSTANTS } from "../../../../shared/constants/global.constants";
import DateRangeIcon from "@material-ui/icons/DateRange";
import { Steps } from "intro.js-react";
import blobToBase64 from "blob-to-base64";

import ImagePickerModal from "../ImagePicker/ImagePickerModal";
import { datetime } from "../../../../shared/helpers/datetime";

const useStyles = makeStyles(lessonFormStyles);

const LessonForm = ({ handleNext, isOpen }) => {
  const styles = useStyles();
  const [inputs, setInputs] = useState({
    title: "",
    unit: "",
    subject: "",
    description: "",
    image: "",
    week: "",
    day: "",
    hour: "",
  });
  const submitRef = useRef(null);

  const [isLoading, setIsLoading] = useState(false);
  const [isLoaderOpen, setIsLoaderOpen] = useState(false);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [selectedDate, handleDateChange] = useState(new Date());
  const existingInputs = useSelector((state) => {
    if (Object.keys(typeof state.dashboardReducer.formContent).length > 0) {
      return state.dashboardReducer.formContent;
    } else {
      return {
        title: "",
        unit: "",
        subject: "",
        description: "",
        image: "",
        week: "",
        day: "",
        hour: "",
      };
    }
  });

  const id = useSelector((state) => state.dashboardReducer.id);

  const [list, setList] = useState([]);
  const dispatch = useDispatch();
  const lesson = useSelector((state) => state.dashboardReducer);
  const lessonDescription = useSelector(
    (state) => state.dashboardReducer.formContent.description
  );

  const handleInputChange = (e) => {
    setInputs((prevInputs) => {
      return {
        ...prevInputs,
        [e.target.name]: e.target.value,
      };
    });
  };

  const handleImageDelete = () => {
    setInputs((prevInputs) => {
      return {
        ...prevInputs,
        image: "",
      };
    });
  };

  const getData = async () => {
    const response = await dashboardService.listSubjectLesson();
    if (response.success) {
      setList(response.success.data.data.subjects);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    getData();
    if (Object.keys(existingInputs).length > 0) {
      setInputs(existingInputs);
    }
  }, [existingInputs]);

  const handleImageUpload = (image) => {
    if (typeof image === "object") {
      blobToBase64(image, function (error, base64) {
        if (!error) {
          setInputs((prevInputs) => {
            return {
              ...prevInputs,
              image: base64,
            };
          });
        }
      });
    }
  };

  const weeksOption = Array.from(
    Array(GLOBAL_CONSTANTS.NUMBER_OF_ACADEMIC_WEEKS).keys()
  );

  const handleWeekChange = (event) => {
    setInputs((prevInputs) => {
      return {
        ...prevInputs,
        week: event.target.value,
      };
    });
  };

  const handleSubject = (event, newValue) => {
    setInputs((prevInputs) => {
      return {
        ...prevInputs,
        subject: newValue ? newValue : event.target.value,
      };
    });
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    setIsLoaderOpen(true);

    let query = id
      ? dashboardService.patchLessonById
      : dashboardService.createLesson;

    submitRef.current.innerText = id ? "Updating..." : "Creating...";

    const params = id
      ? { id, lesson }
      : { lesson: { ...lesson, formContent: inputs } };

    const response = await query(params);
    const newLessonId = !id && response?.success?.data?.data?.lesson?.id;

    await dispatch(
      dashboardActions.setFormContent(inputs, newLessonId ?? null)
    );
    setIsLoading(false);
    if (response.success) {
      setSuccess(true);
      handleNext();
    } else {
      setError(true);
    }
  };

  return (
    <div>
      <div>
        <form>
          <Grid container spacing={3}>
            <Grid item xs={12} md={2}>
              <TextField
                label={en.label.title}
                fullWidth
                required
                type="text"
                value={inputs.title}
                onChange={handleInputChange}
                name="title"
                className="step-one"
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <TextField
                label={en.label.unit}
                fullWidth
                required
                type="text"
                value={inputs.unit}
                onChange={handleInputChange}
                name="unit"
                className="step-two"
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <Autocomplete
                disableClearable
                freeSolo
                value={
                  existingInputs.subject ? existingInputs.subjects : undefined
                }
                onChange={handleSubject}
                options={list.map((option) => option.title)}
                renderInput={(params) => (
                  <TextField
                    className="step-three"
                    {...params}
                    label={en.label.subject}
                    required
                    name="subject"
                    onChange={handleInputChange}
                    value={inputs.subject}
                    placeholder={
                      existingInputs.subject
                        ? en.label.currentSubject + existingInputs.subject
                        : undefined
                    }
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={2} classes={{ root: styles.weekPicker }}>
              <FormControl fullWidth>
                <InputLabel htmlFor="age-native-simple">
                  {en.academicWeek}
                </InputLabel>
                <Select
                  className="step-four"
                  native
                  value={inputs.week}
                  onChange={handleWeekChange}
                  fullWidth
                  classes={{
                    select: styles.weekSelector,
                    selectMenu: styles.weekMenu,
                  }}
                  inputProps={{
                    name: "age",
                    id: "age-native-simple",
                  }}
                  label="Academic week"
                >
                  {weeksOption.map((week, _index) => (
                    <option value={_index}>
                      {_index === 0
                        ? `${en.label.none}`
                        : `${en.label.lessonWeek} ${_index}`}
                    </option>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={2} classes={{ root: styles.weekPicker }}>
              <FormControl fullWidth>
                <InputLabel htmlFor="age-native-simple"></InputLabel>
                <div className={styles.datePickerContainer}>
                  <MuiPickersUtilsProvider utils={MomentUtils}>
                    <DateTimePicker
                      className={styles.datePicker}
                      value={selectedDate}
                      fullWidth
                      onChange={(date) => {
                        handleDateChange(date);
                        const dataAndTime = datetime.getDateAndTime(date);
                        setInputs((prevInputs) => {
                          return {
                            ...prevInputs,
                            day: dataAndTime.date,
                            hour: dataAndTime.time,
                          };
                        });
                      }}
                    />
                  </MuiPickersUtilsProvider>
                  <DateRangeIcon
                    fontSize="default"
                    className={styles.calendarIcon}
                  />
                </div>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={12} className="step-seven">
              <Box m={2} className={styles.boxStyle}>
                <ImagePickerModal
                  inputs={inputs}
                  setInputs={setInputs}
                  handleImageUpload={handleImageUpload}
                  file={inputs.image}
                  description={en.lesson.dropZone}
                  accept={GLOBAL_CONSTANTS.INPUT_TYPE.IMAGE}
                  handleImageDelete={handleImageDelete}
                  fileName={
                    existingInputs.image?.length > 0
                      ? en.label.coverExists
                      : null
                  }
                />
              </Box>
            </Grid>

            <Grid item xs={12}>
              <ReactQuill
                className={styles.textArea}
                aria-label="empty textarea"
                defaultValue={lessonDescription || ""}
                placeholder={en.label.description}
                onChange={(content, delta, source, editor) => {
                  const text = editor.getText(content);

                  setInputs((prevInputs) => {
                    return {
                      ...prevInputs,
                      description: text,
                    };
                  });
                }}
                name="description"
                id="step-eight"
                theme={"snow"}
              />
            </Grid>
            <Grid item self="end" xs={12}>
              <Box className={styles.container__button}>
                <Button
                  variant="contained"
                  color="primary"
                  disableRipple
                  onClick={() => handleSubmit()}
                  disabled={
                    inputs?.title?.length === 0 || inputs?.subject?.length === 0
                  }
                  ref={submitRef}
                >
                  {id ? en.button.edit : en.button.create}
                </Button>
              </Box>
            </Grid>
          </Grid>
        </form>
      </div>
    </div>
  );
};

export default LessonForm;
