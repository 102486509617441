import axios from "axios";
import { AUTH_ENDPOINTS } from "./auth.endpoints";
import { AUTH_CONSTANTS } from "./auth.constants";
import { GLOBAL_CONSTANTS } from "../../shared/constants/global.constants";

const signUp = async ({ name, surname, email, password }) => {
  let data = {
    body: {},
    responseStatus: AUTH_CONSTANTS.SIGN_UP_FAIL,
  };

  try {
    const response = await axios({
      method: GLOBAL_CONSTANTS.AXIOS_METHODS.POST,
      url: AUTH_ENDPOINTS.SIGN_UP,
      headers: {},
      data: {
        name: name,
        surname: surname,
        email: email,
        password: password,
      },
    });

    data = {
      body: response,
      responseStatus: AUTH_CONSTANTS.SIGN_UP_SUCCESS,
    };
  } catch (error) {
    data = {
      body: {},
      responseStatus: AUTH_CONSTANTS.SIGN_UP_FAIL,
    };
  } finally {
    return data;
  }
};

const logIn = async ({ username, password }) => {
  let data = {
    body: {},
    responseStatus: AUTH_CONSTANTS.SIGN_IN_FAIL,
  };

  try {
    const response = await axios({
      method: GLOBAL_CONSTANTS.AXIOS_METHODS.POST,
      url: AUTH_ENDPOINTS.SIGN_IN,
      headers: {},
      data: {
        username: username,
        password: password,
      },
    });
    data = {
      body: response,
      responseStatus: AUTH_CONSTANTS.SIGN_IN_SUCCESS,
    };
  } catch (error) {
    data = {
      body: {},
      responseStatus: AUTH_CONSTANTS.SIGN_IN_FAIL,
    };
  } finally {
    return data;
  }
};

const forgotPassword = async ({ email }) => {
  let data = {
    body: {},
    responseStatus: AUTH_CONSTANTS.FORGOT_PASSWORD_FAIL,
  };

  try {
    const response = await axios({
      method: GLOBAL_CONSTANTS.AXIOS_METHODS.POST,
      url: AUTH_ENDPOINTS.FORGOT_PASSWORD,
      headers: {},
      data: {
        email: email,
      },
    });
    data = {
      body: response,
      responseStatus: AUTH_CONSTANTS.FORGOT_PASSWORD_SUCCESS,
    };
  } catch (error) {
    data = {
      body: {},
      responseStatus: AUTH_CONSTANTS.FORGOT_PASSWORD_FAIL,
    };
  } finally {
    return data;
  }
};

const changePassword = async ({ password, token }) => {
  let data = {
    body: {},
    responseStatus: AUTH_CONSTANTS.change_PASSWORD_FAIL,
  };

  try {
    const response = await axios({
      method: GLOBAL_CONSTANTS.AXIOS_METHODS.POST,
      url: AUTH_ENDPOINTS.CHANGE_PASSWORD,
      headers: {},
      data: {
        password: password,
        token: token,
      },
    });
    data = {
      body: response,
      responseStatus: AUTH_CONSTANTS.change_PASSWORD_SUCCESS,
    };
  } catch (error) {
    data = {
      body: {},
      responseStatus: AUTH_CONSTANTS.change_PASSWORD_FAIL,
    };
  } finally {
    return data;
  }
};

export const authService = {
  signUp,
  logIn,
  forgotPassword,
  changePassword,
};
