import React, { useEffect, useState } from "react";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles, useMediaQuery } from "@material-ui/core/";
import en from "../../../../assets/languages/en.json";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Box from "@material-ui/core/Box";
import navbarStyles from "./Navbar.styles";
import account from "../../../../assets/svg/tooltip-account.svg";
import { Link, NavLink } from "react-router-dom";
import { GLOBAL_CONSTANTS } from "../../constants/global.constants";
import { userActions } from "../../actions/user.actions";
import { useHistory } from "react-router-dom";
import logo from "../../../../assets/svg/logo.svg";

const useStyles = makeStyles(navbarStyles);

const Navbar = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const history = useHistory();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const styles = useStyles();
  const user = useSelector((state) =>
    JSON.parse(state.baseAuthReducer.authenticatedUser)
  );

  const isAuthenticated = useSelector(
    (state) => state.baseAuthReducer.isAuthenticated
  );

  const dispatch = useDispatch();

  const handleSignOut = () => {
    dispatch(userActions.removeAuthenticatedUser());
  };

  const matches = useMediaQuery("(max-width:750px)");

  useEffect(() => {
    if (!isAuthenticated) {
      history.push("/");
    }
  }, [isAuthenticated]);

  const handleLogoClick = () => {
    history.push("/");
  };

  return (
    <AppBar position="static" classes={{ root: styles.wrapper }}>
      <Toolbar>
        <div className={styles.logo}>
          <img
            height={!matches ? "60" : "40"}
            src={logo}
            onClick={handleLogoClick}
          />
        </div>
        {!matches && (
          <div className={styles.navLinksContainer}>
            <NavLink to="/dashboard" activeClassName={styles.selected}>
              <Typography color="primary">{en.navbar.dashboard}</Typography>
            </NavLink>
            <NavLink to="/profile" activeClassName={styles.selected}>
              <Typography color="primary"> {en.navbar.profile}</Typography>
            </NavLink>
            <NavLink to="/create-lesson" activeClassName={styles.selected}>
              <Typography color="primary"> {en.navbar.lessons}</Typography>
            </NavLink>
            <NavLink to="/lessons" activeClassName={styles.selected}>
              <Typography color="primary">
                {en.navbar.existingLessons}
              </Typography>
            </NavLink>
            <div className={styles.logo}>
              <Typography color="primary" onClick={handleSignOut}>
                {en.navbar.signOut}
              </Typography>
            </div>
          </div>
        )}

        {matches && (
          <div className={styles.container}>
            <Box mr={1}>
              <Typography variant="h6" className={styles.greeting}>
                {en.navbar.greeting + user.name}
              </Typography>
            </Box>
            <img
              src={account}
              onClick={handleClick}
              alt={GLOBAL_CONSTANTS.IMAGE_ALT.PROFILE}
              className={styles.userIcon}
            />
            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem component={Link} to="/dashboard">
                {en.navbar.dashboard}
              </MenuItem>
              <MenuItem component={Link} to="/profile">
                {en.navbar.profile}
              </MenuItem>
              <MenuItem component={Link} to="/create-lesson">
                {en.navbar.lessons}
              </MenuItem>
              <MenuItem component={Link} to="/lessons">
                {en.navbar.existingLessons}
              </MenuItem>
              <MenuItem onClick={handleSignOut}>{en.navbar.signOut}</MenuItem>
            </Menu>
          </div>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;
