import React, { useState, useEffect } from "react";
import {
  CardContent,
  Dialog,
  Card,
  Grid,
  Typography,
  makeStyles,
  Box,
  Button,
  Checkbox,
} from "@material-ui/core";
import pluginGuideStyles from "./PluginGuide.styles";
import { GLOBAL_CONSTANTS } from "../../../../shared/constants/global.constants";
import en from "../../../../../assets/languages/en.json";
import { actionHelpers } from "../../../../shared/helpers/actionHelpers";
import { Check } from "@material-ui/icons";

const useStyles = makeStyles(pluginGuideStyles);

const PluginGuide = ({ isOpen, handleClose }) => {
  const styles = useStyles();

  const [isChecked, setIsChecked] = useState(false);

  const handlePluginClick = () => {
    window.open(GLOBAL_CONSTANTS.OUTTER_LINKS.X_FRAME_IGNORE, "_blank");
  };

  const handleCheckboxChange = (event) => {
    setIsChecked((prevState) => event.target.checked);
  };

  useEffect(() => {
    if (!isOpen)
      if (isChecked) {
        actionHelpers.setLocalStorage.pluginNotification(false);
      }
  }, [isOpen]);

  return (
    <Dialog open={isOpen} onClose={handleClose} disableBackdropClick>
      <Card classes={{ root: styles.cardContainer }}>
        <CardContent>
          <Grid container>
            <Grid item xs={12}>
              <Typography
                variant="h3"
                classes={{ root: styles.cardDescription }}
              >
                {en.plugin.warning}
              </Typography>
            </Grid>
            <Grid item xs={12} classes={{ root: styles.cardContent }}>
              <Typography
                variant="body2"
                classes={{ root: styles.cardDescription }}
              >
                {en.plugin.description}
              </Typography>
              <Box pt={1}>
                <Typography
                  variant="h6"
                  color="primary"
                  onClick={handlePluginClick}
                  classes={{ root: styles.cardLink }}
                >
                  {en.plugin.link}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Checkbox
                checked={isChecked}
                color="primary"
                onChange={handleCheckboxChange}
                classes={{ root: styles.checkbox }}
                disableFocusRipple
                disableTouchRipple
                disableRipple
              />
              <Typography
                variant="body2"
                classes={{ root: styles.cardDescription }}
                component="span"
              >
                Don't show this again
              </Typography>
            </Grid>
            <Grid item xs={12} classes={{ root: styles.cardAction }}>
              <Button variant="contained" onClick={handleClose} color="primary">
                {en.button.ok}
              </Button>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Dialog>
  );
};

export default PluginGuide;
