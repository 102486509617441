import customClasses from "../../../../shared/helpers/customClasses";
import ForgotPassword from "./ForgotPassword";

const ForgotPasswordStyles = {
  root: {
    height: "25em",
  },
  button: {
    backgroundColor: "#f7931f",
    marginTop: "1em",
  },
  form: {
    ...customClasses.form,
  },
  label: {
    textAlign: "center",
  },
  spinner: {
    display: "flex",
    justifyContent: "center",
    marginTop: "10em",
  },
};

export default ForgotPasswordStyles;
