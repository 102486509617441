const navbarStyles = {
  container: {
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
  },
  userIcon: {
    cursor: "pointer",
  },
  wrapper: {
    backgroundColor: "#333333!important",
  },
  greeting: {
    color: "#F7931F",
  },
  navLinksContainer: {
    display: "flex",
    "& a": {
      margin: "0px 8px",
      textDecoration: "none",
      fontWeight: "900",
    },
  },
  selected: {
    borderBottom: "2px solid #F7931F",
    paddingBottom: "4px",
  },
  logo: {
    cursor: "pointer",
  },
};

export default navbarStyles;
