const createLessonStyles = {
  stepper: {
    backgroundColor: "white",
  },
  container: {
    backgroundColor: "white",
  },
  stepIcon: {
    cursor: "pointer",
  },
};

export default createLessonStyles;
