import { makeStyles } from "@material-ui/core";

const imagePickerStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    margin: "0 10rem",
    height: "45%",
  },

  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },

  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },

  container: {
    overflowY: "auto",
    height: "250px",
  },

  btnContainer: {
    display: "flex",
    justifyContent: "flex end",
    alignItems: "flex-end",
    marginTop: "10px",
  },

  imgStyle: {
    border: "1px solid #ddd",
    margin: "2px",
    cursor: "pointer",
    position: "relative",
    borderRadius: "4px",
    padding: "5px",
  },

  selected: {
    backgroundColor: "#5fc5e7",
  },

  flexcontainer: {
    display: "inline",
  },

  btn: {
    padding: "0.75rem 2rem !important",
  },
  quitButton: {
    position: "fixed",
    top: "50",
    right: "100px",
    color: "white",
    "@media (max-width:400px)": {
      position: "fixed",
      top: "120px",
      left: "300px",
    },
    "@media (min-width:401px) and (max-width:600px)": {
      position: "fixed",
      top: "50",
      right: "100",
    },
  },

  divStyle: {
    display: "flex",
    justifyContent: "flex-end",
  },
  divStyleTab: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: "4rem",
  },

  tabPanelStyle: {
    display: "block !important",
    margin: "0 auto !important",
    background: "white",
  },

  tabPanelImagePicker: {
    width: "100% !important",
    background: "white",
  },

  closeBtn: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  firstTab: {},
  secondTab: {
    fontSize: "14px !important",
    maxWidth: "300px",
    letterSpacing: "0",
    overflowWord: "wrap",
  },
}));

export default imagePickerStyles;
