const lessonCircleStyles = {
  container: {
    position: "relative",
  },
  actionButtonsContainer: {
    position: "sticky",
    bottom: "10px",
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    background: "rgba(255,255,255,.1)",
    borderRadius: "10px",
    backdropFilter: "blur(5px)",
    boxShadow: "0 25px 45px rgba(0,0,0,0.1)",
    border: "1px solid rgba(255,255,255,0.3)",
    borderRight: "1px solid rgba(255,255,255,0.2)",
    borderBottom: "1px solid rgba(255,255,255,0.2)",
    zIndex: "5",
  },
  muted: {
    "&:hover": {
      cursor: "not-allowed",
    },
    firstContainer: {
      marginTop: "2.5rem",
    },
  },
};

export default lessonCircleStyles;
