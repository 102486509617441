import { DASHBOARD_ENDPOINTS } from "./dashboard.endpoints";

const DASHBOARD_CONSTANTS = {
  SET_LESSON_CONTENT: "SET_LESSON_CONTENT",
  SET_FORM_CONTENT: "SET_FORM_CONTENT",
  SET_SELECTED_CONTENT: "SET_SELECTED_CONTENT",
  CHANGE_SELECTED_CONTENT: "CHANGE_SELECTED_CONTENT",
  CHANGE_STEP_DESCRIPTION: "CHANGE_STEP_DESCRIPTION",
  CHANGE_LESSON_DURATION: "CHANGE_LESSON_DURATION",
  SET_CUSTOM_CONTENT: "SET_CUSTOM_CONTENT",
  CHANGE_LEARNING_TYPE: "CHANGE_LEARNING_TYPE",
  UPLOAD_STEP_IMAGE: "UPLOAD_STEP_IMAGE",
  SET_STEP_EMBEDDED_URL: "SET_STEP_EMBEDDED_URL",
  UPLOAD_STEP_VIDEO: "UPLOAD_STEP_VIDEO",
  CHANGE_SUBSTEP_ORDER: "CHANGE_SUBSTEP_ORDER",
  SET_LESSON_TOPIC: "SET_LESSON_TOPIC",
  EMPTY_LESSON_DATA: "EMPTY_LESSON_DATA",
  SET_EXISTING_LESSON: "SET_EXISTING_LESSON",
  UPLOAD_STEP_PDF: "UPLOAD_STEP_PDF",
  CHANGE_COLOR_BACKGROUND: "CHANGE_COLOR_BACKGROUND",
  DELETE_STEP_IMAGE: "DELETE_STEP_IMAGE",
  CHANGE_STEP_STATUS: "CHANGE_STEP_STATUS",
};

export default DASHBOARD_CONSTANTS;
