import React, { useState } from "react";
import { Grid, makeStyles, Button, Typography, Box } from "@material-ui/core";
import Step from "../Step/Step";
import en from "../../../../../assets/languages/en.json";
import SearchBox from "../SearchBox/SearchBox";
import lessonCircleStyles from "./LessonCircle.styles.js";
import VisibilityRoundedIcon from "@material-ui/icons/VisibilityRounded";
import SaveRoundedIcon from "@material-ui/icons/SaveRounded";
import LessonPreview from "../LessonPreview/LessonPreview";
import { useSelector } from "react-redux";
import FullScreenLoader from "../../../../shared/components/FullScreenLoader/FullScreenLoader";
import { useEffect } from "react";
import { dashboardService } from "../../dashboard.service";
import { useHistory } from "react-router";
import { Steps } from "intro.js-react";
import { tutorial } from "../../../../shared/constants/tutorial.constants";
import { actionHelpers } from "../../../../shared/helpers/actionHelpers";

const useStyles = makeStyles(lessonCircleStyles);

const LessonCircle = () => {
  const styles = useStyles();
  const [preview, setPreview] = useState(false);
  const [isLoaderOpen, setIsLoaderOpen] = useState(false);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [stepTitle, setStepTitle] = useState({
    2: null,
    3: null,
    4: null,
    5: null,
    6: null,
    7: null,
    8: null,
  });
  const [activeStep, setActiveStep] = useState(false);
  const [activeSubStep, setActiveSubstep] = useState(false);

  const history = useHistory();

  const content = useSelector((state) => state.dashboardReducer.content);
  const lesson = useSelector((state) => state.dashboardReducer);
  const id = useSelector((state) => state.dashboardReducer.id);

  const selectedContent = useSelector(
    (state) => state.dashboardReducer.selectedContent
  );

  const substepOrder = useSelector(
    (state) => state.dashboardReducer.substepOrder
  );

  const handleTitle = (stepCount) => {
    if (
      !substepOrder[stepCount - 1] ||
      substepOrder[stepCount - 1]?.[0] === 0
    ) {
      const result = selectedContent[stepCount - 2]?.filter(
        (element) => element.checked
      );
      return result?.[0]?.title;
    }
    if (substepOrder[stepCount - 1]?.[0] !== 0) {
      const value = Object.keys(substepOrder[stepCount - 1]).find(
        (key) => substepOrder[stepCount - 1][key] === 0
      );
      return en.lesson.tabs[value];
    }
  };

  useEffect(() => {
    [2, 3, 4, 5, 6, 7, 8].forEach((stepCount) => {
      setStepTitle((prevState) => {
        return {
          ...prevState,
          [stepCount]: handleTitle(stepCount),
        };
      });
    });
  }, [substepOrder]);

  const handleSaveLesson = async () => {
    setIsLoading(true);
    setIsLoaderOpen(true);

    let query = id
      ? dashboardService.patchLessonById
      : dashboardService.createLesson;

    const params = id
      ? { id, lesson: { ...lesson, isDraft: 0 } }
      : { lesson: { ...lesson, isDraft: 1 } };

    const response = await query(params);
    setIsLoading(false);
    if (response.success) {
      setSuccess(true);
    } else {
      setError(true);
    }
  };

  useEffect(() => {
    let timer = null;
    if (success || error) {
      timer = setTimeout(() => {
        setIsLoaderOpen(false);
        setSuccess(false);
        setError(false);
        history.push("/lessons");
      }, 2000);
    }

    return () => {
      clearTimeout(timer);
    };
  }, [success, error]);

  const user = JSON.parse(actionHelpers.getLocalStorage.user());
  const tutorialIsChecked = async () => {
    const response = await dashboardService.updateTutorialStatus();

    if (response.success) {
      actionHelpers.setLocalStorage.user(
        JSON.stringify({ ...user, isTutorialChecked: 1 })
      );
    }
  };
  const stepDuration = useSelector((state) => state.dashboardReducer.duration);

  const editFromPreview = (acStep, acSubstep) => {
    setPreview(false);
    setActiveStep(acStep);
    setActiveSubstep(acSubstep);
  };

  const closeModal = () => {
    setActiveStep(false);
    setActiveSubstep(false);
  };

  return (
    <>
      <Grid container classes={{ root: styles.container }}>
        {/* first step container */}
        <Grid
          container
          justify="center"
          className={{ root: styles.firstContainer }}
        >
          <Grid item xs={12} sm={4} xl={3} className="step-three">
            <Step
              key={1}
              stepCount={`${en.steps.step} 1`}
              title={en.steps.step1.title}
              info={en.steps.step1.content}
              active={{ activeStep: activeStep, activeSubStep: activeSubStep }}
              closeModal={closeModal}
            />
          </Grid>
        </Grid>
        {/* second step container */}
        <Grid container justify="space-evenly">
          <Grid item xs={12} sm={4} xl={3}>
            <Step
              key={2}
              stepCount={`${en.steps.step} 8`}
              title={stepTitle[8] ?? en.steps.step8.title}
              info={en.steps.step8.content}
              defaultTitle={en.steps.step8.title}
              active={{ activeStep: activeStep, activeSubStep: activeSubStep }}
              closeModal={closeModal}
            />
          </Grid>
          <Grid item xs={12} sm={4} xl={3} className="step-two">
            <Step
              key={3}
              stepCount={`${en.steps.step} 2`}
              title={stepTitle[2] ?? en.steps.step2.title}
              info={en.steps.step2.content}
              defaultTitle={en.steps.step2.title}
              active={{ activeStep: activeStep, activeSubStep: activeSubStep }}
              closeModal={closeModal}
            />
          </Grid>
        </Grid>
        {/* third step container */}
        <Grid container>
          <Grid item sm={4} xl={3}>
            <Step
              key={4}
              stepCount={`${en.steps.step} 7`}
              title={stepTitle[7] ?? en.steps.step7.title}
              info={en.steps.step7.content}
              defaultTitle={en.steps.step7.title}
              active={{ activeStep: activeStep, activeSubStep: activeSubStep }}
              closeModal={closeModal}
            />
          </Grid>
          <Grid item sm={4} xl={6} className="step-one">
            <SearchBox keywords={en.lesson.keywords} />
          </Grid>
          <Grid item sm={4} xl={3}>
            <Step
              key={5}
              stepCount={`${en.steps.step} 3`}
              title={stepTitle[3] ?? en.steps.step3.title}
              info={en.steps.step3.content}
              defaultTitle={en.steps.step3.title}
              active={{ activeStep: activeStep, activeSubStep: activeSubStep }}
              closeModal={closeModal}
            />
          </Grid>
        </Grid>
        {/* Fourth step container */}
        <Grid container justify="space-evenly">
          <Grid item xs={12} sm={4} xl={3}>
            <Step
              key={6}
              stepCount={`${en.steps.step} 6`}
              title={stepTitle[6] ?? en.steps.step6.title}
              info={en.steps.step6.content}
              defaultTitle={en.steps.step6.title}
              active={{ activeStep: activeStep, activeSubStep: activeSubStep }}
              closeModal={closeModal}
            />
          </Grid>
          <Grid item xs={12} sm={4} xl={3}>
            <Step
              key={7}
              stepCount={`${en.steps.step} 4`}
              title={stepTitle[4] ?? en.steps.step4.title}
              info={en.steps.step4.content}
              defaultTitle={en.steps.step4.title}
              active={{ activeStep: activeStep, activeSubStep: activeSubStep }}
              closeModal={closeModal}
            />
          </Grid>
        </Grid>
        {/* fifth step container */}
        <Grid container justify="center">
          <Grid item xs={12} sm={4} xl={3}>
            <Step
              key={8}
              stepCount={`${en.steps.step} 5`}
              title={stepTitle[5] ?? en.steps.step5.title}
              info={en.steps.step5.content}
              defaultTitle={en.steps.step5.title}
              active={{ activeStep: activeStep, activeSubStep: activeSubStep }}
              closeModal={closeModal}
            />
          </Grid>
        </Grid>
        <div className={styles.actionButtonsContainer}>
          <Button
            color="primary"
            variant="contained"
            disableRipple
            onClick={() => setPreview(true)}
            disabled={!content.length}
            classes={{ root: !content.length ? styles.muted : null }}
            className="step-four"
          >
            <VisibilityRoundedIcon />
            <Box ml={1}>
              <Typography color="secondary">{en.button.preview}</Typography>
            </Box>
          </Button>
          <Button
            color="primary"
            variant="contained"
            disableRipple
            disabled={!content.length}
            classes={{ root: !content.length ? styles.muted : null }}
            onClick={handleSaveLesson}
            className="step-five"
          >
            <SaveRoundedIcon />
            <Box ml={1}>
              <Typography color="secondary">{en.button.save}</Typography>
            </Box>
          </Button>
        </div>
      </Grid>
      {preview && (
        <LessonPreview
          setPreview={setPreview}
          editFromPreview={(acStep, acSubstep) =>
            editFromPreview(acStep, acSubstep)
          }
        />
      )}
      <FullScreenLoader
        isLoaderOpen={isLoaderOpen}
        success={success}
        error={error}
        isLoading={isLoading}
        isLoadingMessage={en.backdrop.loading}
        successMessage={en.backdrop.success}
        errorMessage={en.backdrop.error}
      />
      {!history.location.state?.step && (
        <Steps
          enabled={true}
          steps={user.isTutorialChecked ? [] : tutorial.lessonCircleSteps}
          initialStep={0}
          onExit={() => tutorialIsChecked()}
          options={{ scrollToElement: false }}
        />
      )}
    </>
  );
};

export default LessonCircle;
