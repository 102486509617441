import axios from "axios";
import { DASHBOARD_ENDPOINTS } from "../../modules/Dashboard/dashboard.endpoints";

const multipleSearch = (keywords, topic) => {
  let queries = keywords.map((keyword, _index) => {
    if (_index === 1) {
      return axios.get(
        `${DASHBOARD_ENDPOINTS.GOOGLE_SEARCH}&q=${keyword.first}${topic}&searchType=image`
      );
    } else {
      return axios.get(
        `${DASHBOARD_ENDPOINTS.GOOGLE_SEARCH}&q=${keyword.first}${topic}`
      );
    }
  });

  return queries;
};

const singleSearch = (customWording, stepCount) => {
  if (stepCount - 2 === 1) {
    return axios.get(
      `${DASHBOARD_ENDPOINTS.GOOGLE_SEARCH}&q=${customWording}&searchType=image`
    );
  } else {
    return axios.get(`${DASHBOARD_ENDPOINTS.GOOGLE_SEARCH}&q=${customWording}`);
  }
};

export const googleSearchBuilder = {
  multipleSearch,
  singleSearch,
};
