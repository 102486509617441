import DASHBOARD_CONSTANTS from "./dashboard.constants";

const initialState = {
  content: [],
  formContent: {},
  selectedContent: [],
  stepDescriptions: {},
  duration: {},
  stepLearningType: {},
  stepImage: {},
  stepVideo: {},
  substepOrder: {},
  stepEmbeddedUrl: {},
  topic: "",
  stepPDF: {},
  backgroundColor: "#f7931f",
  isDraft: 1,
  stepStatus: [],
};

export const dashboardReducer = (state = initialState, action) => {
  switch (action.type) {
    case DASHBOARD_CONSTANTS.SET_LESSON_CONTENT:
      return {
        ...state,
        content: action.payload,
      };

    case DASHBOARD_CONSTANTS.SET_FORM_CONTENT:
      return {
        ...state,
        ...(action.payload.id && { id: action.payload.id }),
        formContent: action.payload.formContent,
      };
    case DASHBOARD_CONSTANTS.SET_SELECTED_CONTENT:
      return {
        ...state,
        selectedContent: action.payload,
      };
    case DASHBOARD_CONSTANTS.CHANGE_STEP_DESCRIPTION:
      const key = action.payload.stepCount + action.payload.key;
      return {
        ...state,
        stepDescriptions: {
          ...state.stepDescriptions,
          [key]: action.payload.text,
        },
      };
    case DASHBOARD_CONSTANTS.CHANGE_LESSON_DURATION:
      return {
        ...state,
        duration: {
          ...state.duration,
          ...action.payload,
        },
      };
    case DASHBOARD_CONSTANTS.SET_CUSTOM_CONTENT:
      return {
        ...state,
        content: action.payload.content,
        selectedContent: action.payload.selectedContent,
      };
    case DASHBOARD_CONSTANTS.CHANGE_LEARNING_TYPE:
      return {
        ...state,
        stepLearningType: { ...state.stepLearningType, ...action.payload },
      };
    case DASHBOARD_CONSTANTS.UPLOAD_STEP_IMAGE:
      return {
        ...state,
        stepImage: { ...state.stepImage, ...action.payload },
      };
    case DASHBOARD_CONSTANTS.UPLOAD_STEP_VIDEO:
      const stepKey = Object.keys(action.payload)[0]

      const stepKeyValue = action.payload[stepKey];

      if (stepKeyValue?.length < 2) {

        const newStepVideo = { ...state.stepVideo };

        delete newStepVideo[stepKey];

        return { ...state, stepVideo: { ...newStepVideo } }
      }

      return {
        ...state,
        stepVideo: { ...state.stepVideo, ...action.payload },
      };
    case DASHBOARD_CONSTANTS.CHANGE_SUBSTEP_ORDER:
      return {
        ...state,
        substepOrder: { ...state.substepOrder, ...action.payload },
      };
    case DASHBOARD_CONSTANTS.SET_STEP_EMBEDDED_URL:
      const stepKeyUrl = Object.keys(action.payload)[0]

      const stepKeyValueUrl = action.payload[stepKeyUrl];

      if (stepKeyValueUrl?.length < 2) {

        const newStepEmbeddedUrl = { ...state.stepEmbeddedUrl };

        delete newStepEmbeddedUrl[stepKeyUrl];

        return { ...state, stepEmbeddedUrl: { ...newStepEmbeddedUrl } }
      }

      return {
        ...state,
        stepEmbeddedUrl: { ...state.stepEmbeddedUrl, ...action.payload },
      };

    case DASHBOARD_CONSTANTS.SET_LESSON_TOPIC:
      return {
        ...state,
        topic: action.payload,
      };
    case DASHBOARD_CONSTANTS.EMPTY_LESSON_DATA:
      return (state = initialState);
    case DASHBOARD_CONSTANTS.SET_EXISTING_LESSON:
      return action.payload;
    case DASHBOARD_CONSTANTS.UPLOAD_STEP_PDF:
      return {
        ...state,
        stepPDF: { ...state.stepPDF, ...action.payload },
      };
    case DASHBOARD_CONSTANTS.CHANGE_COLOR_BACKGROUND:
      return {
        ...state,
        backgroundColor: action.payload,
      };
    case DASHBOARD_CONSTANTS.DELETE_STEP_IMAGE:
      const newStepImage = { ...state.stepImage };
      delete newStepImage[action.payload];

      return {
        ...state,
        stepImage: newStepImage,
      };
    case DASHBOARD_CONSTANTS.CHANGE_STEP_STATUS:
      return {
        ...state,
        stepStatus: action.payload,
      };
    default:
      return state;
  }
};

export default dashboardReducer;
