import React, { useEffect } from "react";
import { makeStyles, Grid } from "@material-ui/core";
import stepEditStyles from "../StepEdit/StepEdit.styles";
import { useDispatch, useSelector } from "react-redux";
import DASHBOARD_CONSTANTS from "../../dashboard.constants";
import { dashboardActions } from "../../dashboard.actions";

import { GLOBAL_CONSTANTS } from "../../../../shared/constants/global.constants";

import { ColorPicker, useColor } from "react-color-palette";
import "react-color-palette/lib/css/styles.css";
const useStyles = makeStyles(stepEditStyles);

const CoverStepEdit = ({
  stepCount,

  setUploadedImage,
  stepImage,
}) => {
  const styles = useStyles();

  const dispatch = useDispatch();

  useEffect(() => {
    if (stepImage.hasOwnProperty([stepCount - 1]) && stepImage[stepCount - 1]) {
      const imageData = stepImage[stepCount - 1].split(`name${stepCount - 1}:`);
      setUploadedImage({
        source: imageData[0],
        name: imageData[1],
      });
    }
  }, [stepImage]);

  const colorFromRedux = useSelector(
    (state) => state.dashboardReducer.backgroundColor
  );

  const [color, setColor] = useColor("hex", colorFromRedux ?? "#FFFFF");

  const handleColorChange = (color) => {
    dispatch(dashboardActions.changeColorBackground(color.hex));
  };

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12} style={{ textAlign: "center" }}>
          <h3>Pick Background Color</h3>
        </Grid>
        <Grid item xs={3}></Grid>
        <ColorPicker
          width={456}
          height={100}
          color={color}
          onChange={(color) => handleColorChange(color)}
          hideHEX
        />
      </Grid>
    </>
  );
};

export default CoverStepEdit;
