import DASHBOARD_CONSTANTS from "./dashboard.constants";
import blobToBase64 from "blob-to-base64";
import { dashboardService } from "./dashboard.service";

const setLessonContent = (content) => (dispatch) => {
  let selectedContent = [];
  let tempContent = null;

  for (let index of content) {
    tempContent = index?.data?.items?.map((item, _index) => {
      if (_index === 0) {
        return {
          ...item,
          checked: true,
        };
      } else
        return {
          ...item,
          checked: false,
        };
    });
    selectedContent.push(tempContent);
  }

  dispatch(setSelectedContent(selectedContent));
  dispatch(setDefaultContent(content));
};

const setFormContent = (content, id) => {
  if (typeof content.image === "object") {
    blobToBase64(content.image, function (error, base64) {
      if (!error) {
        content.image = base64;
      }
    });
  }

  return {
    type: DASHBOARD_CONSTANTS.SET_FORM_CONTENT,
    payload: { formContent: content, id },
  };
};

const setSelectedContent = (content) => {
  return {
    type: DASHBOARD_CONSTANTS.SET_SELECTED_CONTENT,
    payload: content,
  };
};

const setDefaultContent = (content) => {
  return {
    type: DASHBOARD_CONSTANTS.SET_LESSON_CONTENT,
    payload: content,
  };
};

const changeStepDescription = (text, stepCount, key) => {
  return {
    type: DASHBOARD_CONSTANTS.CHANGE_STEP_DESCRIPTION,
    payload: { text, stepCount, key },
  };
};

const changeStepStateStatus = (status) => {
  return {
    type: DASHBOARD_CONSTANTS.CHANGE_STEP_STATUS,
    payload: status,
  };
};

const changeLessonDuration = (duration) => {
  return {
    type: DASHBOARD_CONSTANTS.CHANGE_LESSON_DURATION,
    payload: duration,
  };
};

const setCustomContent = (customContent) => {
  return {
    type: DASHBOARD_CONSTANTS.SET_CUSTOM_CONTENT,
    payload: customContent,
  };
};

const changeLearningTypes = (learningType) => {
  return {
    type: DASHBOARD_CONSTANTS.CHANGE_LEARNING_TYPE,
    payload: learningType,
  };
};

const uploadStepImage = (image) => {
  return {
    type: DASHBOARD_CONSTANTS.UPLOAD_STEP_IMAGE,
    payload: image,
  };
};

const uploadStepVideo = (url) => {
  return {
    type: DASHBOARD_CONSTANTS.UPLOAD_STEP_VIDEO,
    payload: url,
  };
};

const uploadStepPDF = (pdf) => {
  return {
    type: DASHBOARD_CONSTANTS.UPLOAD_STEP_PDF,
    payload: pdf,
  };
};

const changeSubstepOrder = (order) => {
  return {
    type: DASHBOARD_CONSTANTS.CHANGE_SUBSTEP_ORDER,
    payload: order,
  };
};

const setStepEmbeddedUrl = (url) => {
  return {
    type: DASHBOARD_CONSTANTS.SET_STEP_EMBEDDED_URL,
    payload: url,
  };
};

const setLessonTopic = (topic) => {
  return {
    type: DASHBOARD_CONSTANTS.SET_LESSON_TOPIC,
    payload: topic,
  };
};

const emptyLessonData = () => {
  return {
    type: DASHBOARD_CONSTANTS.EMPTY_LESSON_DATA,
  };
};

const changeColorBackground = (color) => {
  return {
    type: DASHBOARD_CONSTANTS.CHANGE_COLOR_BACKGROUND,
    payload: color,
  };
};

const getExistingLesson = (lessonId) => async (dispatch) => {
  console.log("not ready yet")
  const response = await dashboardService.getLessonById(lessonId);
  if (response.success) {
    dispatch(setExistingLesson(response.success.data.data.results));
  }
  console.log("ready")
};

const setExistingLesson = (lesson) => {
  return {
    type: DASHBOARD_CONSTANTS.SET_EXISTING_LESSON,
    payload: lesson,
  };
};

const deleteImage = (stepCount) => {
  return {
    type: DASHBOARD_CONSTANTS.DELETE_STEP_IMAGE,
    payload: stepCount,
  };
};

export const dashboardActions = {
  setLessonContent,
  setFormContent,
  setSelectedContent,
  changeStepDescription,
  changeLessonDuration,
  setCustomContent,
  changeLearningTypes,
  uploadStepImage,
  uploadStepVideo,
  changeSubstepOrder,
  setStepEmbeddedUrl,
  setLessonTopic,
  emptyLessonData,
  getExistingLesson,
  uploadStepPDF,
  changeColorBackground,
  deleteImage,
  changeStepStateStatus,
};
