import { GLOBAL_CONSTANTS } from "../constants/global.constants";
import { AUTH_ENDPOINTS } from "../../modules/login/auth.endpoints";
import axios from "axios";
import { actionHelpers } from "../helpers/actionHelpers";

const signOut = async () => {
  const response = await axios({
    method: GLOBAL_CONSTANTS.AXIOS_METHODS.POST,
    url: AUTH_ENDPOINTS.SIGN_OUT,
    headers: {
      [GLOBAL_CONSTANTS.AUTHORIZATION_HEADER
        .AUTHORIZATION]: actionHelpers.getLocalStorage.accessToken(),
    },
  });
  return response;
};

export const authService = {
  signOut,
};
