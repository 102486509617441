import React from "react";
import { createMuiTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import theme from "./Theme.styles";

const customTheme = createMuiTheme(theme);

const ThemeProviderHOC = ({ children }) => {
  return <ThemeProvider theme={customTheme}>{children}</ThemeProvider>;
};

export default ThemeProviderHOC;
