const form = {
  textAlign: "center",
  marginTop: "2em",
  "& > *": {
    marginBottom: "2em",
  },
  "& label.Mui-focused": {
    color: "#f7931f",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#f7931f",
  },
  "&.Mui-focused fieldset": {
    borderColor: "#f7931f",
  },
};

const quitButton = {
  position: "absolute",
  top: "0px",
  right: "1%",
  display: "inline-block",
  opacity: " 0.7",
};

const customClasses = {
  form,
  quitButton,
};

export default customClasses;
