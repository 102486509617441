import { Typography, Grid, Box } from "@material-ui/core";
import React from "react";
import { useSelector } from "react-redux";
import { GLOBAL_CONSTANTS } from "../../../../shared/constants/global.constants";
import styles from "./CoverPreview.module.scss";
import en from "../../../../../assets/languages/en.json";
import TextColor from "./TextColor";
import ReactQuill from "react-quill";
import { useParams } from "react-router-dom";

const CoverPreview = ({ stepCount }) => {
  const { id } = useParams();

  const backgroundColor = useSelector(
    (state) => state.dashboardReducer.backgroundColor
  );
  const lessonTitle = useSelector(
    (state) => state.dashboardReducer.formContent.title
  );

  const stepCover = useSelector(
    (state) =>
      state.dashboardReducer.stepImage[
        stepCount - GLOBAL_CONSTANTS.STEP_TYPE.DEFAULT_FIRST_STEP
      ]
  );

  const objectives = useSelector(
    (state) => state.dashboardReducer.stepDescriptions[en.label.firstStepNotes]
  );

  return (
    <Grid container>
      <Grid item xs={12}>
        <Box m={2} className={styles.boxStyle}>
          <Typography variant="h4" alignText="center">
            {lessonTitle}
          </Typography>
        </Box>
        <Grid item xs={12}>
          {stepCover && (
            <div className={styles.container}>
              <img src={stepCover.split("name")[0]} />
            </div>
          )}
        </Grid>

        <Grid
          item
          xs={12}
          style={{
            background: backgroundColor,
            overflowWrap: "break-word",
          }}
        >
          <Box m={2}>
            <div
              className={id ? styles.objectivesFullScreen : styles.objectives}
            >
              <Typography
                className={styles.boxStyle}
                color="secondary"
                variant="h6"
                style={{ color: TextColor(backgroundColor) }}
              >
                {en.lesson.aims}
              </Typography>

              <Typography className={id ? styles.topographyStyle : ""}>
                <ReactQuill
                  value={objectives ?? ""}
                  readOnly
                  modules={{ toolbar: null }}
                  theme={"bubble"}
                  className={!id ? styles.quill : styles.quillFullScreen}
                />
              </Typography>
            </div>
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default CoverPreview;
