import customClasses from "../../../../shared/helpers/customClasses";

const signUpStyles = {
  root: {
    // height: "25em",
  },
  button: {
    backgroundColor: "#f7931f",
    marginTop: "1em",
  },
  label: {
    textAlign: "center",
  },
  form: {
    ...customClasses.form,
  },
  spinner: {
    display: "flex",
    justifyContent: "center",
    marginTop: "10em",
  },
};

export default signUpStyles;
