const lessonSnippetStyles = {
  container: {
    height: "360px",
    position: "relative",
  },

  containerBackground: {
    position: "absolute",
    top: 0,
    left: 0,
    height: "65%",
    width: "100%",
  },
  background: {
    width: "100%",
    height: "100%",
    objectFit: "scale-down",
  },

  shade: {
    position: "sticky",
    bottom: "0px",
    zIndex: "1",
    backgroundColor: "#fff",
    color: "back",
    height: "35%",
    padding: "6px",
  },

  description: {
    paddingTop: "8px",
    width: "100%",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    borderTop: "1px solid #bbb",
  },

  icons: {
    paddingTop: "6px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "#212121",
    cursor: "pointer",
    "& div": {
      marginLeft: "16px",
    },
  },
  editIcon: {
    height: "22px",
    width: "22px",
    marginLeft: "3px",
    cursor: "pointer",
  },

  draft: {
    color: "unset",
    border: "1px solid black",
    borderRadius: "2px",
  },

  copyText: {
    fontSize: "10px",
    fontWeight: "bold",
    color: "green",
  },
  duplicateLessonIcon: {
    marginLeft: "15px",
  },
  footer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "5px",
  },
  subject: {
    width: "100%",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "flex",
    justifyContent: "space-between",
  },
};

export default lessonSnippetStyles;
