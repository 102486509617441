import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { authActions } from "../../auth.actions";
import { useHistory } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Alert from "@material-ui/lab/Alert";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import en from "../../../../../assets/languages/en.json";
import SignInStyles from "./SignIn.styles";
import Link from "@material-ui/core/Link";

const useStyles = makeStyles(SignInStyles);

const SignIn = ({ isReset }) => {
  const styles = useStyles();

  const [inputs, setInputs] = useState({
    username: "",
    password: "",
  });

  const [hasSignInAttempt, setHasSignInAttempt] = useState(false);
  const [error, setError] = useState(false);

  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.authReducer.loading);
  const isAuthenticated = useSelector(
    (state) => state.baseAuthReducer.isAuthenticated
  );
  const forgotPasswordError = useSelector(
    (state) => state.authReducer.forgotPasswordError
  );

  const forgotPasswordSuccess = useSelector(
    (state) => state.authReducer.forgotPasswordSuccess
  );

  const history = useHistory();

  useEffect(() => {
    if (isAuthenticated) {
      history.push("/dashboard");
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (isLoading) {
      setHasSignInAttempt(true);
    }
  }, [isLoading]);

  useEffect(() => {
    if (hasSignInAttempt && !isAuthenticated && !isLoading) {
      setError(true);
      setHasSignInAttempt(false);
    }
  }, [hasSignInAttempt, isLoading]);

  useEffect(() => {
    let timer = null;
    if (error) {
      timer = setTimeout(() => {
        setError(false);
      }, 2000);
    }

    return () => clearTimeout(timer);
  }, [error]);

  const handleInputChange = (e) => {
    setInputs((prevInputs) => {
      return {
        ...prevInputs,
        [e.target.name]: e.target.value,
      };
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    isReset
      ? dispatch(authActions.forgotPassword({ email: inputs.username }))
      : dispatch(authActions.logIn(inputs));
  };

  return (
    <Grid container justify="center" className={styles.root}>
      <Grid item>
        <Typography variant="h3" color="primary" className={styles.label}>
          {isReset ? "Reset Password" : en.authentication.labelSignIn}
        </Typography>
        <Grid item xs={12}>
          {isLoading ? (
            <div className={styles.spinner}>
              <CircularProgress />
            </div>
          ) : (
            <form onSubmit={handleSubmit} className={styles.form}>
              <TextField
                label={en.label.email}
                placeholder={en.label.emailExample}
                fullWidth
                required
                type="email"
                value={inputs.username}
                onChange={handleInputChange}
                name="username"
              />
              {!isReset && (
                <TextField
                  label={en.label.password}
                  fullWidth
                  required
                  type="password"
                  value={inputs.password}
                  onChange={handleInputChange}
                  name="password"
                />
              )}
              <Button
                type="submit"
                disableRipple
                variant="contained"
                className={styles.button}
              >
                <Typography color="secondary">
                  {isReset ? "Reset" : en.authentication.labelSignIn}
                </Typography>
              </Button>
            </form>
          )}
        </Grid>
      </Grid>
      <Grid item>
        {forgotPasswordSuccess && (
          <Alert severity="success" style={{ alignSelf: "center" }}>
            Please check your email to enter new password!
          </Alert>
        )}
        {forgotPasswordError && (
          <Alert severity="error">User does not exist</Alert>
        )}
        {error && !isReset && (
          <Alert severity="error">{en.authentication.erroSignIn}</Alert>
        )}
      </Grid>
    </Grid>
  );
};

export default SignIn;
