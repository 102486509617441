const listLessonsStyles = {
  container: {
    minHeight: "75vh",
    marginBottom: "16px",
  },
  fabIcon: {
    display: "flex",
    justifyContent: "flex-end",
  },
  spinnerContainer: {
    position: "absolute",
    top: 0,
    left: 0,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    width: "100%",
  },
  weekSelector: {
    width: "200px",
    "&:focus": {
      backgroundColor: "transparent",
    },
    "& ul": {
      height: "100px",
    },
  },

  filter: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: "8px",
  },
  tansparentnBackground: {
    position: "sticky",
    bottom: "0",
    background: "rgba(255,255,255,.1)",
    borderRadius: "10px",
    backdropFilter: "blur(5px)",
    boxShadow: "0 25px 45px rgba(0,0,0,0.1)",
    border: "1px solid rgba(255,255,255,0.3)",
    borderRight: "1px solid rgba(255,255,255,0.2)",
    borderBottom: "1px solid rgba(255,255,255,0.2)",
    zIndex: "5",
  },
  footerStyle: {
    display: "flex",
    justifyContent: "center",
    alignContent: "center",
    alignItems: "center",
  },
};

export default listLessonsStyles;
