import { AUTH_CONSTANTS } from "./auth.constants";

const initialState = {
  loading: false,
  confirmationPending: false,
  fullWindow: false,
  forgotPasswordError: false,
  changePasswordError: false,
  forgotPasswordSuccess: false,
  changePasswordSuccess: false,
};

export const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case AUTH_CONSTANTS.SIGN_UP_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case AUTH_CONSTANTS.SIGN_UP_SUCCESS:
      return {
        ...state,
        loading: false,
        confirmationPending: true,
      };
    case AUTH_CONSTANTS.SIGN_UP_FAIL:
      return {
        ...state,
        loading: false,
        confirmationPending: false,
      };
    case AUTH_CONSTANTS.CONFIRMATION_MODAL:
      return {
        ...state,
        confirmationPending: false,
      };
    case AUTH_CONSTANTS.SIGN_IN_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case AUTH_CONSTANTS.SIGN_IN_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case AUTH_CONSTANTS.SIGN_IN_FAIL:
      return {
        ...state,
        loading: false,
      };
    case AUTH_CONSTANTS.FULL_SCREEN:
      return {
        ...state,
        fullWindow: !state.fullWindow,
      };
    case AUTH_CONSTANTS.FORGOT_PASSWORD_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case AUTH_CONSTANTS.FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        forgotPasswordError: false,
        forgotPasswordSuccess: true,
      };

    case AUTH_CONSTANTS.FORGOT_PASSWORD_FAIL:
      return {
        ...state,
        loading: false,
        forgotPasswordError: true,
        forgotPasswordSuccess: false,
      };
    case AUTH_CONSTANTS.CHANGE_PASSWORD_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case AUTH_CONSTANTS.CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        changePasswordError: false,
        changePasswordSuccess: true,
      };

    case AUTH_CONSTANTS.CHANGE_PASSWORD_FAIL:
      return {
        ...state,
        loading: false,
        changePasswordError: true,
      };
    default:
      return state;
  }
};
