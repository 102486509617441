import React from "react";
import { Button, Box } from "@material-ui/core";
import LazyLoadedImage from "./LazyLoadImage";
import { useTheme } from "@material-ui/core";
import imagePickerStyles from "./ImagePicker.Styles";
import { useState } from "react";
import { useEffect } from "react";
import { dashboardService } from "../../dashboard.service";
import { CircularProgress } from "@material-ui/core";

const ImagePicker = ({ handleClose, inputs, setInputs }) => {
  const [images, setImages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [totalImages, setTotalImages] = useState(0);
  const [page, setPage] = useState(-1);

  const fetchImages = async () => {
    if (totalImages == 0 || totalImages > images.length) {
      // setIsLoading(true);

      const staticImages = await dashboardService.getStaticImages(page + 1);

      if (staticImages.success) {
        const newImages = [...images, ...staticImages.success.data.data.images];
        setImages(newImages);
        setTotalImages(staticImages.success.data.data.total);
        setPage(staticImages.success.data.data.page);
      }
      // setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchImages();
  }, []);

  const theme = useTheme();
  const classes = imagePickerStyles(theme);

  const handleScroll = (e) => {
    e.stopPropagation();
    const endScrollReached =
      e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;

    endScrollReached && fetchImages();
  };
  return (
    <div>
      <div className={classes.container} onScroll={handleScroll}>
        {isLoading ? (
          <CircularProgress color="primary" />
        ) : (
          images.map((image, index) => (
            <LazyLoadedImage
              key={index}
              image={image}
              inputs={inputs}
              setInputs={setInputs}
            />
          ))
        )}
      </div>
      <Box className={classes.divStyleTab}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            handleClose();
          }}
        >
          Next
        </Button>
      </Box>
    </div>
  );
};

export default ImagePicker;
