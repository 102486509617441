const confirmModalStyles = {
  buttonsContainer: {
    textAlign: "right",
    marginTop: "16px",
  },
  titleContainer: {
    borderBottom: "1px solid #F7931F",
  },
  secondaryWarning: {
    marginTop: "0px",
  },
};

export default confirmModalStyles;
