import React, { useState, useEffect } from "react";
import { Grid, Box, makeStyles } from "@material-ui/core";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import en from "../../../../../assets/languages/en.json";
import LessonForm from "../../components/LessonForm/LessonForm";
import LessonCircle from "../../components/LessonCircle/LessonCircle";
import createLessonStyles from "./CreateLesson.styles";
import PluginGuide from "../../components/PluginGuide/PluginGuide";
import { useDispatch } from "react-redux";
import { dashboardActions } from "../../dashboard.actions";
import { actionHelpers } from "../../../../shared/helpers/actionHelpers";

const useStyles = makeStyles(createLessonStyles);

const getSteps = () => {
  return [en.stepper.step0, en.stepper.step1];
};

const CreateLesson = (props) => {
  const styles = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  const [stepState, setStepState] = useState([]);
  const [isOpen, setIsOpen] = useState(true);
  const dispatch = useDispatch();
  const lessonId = props.location?.state?.lessonId;
  const handleClose = () => {
    setIsOpen(false);
  };

  const steps = getSteps();

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return <LessonForm handleNext={handleNext} isOpen={isOpen} />;
      case 1:
        return <LessonCircle />;
      default:
        return null;
    }
  };

  useEffect(() => {
    const pluginNotification = JSON.parse(
      actionHelpers.getLocalStorage.pluginNotification()
    );
    setIsOpen(pluginNotification);

    if (!lessonId) {
      dispatch(dashboardActions.emptyLessonData());
    }
    if (lessonId) {
      setActiveStep(1);
      dispatch(dashboardActions.getExistingLesson(lessonId));
    }
  }, []);

  return (
    <>
      <Box my={4}>
        <Grid container justify="center">
          <Grid item xs={12} md={10} className={styles.container}>
            <Stepper activeStep={activeStep} classes={{ root: styles.stepper }}>
              {steps.map((label, index) => {
                const stepProps = {};
                const labelProps = {};
                return (
                  <Step key={label} {...stepProps}>
                    <StepLabel
                      {...labelProps}
                      className={styles.stepIcon}
                      onClick={() => setActiveStep(index)}
                    >
                      {label}
                    </StepLabel>
                  </Step>
                );
              })}
            </Stepper>
            <Box px={6} py={3}>
              {getStepContent(activeStep)}
            </Box>
          </Grid>
        </Grid>
      </Box>
      <PluginGuide isOpen={isOpen} handleClose={handleClose} />
    </>
  );
};

export default CreateLesson;
