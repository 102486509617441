import React, { useEffect, useState } from "react";
import Modal from "../../../../shared/components/Modal/Modal";
import { GLOBAL_CONSTANTS } from "../../../../shared/constants/global.constants";
import { useDispatch, useSelector } from "react-redux";
import { dashboardActions } from "../../dashboard.actions";

const LessonPreview = ({ setPreview, lessonId = null, editFromPreview }) => {
  const [stepCount, setStepCount] = useState(
    GLOBAL_CONSTANTS.STEP_TYPE.DEFAULT_FIRST_STEP
  );
  const [isModalOpen, setIsModalOpen] = useState(true);

  const stepState = useSelector((state) => state.dashboardReducer.stepStatus);
  const enabledSteps = [1, 2, 3, 4, 5, 6, 7, 8].filter(
    (step) => !stepState.includes(step)
  );

  useEffect(() => {
    if (!isModalOpen) {
      setPreview(false);
      return () => setStepCount(GLOBAL_CONSTANTS.STEP_TYPE.DEFAULT_FIRST_STEP);
    }
  }, [isModalOpen]);

  const dispatch = useDispatch();

  useEffect(() => {
    if (lessonId) {
      dispatch(dashboardActions.getExistingLesson(lessonId));
    }
  }, []);

  const handleIncreaseStepCount = () => {
    if (stepCount > enabledSteps.length) {
      setStepCount(GLOBAL_CONSTANTS.STEP_TYPE.DEFAULT_FIRST_STEP);
      return;
    }

    const currentStepIndex = enabledSteps.indexOf(stepCount);

    setStepCount(enabledSteps[currentStepIndex + 1]);
  };

  const handleDecreaseStepCount = () => {
    const currentStepIndex = enabledSteps.indexOf(stepCount);
    const newStep = enabledSteps[currentStepIndex - 1]
      ? enabledSteps[currentStepIndex - 1]
      : 1;
    setStepCount(newStep);
  };

  return (
    <>
      <Modal
        lessonId={lessonId}
        stepCount={stepCount ?? 1}
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        lessonPreview={true}
        editFromPreview={(acStep, acSubstep) =>
          editFromPreview(acStep, acSubstep)
        }
        handleIncreaseStepCount={handleIncreaseStepCount}
        handleDecreaseStepCount={handleDecreaseStepCount}
      />
    </>
  );
};

export default LessonPreview;
