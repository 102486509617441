import React, { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import styles from "./ResultWindow.module.scss";
import { GLOBAL_CONSTANTS } from "../../../../shared/constants/global.constants";

const ResultWindow = ({ stepCount }) => {
  const [selected, setSelected] = useState(null);
  const url = useSelector((state) => state.dashboardReducer);
  const containerRef = useRef(null);

  useEffect(() => {
    if (url?.selectedContent.length > 0 && stepCount > GLOBAL_CONSTANTS.STEP_TYPE.DEFAULT_FIRST_STEP) {
      for (const item of url?.selectedContent[stepCount - GLOBAL_CONSTANTS.STEP_TYPE.DEFAULT_NEXT_TO_FIRST_STEP]) {
        if (item.checked) {
          setSelected(item);
          break;
        }
      }
    }
  }, [stepCount]);

  return (
    <>
      {stepCount === GLOBAL_CONSTANTS.STEP_TYPE.IMAGE_SEARCH ? (
        <img
          className={styles.image}
          alt={!selected ? url?.content[stepCount - 2]?.data?.items[0].title : selected.title}
          src={!selected ? url?.content[stepCount - 2]?.data?.items[0].link : selected.link}
        ></img>
      ) : (
        <iframe
          src={!selected ? url?.content[stepCount - 2]?.data?.items[0].link : selected.link}
          title={`step ${stepCount} default selection`}
          className={styles.frame}
        ></iframe>
      )}
    </>
  );
};

export default ResultWindow;
