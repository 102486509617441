import img from "../../../../../assets/images/background.png";
import { createMuiTheme } from "@material-ui/core";
const homePageStyles = {
  wrapper: {
    height: "100vh",
    paddingTop: "0px",
    background: `linear-gradient(rgba(170,170,170, .25), rgba(170,170,170,.25)), url(${img}) no-repeat`,
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  container__quote: {
    position: "relative",
  },
  quote__icon: {
    position: "absolute",
    zIndex: "5",
    left: "48%",
    borderRadius: "50%",
    top: "-30px",
    backgroundColor: "white",
    padding: "10px",
    boxShadow: "0px -4px 3px rgba(50, 50, 50, 0.20)",
    color: "#443F40",
  },
  root: {
    overflow: "scroll",
  },
  paper: {
    width: "400px",
  },
  buttons: {
    color: "#fff",
    "@media (max-width: 420px)": {
      fontSize: "1.2rem",
    },
    "@media(min-width:421px) and (max-width: 680px)": {
      fontSize: "1.3rem",
    },
  },
  buttonsSmall: {
    color: "#fff",
    fontSize: "1rem",
  },
};

export default homePageStyles;
