import React, { useEffect, useState } from "react";
import {
  List,
  ListSubheader,
  ListItemText,
  ListItem,
  makeStyles,
  Paper,
  Box,
  Typography,
} from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import subjectLessonList from "./SubjectLessonList.styles";
import { dashboardService } from "../../dashboard.service";
import en from "../../../../../assets/languages/en.json";

const useStyles = makeStyles(subjectLessonList);

const SubjectLessonList = () => {
  const styles = useStyles();

  const [isLoading, setIsLoading] = useState(false);
  const [list, setList] = useState([]);
  const [error, setError] = useState(false);
  const lines = new Array(12).fill(1);

  const getData = async () => {
    setIsLoading(true);
    const response = await dashboardService.listSubjectLesson();
    if (response.success) setList(response.success.data.data.subjects);
    if (response.error) setError(true);
    setIsLoading(false);
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div className={styles.container}>
      <Paper>
        <Box p={4}>
          <Typography variant="h5" color="primary">
            {en.label.myCurriculum}
          </Typography>
        </Box>
        {isLoading ? (
          <Box px={1} height={610}>
            {lines.map((line, _index) => (
              <Skeleton
                key={_index}
                variant="text"
                classes={{ root: styles.skeleton }}
              />
            ))}
          </Box>
        ) : (
          <List className={styles.root} subheader={<li />}>
            {list.map((element) => (
              <li key={element.id} className={styles.listSection}>
                <ul className={styles.ul}>
                  <ListSubheader className={styles.header}>
                    {element.title}
                  </ListSubheader>
                  {element.lessons.map((item) => (
                    <ListItem key={item.id}>
                      <ListItemText
                        primary={`${en.lesson.academicWeek} ${
                          item.week ?? en.lesson.na
                        }: ${item.title} - ${item.topic}`}
                      />
                    </ListItem>
                  ))}
                </ul>
              </li>
            ))}
          </List>
        )}
      </Paper>
    </div>
  );
};

export default SubjectLessonList;
