import React from "react";
import { makeStyles, Typography, Grid } from "@material-ui/core";
import { GLOBAL_CONSTANTS } from "../../constants/global.constants";
import en from "../../../../assets/languages/en.json";

import learningTipStyles from "./LearningTip.styles";

const useStyles = makeStyles(learningTipStyles);

const LearningTip = () => {
  const styles = useStyles();
  const learningTips = GLOBAL_CONSTANTS.LEARNING_TIPS;
  const activityIdeas = GLOBAL_CONSTANTS.ACTIVITY_IDEAS;
  return (
    <Grid xs={12}>
      <div className={styles.divStyle}>
        <Typography variant="h6" color="secondary">
          {en.label.learningTip}
        </Typography>{" "}
        <Typography variant="h6" color="secondary" className={styles.mLeft}>
          {en.label.activityIdeas}
        </Typography>{" "}
      </div>
      <div className={styles.divStyle}>
        <div className={styles.pRight}>
          {learningTips.map((content, index) => (
            <div className={styles.divContainer} key={index}>
              <img src={content.imagePath} className={styles.imgStyles} />
              <a
                href={content.link}
                target="_blank"
                className={styles.linkStyles}
              >
                <Typography variant="body2" color="textPrimary">
                  {content.text}
                </Typography>
              </a>
            </div>
          ))}
        </div>
        <div className={styles.activityStyles}>
          {activityIdeas.map((content, index) => (
            <div className={styles.divContainer} key={index}>
              <a href="#" className={styles.linkStyles}>
                <Typography variant="body2" color="textPrimary">
                  {content.text}
                </Typography>
              </a>
            </div>
          ))}
        </div>
      </div>
    </Grid>
  );
};

export default LearningTip;
