import AUTH_CONSTANTS from "../constants/baseAuth.constants";
import { actionHelpers } from "../helpers/actionHelpers";
import { authService } from "../services/auth.service";
import { useHistory } from "react-router-dom";
import { GLOBAL_CONSTANTS } from "../constants/global.constants";

const setAuthenticatedUser = {
  type: AUTH_CONSTANTS.SET_AUTHENTICATED_USER,
};

const clearStore = {
  type: AUTH_CONSTANTS.CLEAR_STORE,
};

const removeAuthenticatedUser = () => async (dispatch) => {
  authService.signOut();
  dispatch(clearStore);
  actionHelpers.clear.localStorage();
  window.location.href = "/";
};

export const userActions = {
  setAuthenticatedUser,
  removeAuthenticatedUser,
};
