import { actionHelpers } from "../helpers/actionHelpers";
import AUTH_CONSTANTS from "../constants/baseAuth.constants";

const initialState = {
  isAuthenticated: actionHelpers.getLocalStorage.user() ? true : false,
  authenticatedUser: actionHelpers.getLocalStorage.user(),
};

export const baseAuthReducer = (state = initialState, action) => {
  switch (action.type) {
    case AUTH_CONSTANTS.SET_AUTHENTICATED_USER:
      return {
        ...state,
        isAuthenticated: true,
        authenticatedUser: actionHelpers.getLocalStorage.user(),
      };
    case AUTH_CONSTANTS.REMOVE_AUTHENTICATED_USER:
      return {
        ...state,
        isAuthenticated: false,
        authenticatedUser: actionHelpers.getLocalStorage.user(),
      };
    default:
      return state;
  }
};
