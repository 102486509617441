import React from "react";
import {
  Card,
  CardContent,
  Typography,
  makeStyles,
  Box,
} from "@material-ui/core";
import stepTipStyles from "./StepTip.styles";
import en from "../../../../../assets/languages/en.json";

const useStyles = makeStyles(stepTipStyles);

const StepTip = ({ index, title, content }) => {
  const styles = useStyles();

  return (
    <Card variant="outlined" className={styles.root}>
      <CardContent>
        <Typography variant="h6">
          {index > 4 ? `${en.steps.step} ${index - 4}: ` : null}
          {title}
        </Typography>
        <Typography>
          <Box p={2}>{content}</Box>
        </Typography>
      </CardContent>
    </Card>
  );
};

export default StepTip;
