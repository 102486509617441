const searchBoxStyles = {
  searchForm: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    minHeight: "100%",
  },
  topic: {
    letterSpacing: "0.3em",
    fontWeight: "700",
    textTransform: "capitalize",
    display: "flex",
    justifyContent: "center",
  },
  popover: {
    pointerEvents: "none",
  },
  paper: {
    padding: "0.3em",
    maxWidth: "150px",
  },
  file: {
    display: "block",
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
  },
  gridStyle: {
    paddingBottom: "10px",
  },
};

export default searchBoxStyles;
