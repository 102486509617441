import BackgroundImage from "./img/emptybackground.png";

const { innerWidth: width, innerHeight: height } = window;

const authCardStyles = {
  underline: {
    textAlign: "center",
    "&:hover": {
      textDecoration: "underline",
    },
    cursor: "pointer",
  },
  authBody: {
    backgroundColor: "#eee",
    minHeight: "100vh",
    height: "100%",
    display: "flex",
    paddingRight: "6em",
    justifyContent: "center",
    alignItems: "center",
    background: "url(" + BackgroundImage + ") no-repeat center center",
    backgroundSize: "100% 100%",
    "@media(max-width: 960px)": {
      background: "none",
    },
  },
  logo: {
    position: "absolute",
    width: "15%",
    top: "2em",
    left: "2em",
  },
  people: {
    position: "absolute",
    height: "100vh",
    width: "161vh",
    left: "0",
    bottom: "0",
  },
  circleIcons: {
    position: "absolute",
    height: "100vh",
    width: "161vh",
    left: "0",
    bottom: "0",
  },
  card: {
    width: "20em",
    padding: "20px",
    boxShadow: "0px 0px 16px 4px #676262",
    "@media(max-width: 960px)": {
      width: "100%",
    },
  },
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",

    "@media(max-height: 850px)": {
      margin: "0rem auto",
    },
  },
};

export default authCardStyles;
