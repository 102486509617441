import { SportsRugbySharp } from "@material-ui/icons";

const subjectLessonList = {
  root: {
    width: "100%",
    position: "relative",
    backgroundColor: "#FFF",
    overflow: "auto",
    height: "37.5em",
  },
  listSection: {
    backgroundColor: "inherit",
    fontWeight: "900",
    color: "rgba(0, 0, 0, 0.54)",
  },
  ul: {
    backgroundColor: "inherit",
    padding: 0,
  },
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  skeleton: {
    width: "99%",
    height: "3em",
  },
  header: {
    color: "#433F40",
  },
};

export default subjectLessonList;
