import React, { useState, useEffect } from "react";
import {
  CircularProgress,
  Backdrop,
  makeStyles,
  Typography,
  Box,
  Zoom,
} from "@material-ui/core";
import fullScreenLoader from "./FullScreenLoader.styles";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ErrorRoundedIcon from "@material-ui/icons/ErrorRounded";
import en from "../../../../assets/languages/en.json";

const useStyles = makeStyles(fullScreenLoader);
const FullScreenLoader = ({
  isLoaderOpen,
  success,
  error,
  isLoading,
  successMessage,
  errorMessage,
  isLoadingMessage,
}) => {
  const styles = useStyles();

  return (
    <div>
      <Backdrop open={isLoaderOpen} classes={{ root: styles.root }}>
        <Box m={2}>
          {isLoading && (
            <Zoom in={isLoading}>
              <CircularProgress color="primary" />
            </Zoom>
          )}
          {success && (
            <Zoom in={success}>
              <CheckCircleIcon className={styles.success} />
            </Zoom>
          )}
          {error && (
            <Zoom in={error}>
              <ErrorRoundedIcon className={styles.error} />
            </Zoom>
          )}
        </Box>

        <Typography className={styles.description} variant="h6">
          {isLoading && isLoadingMessage}
          {success && successMessage}
          {error && errorMessage}
        </Typography>
      </Backdrop>
    </div>
  );
};

export default FullScreenLoader;
