export default function TextColor(backgroundColor) {
  backgroundColor = backgroundColor.replace("#", "");

  const red = parseInt(backgroundColor.substr(0, 2), 16);
  const green = parseInt(backgroundColor.substr(2, 2), 16);
  const blue = parseInt(backgroundColor.substr(4, 2), 16);

  const textColor = (red * 299 + green * 587 + blue * 114) / 1000;

  return textColor >= 128 ? "black" : "white";
}
