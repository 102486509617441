import { GLOBAL_CONSTANTS } from "../constants/global.constants";

const setLocalStorage = {
  accessToken: (accessToken) => {
    localStorage.setItem(
      GLOBAL_CONSTANTS.LOCAL_STORAGE_FIELD_TYPE.ACCESS_TOKEN,
      accessToken
    );
  },
  refreshToken: (refreshToken) => {
    localStorage.setItem(
      GLOBAL_CONSTANTS.LOCAL_STORAGE_FIELD_TYPE.REFRESH_TOKEN,
      refreshToken
    );
  },
  user: (user) => {
    localStorage.setItem(GLOBAL_CONSTANTS.LOCAL_STORAGE_FIELD_TYPE.USER, user);
  },
  pluginNotification: (pluginNotification) => {
    localStorage.setItem(
      GLOBAL_CONSTANTS.LOCAL_STORAGE_FIELD_TYPE.PLUGIN_NOTIFICATION,
      pluginNotification
    );
  },
};

const getLocalStorage = {
  accessToken: () =>
    localStorage.getItem(
      GLOBAL_CONSTANTS.LOCAL_STORAGE_FIELD_TYPE.ACCESS_TOKEN
    ),
  refreshToken: () =>
    localStorage.getItem(
      GLOBAL_CONSTANTS.LOCAL_STORAGE_FIELD_TYPE.REFRESH_TOKEN
    ),
  user: () =>
    localStorage.getItem(GLOBAL_CONSTANTS.LOCAL_STORAGE_FIELD_TYPE.USER),
  pluginNotification: () =>
    localStorage.getItem(
      GLOBAL_CONSTANTS.LOCAL_STORAGE_FIELD_TYPE.PLUGIN_NOTIFICATION
    ),
};

const clear = {
  localStorage: () => {
    localStorage.clear();
  },
};

export const actionHelpers = {
  setLocalStorage,
  getLocalStorage,
  clear,
};
