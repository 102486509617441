const lessonFormStyles = {
  textArea: {
    width: "100%",
    height: "200px",

    marginTop: "22px",
    paddingBottom: "1rem",

    border: "1px solid #666666",
    border: "none",

    "&:focus": {
      outline: "none",
    },
  },

  container__button: {
    textAlign: "right",
    marginTop: "3rem",
  },
  weekPicker: {
    display: "flex",
    alignItems: "flex-end",
  },

  datePicker: {
    zIndex: 2,
  },

  calendarIcon: {
    position: "absolute",
    right: "0",
    zIndex: 1,
    "@media(min-width: 960px ) and (max-width:1500px)": {
      left: "105%",
    },
  },

  datePickerContainer: {
    width: "100%",
    display: "flex",
  },

  weekSelector: {
    "&:focus": {
      backgroundColor: "white",
    },
    "& ul": {
      height: "100px",
    },
  },
  daySelector: {
    "&:focus": {
      backgroundColor: "white",
    },
    "& ul": {
      height: "100px",
    },
  },
  formStyle: {
    width: "125%",
  },
  boxStyle: {
    display: "flex ",
    alignContent: "center",
    justifyContent: "center",
    alignItems: "center",
  },
};

export default lessonFormStyles;
