import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { authActions } from "../../auth.actions";
import { useHistory, Redirect } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Alert from "@material-ui/lab/Alert";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import en from "../../../../../assets/languages/en.json";
import ForgotPasswordStyles from "./ForgotPassword.styles";
import Link from "@material-ui/core/Link";
import { GLOBAL_CONSTANTS } from "../../../../shared/constants/global.constants";

const useStyles = makeStyles(ForgotPasswordStyles);

const ForgotPassword = () => {
  const styles = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  const [inputs, setInputs] = useState({
    password: "",
    confirmPassword: "",
    token: history.location.pathname.split("/")[2],
  });
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const [passwordError, setPasswordError] = useState({
    passwordStrength: false,
    passwordMatch: false,
  });

  const changePasswordSuccess = useSelector(
    (state) => state.authReducer.changePasswordSuccess
  );

  const changePasswordError = useSelector(
    (state) => state.authReducer.changePasswordError
  );

  useEffect(() => {
    setTimeout(() => {}, 2000);
  }, [changePasswordSuccess]);

  const validatePassword = (password, confirmPassword) => {
    const arePasswordsMatching = password === confirmPassword;
    const hasPasswordSpecialChars = new RegExp(
      GLOBAL_CONSTANTS.REGEX.PASSWORD_VALIDATION
    ).test(password);
    const isPasswordLong =
      password.length > GLOBAL_CONSTANTS.PASSWORD_MINIMUM_LENGTH;
    const isPasswordStrong = hasPasswordSpecialChars && isPasswordLong;

    return {
      arePasswordsMatching,
      isPasswordStrong,
    };
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    setPasswordError({
      passwordStrength: false,
      passwordMatch: false,
    });

    const validation = validatePassword(
      inputs.password,
      inputs.confirmPassword
    );
    if (!validation.arePasswordsMatching) {
      setPasswordError((prevPasswordError) => {
        return {
          ...prevPasswordError,
          passwordMatch: true,
        };
      });
    }
    if (!validation.isPasswordStrong) {
      setPasswordError((prevPasswordError) => {
        return {
          ...prevPasswordError,
          passwordStrength: true,
        };
      });
    }
    if (validation.arePasswordsMatching && validation.isPasswordStrong) {
      dispatch(authActions.changePassword(inputs));
    }
  };

  const handleInputChange = (e) => {
    setInputs((prevInputs) => {
      return {
        ...prevInputs,
        [e.target.name]: e.target.value,
      };
    });
  };

  const goToLogin = () => {
    setTimeout(() => {
      history.push("/");
    }, 2000);
  };

  return (
    <Grid container justify="center" className={styles.root}>
      <Grid item>
        <Typography variant="h3" color="primary" className={styles.label}>
          Reset Password
        </Typography>
        <Grid item cs={12}>
          {isLoading ? (
            <div className={styles.spinner}>
              <CircularProgress />
            </div>
          ) : (
            <form onSubmit={handleSubmit} className={styles.form}>
              <TextField
                label={en.label.password}
                fullWidth
                required
                type="password"
                value={inputs.password}
                onChange={handleInputChange}
                name="password"
                error={passwordError.passwordStrength ? true : false}
                helperText={
                  passwordError.passwordStrength
                    ? en.authentication.passwordStrength
                    : null
                }
              />
              <TextField
                label={en.label.confirmPassword}
                fullWidth
                required
                type="password"
                value={inputs.confirmPassword}
                onChange={handleInputChange}
                name="confirmPassword"
                error={passwordError.passwordMatch ? true : false}
                helperText={
                  passwordError.passwordMatch
                    ? en.authentication.passwordMatch
                    : null
                }
              />
              <Button
                type="submit"
                disableRipple
                variant="contained"
                className={styles.button}
              >
                <Typography color="secondary">Reset</Typography>
              </Button>
            </form>
          )}
        </Grid>
      </Grid>
      <Grid item>
        {changePasswordSuccess && (
          <Alert severity="success">Password changed successfully!</Alert>
        )}
        {changePasswordError && (
          <Alert severity="error">
            This session has expired, please reset again.
          </Alert>
        )}
        {changePasswordSuccess && goToLogin()}
      </Grid>
    </Grid>
  );
};

export default ForgotPassword;
