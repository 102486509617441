import React, { useState, useRef, useMemo, useEffect } from "react";
import styles from "./Step.module.scss";
import InfoRoundedIcon from "@material-ui/icons/InfoRounded";
import { Popover, makeStyles, Typography } from "@material-ui/core";
import stepStyles from "./Step.styles";
import { GLOBAL_CONSTANTS } from "../../../../shared/constants/global.constants";
import en from "../../../../../assets/languages/en.json";
import SettingsOverscanRoundedIcon from "@material-ui/icons/SettingsOverscanRounded";
import Modal from "../../../../shared/components/Modal/Modal";
import StepEdit from "../StepEdit/StepEdit";
import { dashboardActions } from "../../dashboard.actions";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import Infographic from "../../components/Infographic/Infrographic";
import { useHistory } from "react-router-dom";
import editIcon from "../../../../../assets/svg/edit.svg";
import LockIcon from "@material-ui/icons/Lock";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import Tooltip from "@material-ui/core/Tooltip";
import { useTheme } from "@material-ui/core";

const templates = require.context(
  "../../../../../assets/infographics",
  true,
  /\.(png|png)$/
);
const useStyles = makeStyles(stepStyles);

const Step = ({ stepCount, title, info, defaultTitle, active, closeModal }) => {
  stepCount = stepCount || null;
  const index = stepCount ? Number(stepCount[stepCount.length - 1]) : "Step 1";
  const theme = useTheme();
  const classes = useStyles();
  const bodyRef = useRef(null);
  const { location } = useHistory();
  const [anchorEl, setAnchorEl] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isStepEditOpen, setIsStepEditOpen] = useState(
    location.state?.step == index
  );
  const dispatch = useDispatch();
  const [isTouched, setIsTouched] = useState(false);
  const results = useSelector((state) => state.dashboardReducer.content);
  const stepState =
    useSelector((state) => state.dashboardReducer.stepStatus) ?? [];
  const learningType = useSelector(
    (state) =>
      state.dashboardReducer.stepLearningType[
        stepCount[stepCount.length - 1] - 1
      ]
  );

  useEffect(() => {
    const newLearningType = {
      [stepCount[stepCount.length - 1] - 1]:
        en.lesson.learningTypes[Math.floor(Math.random() * 6)],
    };

    bodyRef.current.style.background =
      stepStyles.colors[newLearningType[stepCount[stepCount.length - 1] - 1]];
  }, []);

  useEffect(() => {
    if (learningType) {
      bodyRef.current.style.background = stepStyles.colors[learningType];
    } else {
      const newLearningType = {
        [stepCount[stepCount.length - 1] - 1]:
          en.lesson.learningTypes[Math.floor(Math.random() * 6)],
      };

      bodyRef.current.style.background = Boolean(results[index - 2]?.isEdited)
        ? stepStyles.colors[
            newLearningType[stepCount[stepCount.length - 1] - 1]
          ]
        : theme.palette.primary.unEditedStep;
    }
  }, [learningType, results[index - 2]?.isEdited]);

  const modal = useMemo(
    () =>
      isModalOpen && (
        <Modal
          setIsModalOpen={setIsModalOpen}
          isModalOpen={isModalOpen}
          stepCount={parseInt(stepCount[stepCount.length - 1])}
        />
      ),
    [isModalOpen]
  );

  const stepEdit = useMemo(() => {
    return (
      (isStepEditOpen || active.activeStep == index) && (
        <StepEdit
          index={index}
          setIsStepEditOpen={() => {
            closeModal();
            setIsStepEditOpen(false);
          }}
          isStepEditOpen={isStepEditOpen || active.activeStep == index}
          title={title}
          defaultTitle={defaultTitle}
          stepCount={parseInt(stepCount[stepCount.length - 1])}
          active={active}
        />
      )
    );
  }, [isStepEditOpen || active.activeStep]);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const handleDurationChange = (event) => {
    if (parseInt(event.target.value) === 0) {
      event.stopPropagation();
      return;
    }
    const stepKey = stepCount[stepCount.length - 1] - 1;
    let newStepDuration = {
      [stepKey]: 0,
    };
    if (event.target.value) {
      newStepDuration = {
        [stepKey]: isNaN(parseInt(event.target.value))
          ? 0
          : parseInt(event.target.value),
      };
    }
    dispatch(dashboardActions.changeLessonDuration(newStepDuration));
  };
  const stepDuration = useSelector(
    (state) =>
      state.dashboardReducer.duration[stepCount[stepCount.length - 1] - 1]
  );

  const handleModalClick = () => {
    if (results.length !== 0) {
      setIsModalOpen(true);
    }
  };

  const handleStepEditClick = () => {
    if (results.length !== 0) {
      setIsStepEditOpen(true);
    }
  };

  const handleLearningTypeChange = (option) => {
    setIsTouched(true);
    const stepKey = stepCount[stepCount.length - 1] - 1;
    let newLearningType = {
      [stepKey]: option.value,
    };
    dispatch(dashboardActions.changeLearningTypes(newLearningType));
  };

  const options = en.lesson.learningTypes.map((learningType) => {
    return { value: learningType, label: learningType };
  });

  const customStyles = stepStyles.customStyles;

  const isOpen = Boolean(anchorEl);

  const handleStepStateClick = () => {
    const indexOfDisabledStep = stepState.indexOf(Number(index));

    const stepStateStatus =
      indexOfDisabledStep != -1
        ? stepState.filter(
            (stepNumber) => stepNumber != stepState[indexOfDisabledStep]
          )
        : [...stepState, Number(index)];

    dispatch(dashboardActions.changeStepStateStatus(stepStateStatus));
  };

  return (
    <div style={{ padding: "10px", position: "relative" }}>
      {templates.keys().map((element, _index) => {
        if (_index == index - 1) {
          return (
            <Infographic key={element} source={templates(element).default} />
          );
        }
        return null;
      })}

      <div className={styles.card} ref={bodyRef}>
        <div className={styles.card__header}>
          {Number(index) != 1 && (
            <div onClick={() => handleStepStateClick()}>
              {!stepState.includes(Number(index)) ? (
                <LockOpenIcon fontSize="small" />
              ) : (
                <LockIcon fontSize="small" />
              )}
            </div>
          )}

          <div>
            <input
              className={styles.duration}
              type="number"
              placeholder="00:00"
              name="duration"
              onChange={handleDurationChange}
              min="0"
              step="1"
              value={stepDuration ? stepDuration : null}
            ></input>
          </div>

          <span>{en.mins}</span>
          <Tooltip title="Edit">
            <img
              className={styles.editIcon}
              src={editIcon}
              onClick={handleStepEditClick}
            />
          </Tooltip>
        </div>

        <div className={styles.card__content}>
          <Typography color="secondary">
            {stepCount}: {defaultTitle}
          </Typography>

          <p className="title">
            <Typography color="textSecondary">{title}</Typography>
          </p>
        </div>
        <div className={styles.card__footer}>
          <div
            className={
              results.length > 0
                ? styles["pointer-cursor"]
                : styles["forbidden-cursor"]
            }
          >
            {stepCount[stepCount.length - 1] !== "1" ? (
              <Tooltip title="Preview">
                <SettingsOverscanRoundedIcon onClick={handleModalClick} />
              </Tooltip>
            ) : null}
          </div>
          <div>
            <Select
              className={styles["select-container"]}
              options={options}
              value={learningType}
              onChange={handleLearningTypeChange}
              placeholder={
                isTouched || learningType ? learningType : en.label.learningType
              }
              defaultValue={learningType ?? options[0].value}
              isSearchable={false}
              styles={customStyles}
            />
          </div>
          <div
            aria-owns={
              isOpen
                ? GLOBAL_CONSTANTS.CLICK_EVENTS_MOUSE_OVER_POPOVER
                : undefined
            }
            aria-haspopup="true"
            onMouseEnter={handlePopoverOpen}
            onMouseLeave={handlePopoverClose}
          >
            <InfoRoundedIcon />
          </div>
        </div>
      </div>

      <Popover
        id="mouse-over-popover"
        open={isOpen}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        className={classes.popover}
        classes={{
          paper: classes.paper,
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        {info}
      </Popover>

      {stepCount[stepCount.length - 1] !== "1" && modal}
      {stepEdit}
    </div>
  );
};

export default Step;
