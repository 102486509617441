const pluginGuideStyles = {
  cardContainer: {
    backgroundColor: "#000",
    opacity: 0.8,
  },
  cardDescription: {
    color: "#fff",
  },
  cardContent: {
    margin: "24px auto",
  },
  cardLink: {
    cursor: "pointer",
    borderBottom: "2px solid #FFDB86",
    display: "inline-block",
    paddingBottom: "1px",
  },
  cardAction: {
    textAlign: "right",
  },
  checkbox: {
    color: "#fff",
    "&:not($checked) .MuiIconButton-label:after": {
      backgroundColor: "#fff",
    },
  },
};

export default pluginGuideStyles;
