import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";
import Navbar from "../Navbar/Navbar";

const PrivateRoute = ({ component: RouteComponent, ...rest }) => {
  const isAuthenticated = useSelector(
    (state) => state.baseAuthReducer.isAuthenticated
  );
  const authenticatedUser = useSelector(
    (state) => state.baseAuthReducer.authenticatedUser
  );
  return (
    <Route
      {...rest}
      render={(routeProps) =>
        !!(authenticatedUser && isAuthenticated) ? (
          <>
            <Navbar />
            <RouteComponent {...routeProps} />
          </>
        ) : (
          <Redirect to={"/"} />
        )
      }
    />
  );
};

export default PrivateRoute;
