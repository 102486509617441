import { combineReducers } from "redux";
import { authReducer } from "../../modules/login/auth.reducer";
import { baseAuthReducer } from "./baseAuth.reducer";
import dashboardReducer from "../../modules/Dashboard/dashboard.reducer";
import AUTH_CONSTANTS from "../constants/baseAuth.constants";

const appReducer = combineReducers({
  authReducer,
  baseAuthReducer,
  dashboardReducer,
});

export const baseReducer = (state, action) => {
  if (action.type === AUTH_CONSTANTS.CLEAR_STORE) {
    state = undefined;
  }
  return appReducer(state, action);
};

export default baseReducer;
