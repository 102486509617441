import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useTheme } from "@material-ui/core";
import imagePickerStyles from "./ImagePicker.Styles";
import styles from "./LazyLoadedImage.module.scss";
import check from "../../../../../assets/images/check.png";

const LazyLoadedImage = ({ image, inputs, setInputs }) => {
  const theme = useTheme();
  const classes = imagePickerStyles(theme);
  const handleImageColor = (event) => {
    event.target.classList.toggle("selected");
  };

  const handleImageClick = () => {
    if (image === inputs.image) {
      setInputs((prevInputs) => {
        return {
          ...prevInputs,
          image: "",
        };
      });
      return;
    }

    setInputs((prevInputs) => {
      return {
        ...prevInputs,
        image: image,
      };
    });
  };

  return (
    <div className={classes.flexcontainer} style={{ position: "relative" }}>
      <LazyLoadImage
        src={image}
        width={140}
        height={140}
        effect="blur"
        onClick={handleImageColor}
        className={classes.imgStyle}
        onClick={handleImageClick}
        className={
          inputs.image === image ? styles.selected : styles.notSelected
        }
      />
      {inputs.image === image ? (
        <img
          src={check}
          width="50px"
          style={{ position: "absolute", bottom: 0, right: 0 }}
        ></img>
      ) : (
        ""
      )}
    </div>
  );
};

export default LazyLoadedImage;
