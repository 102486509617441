import googleClassroom from "../../../assets/favicons/Google_Classroom_Logo.png";
import slido from "../../../assets/favicons/slido-logo.png";
import flipGrid from "../../../assets/favicons/flipgrid.png";
import canva from "../../../assets/favicons/canva-logo.png";
import commonlit from "../../../assets/favicons/commonlit.png";
import edPuzzle from "../../../assets/favicons/edPuzzle.png";
import kahoot from "../../../assets/favicons/Kahoot_Logo.png";
import quizlet from "../../../assets/favicons/Quizlet-logo.jpg";
import mentimeter from "../../../assets/favicons/Mentimeter_Branding_Logo.jpg";
import padlet from "../../../assets/favicons/padlet-logo.png";
import quizizz from "../../../assets/favicons/quizizz.png";

const AXIOS_METHODS = {
  GET: "get",
  POST: "post",
};

const REGEX = {
  PASSWORD_VALIDATION: "(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])",
  SOURCE_EXTRACTION: 'srcs*=s*"(.+?)"',
};

const LOCAL_STORAGE_FIELD_TYPE = {
  ACCESS_TOKEN: "accessToken",
  REFRESH_TOKEN: "refreshToken",
  USER: "user",
  PLUGIN_NOTIFICATION: "pluginConstants",
};

const AUTHORIZATION_HEADER = {
  AUTHORIZATION: "Authorization",
};

const PASSWORD_MINIMUM_LENGTH = 7;

const IMAGE_ALT = {
  PROFILE: "Profile",
};

const WIDTH = {
  TIP: 320,
  CHEVRON: 40,
};

const DELAY = {
  SNACKBAR: 6000,
};

const SEVERITY = {
  ERROR: "error",
  SUCCESS: "success",
  WARNING: "warning",
  INFO: "info",
};

const CLICK_EVENTS = {
  CLICKAWAY: "clickaway",
  MOUSE_OVER_POPOVER: "mouse-over-popover",
};

const LEARNING_TYPE = {
  ACQUIRING: "ACQUIRING",
  INQUIRING: "INQUIRING",
  PRODUCING: "PRODUCING",
  DISCUSSING: "DISCUSSING",
  COLLABORATING: "COLLABORATING",
  PRACTISING: "PRACTISING",
};

const INPUT_TYPE = {
  IMAGE: "image/*",
  VIDEO: "video/*",
  PDF: "application/pdf",
};

const DEAFULT_SUBSTEP_ORDER = {
  0: 0,
  1: 1,
  2: 2,
  3: 3,
  4: 4,
};

const STEP_TYPE = {
  IMAGE_SEARCH: 3,
  DEFAULT_LAST_STEP: 8,
  DEFAULT_FIRST_STEP: 1,
  DEAFULT_NEXT_TO_LAST_STEP: 7,
  DEFAULT_NEXT_TO_FIRST_STEP: 2,
};

const EMBEDDED_LINK = {
  LENGTH: 50,
};

const OUTTER_LINKS = {
  X_FRAME_IGNORE:
    "https://chrome.google.com/webstore/detail/ignore-x-frame-headers/gleekbfjekiniecknbkamfmkohkpodhe",
};

const DEFAULT_COLOR = "#0daed1";
const LESSONS_PAGINATION_LIMIT = 6;
const NUMBER_OF_ACADEMIC_WEEKS = 37;
const GOOGLE_SLIDES_LINK = "https://docs.google.com/presentation/u/0/?tgif=d";

const LEARNING_TIPS = [
  {
    link: "https://www.sli.do/",
    imagePath: slido,
    text: "Run a poll/ quiz on Slido",
  },
  {
    link: "https://info.flipgrid.com/",
    imagePath: flipGrid,
    text: " Create/Assign a topic for students to record creative evidence of their learning on Flipgrid individually, in pairs or groups",
  },
  {
    link: "https://edu.google.com/products/classroom/",
    imagePath: googleClassroom,
    text: "Group/Pair students to collaborate on a worksheet on Google Classroom",
  },
  {
    link: "https://www.mentimeter.com/",
    imagePath: mentimeter,
    text: "Run a survey on Menti",
  },
  {
    link: "https://edpuzzle.com/",
    imagePath: edPuzzle,
    text: "Make videos interactive with EdPuzzle for instant results",
  },
  {
    link: "https://padlet.com/",
    imagePath: padlet,
    text: " Invite students to discuss with Padlet",
  },
  {
    link: "https://www.canva.com/create/logos/",
    imagePath: canva,
    text: " Encourage students to produce their creative work evidence on Canva",
  },
  {
    link: "https://quizizz.com/",
    imagePath: quizizz,
    text: "Run/Assign a quiz on Quizzizz",
  },
  {
    link: "https://quizlet.com/",
    imagePath: quizlet,
    text: "Run a game on Quizlet",
  },
  {
    link: "https://kahoot.com/",
    imagePath: kahoot,
    text: " Run/ Assign a quiz/game on Kahoot",
  },
  {
    link: "https://www.commonlit.org/",
    imagePath: commonlit,
    text: "  Promote reading/writing for students to acquire knowledge via CommonLit",
  },
];
const ACTIVITY_IDEAS = [
  {
    text: "Peer-Assess learning evidence – Use Rubrics",
  },
  {
    text: "Self- Assess learning evidence- Use Rubrics",
  },
  {
    text: "Chat- real time reactions in 5.4.3.2.1 Press Send",
  },
  {
    text: "Collaborative Concept mapping",
  },
  {
    text: "Project Based Learning",
  },
  {
    text: "Peer- Feedback –Use Rubrics",
  },
  {
    text: "Drill and practice",
  },
  {
    text: "Speed Chatting",
  },
  {
    text: "Questionnaire",
  },
  {
    text: "HOT question",
  },
  {
    text: "Virtual Field Trips",
  },
  {
    text: "Roleplay",
  },
  {
    text: "Forum",
  },
  {
    text: "Debate",
  },
  {
    text: "Jigsaw",
  },
  {
    text: "Individual",
  },
  {
    text: "Pair Work",
  },
  {
    text: "Group Work",
  },
  {
    text: "Tour Creator",
  },
  {
    text: "Case Study",
  },
  {
    text: "Brainwriting",
  },
  {
    text: "New learnt concept reflections",
  },
];

export const GLOBAL_CONSTANTS = {
  AXIOS_METHODS,
  REGEX,
  LOCAL_STORAGE_FIELD_TYPE,
  AUTHORIZATION_HEADER,
  PASSWORD_MINIMUM_LENGTH,
  IMAGE_ALT,
  WIDTH,
  DELAY,
  SEVERITY,
  CLICK_EVENTS,
  LEARNING_TYPE,
  INPUT_TYPE,
  DEAFULT_SUBSTEP_ORDER,
  STEP_TYPE,
  EMBEDDED_LINK,
  OUTTER_LINKS,
  LESSONS_PAGINATION_LIMIT,
  NUMBER_OF_ACADEMIC_WEEKS,
  DEFAULT_COLOR,
  GOOGLE_SLIDES_LINK,
  LEARNING_TIPS,
  ACTIVITY_IDEAS,
};
