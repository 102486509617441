import React from "react";
import { Grid, Box, Paper, Typography } from "@material-ui/core";
import en from "../../../../../assets/languages/en.json";
import styles from "./Quote.module.scss";

const Quote = () => {
  return (
    <Grid container justify="center" display="flex">
      <Grid item xs={10} md={8}>
        <Paper elevation={2}>
          <Box
            paddingTop={5}
            paddingRight={5}
            paddingLeft={5}
            align="center"
            className={styles.responsive}
          >
            <Typography
              color="secondary"
              variant="h2"
              component="span"
              className={styles.responsive}
            >
              {en.quote}
            </Typography>
            <Typography
              color="secondary"
              variant="h2"
              component="span"
              className={styles["quote"]}
            >
              {en.quoteBold}
            </Typography>
          </Box>
          <Box align="center">
            <Box padding={2}>
              <Typography color="primary" variant="h6" align="center">
                {en.secondary}
              </Typography>
            </Box>
          </Box>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default Quote;
