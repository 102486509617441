import { authService } from "./auth.service";
import { AUTH_CONSTANTS } from "./auth.constants";
import { actionHelpers } from "../../shared/helpers/actionHelpers";
import { userActions } from "../../shared/actions/user.actions";

const signUp = (inputs) => async (dispatch) => {
  dispatch(startSignUp());
  const data = await authService.signUp(inputs);
  dispatch(finishSignUp(data));
};

const logIn = (inputs) => async (dispatch) => {
  dispatch(startSignIn());
  const data = await authService.logIn(inputs);
  dispatch(finishSignIn(data));
  if (data.responseStatus === AUTH_CONSTANTS.SIGN_IN_SUCCESS) {
    actionHelpers.setLocalStorage.accessToken(data.body.data.data.accessToken);
    actionHelpers.setLocalStorage.refreshToken(
      data.body.data.data.refreshToken
    );
    actionHelpers.setLocalStorage.pluginNotification(true);
    actionHelpers.setLocalStorage.user(
      JSON.stringify(data.body.data.data.user)
    );
    dispatch(userActions.setAuthenticatedUser);
  }
};

const startSignUp = () => {
  return {
    type: AUTH_CONSTANTS.SIGN_UP_REQUEST,
  };
};

const forgotPassword = (email) => async (dispatch) => {
  dispatch({ type: AUTH_CONSTANTS.FORGOT_PASSWORD_REQUEST });
  const data = await authService.forgotPassword(email);
  dispatch({ type: data.responseStatus });
};

const changePassword = (password, token) => async (dispatch) => {
  dispatch({ type: AUTH_CONSTANTS.CHANGE_PASSWORD_REQUEST });
  const data = await authService.changePassword(password, token);
  if (data.body?.status == 200) {
    dispatch({ type: AUTH_CONSTANTS.CHANGE_PASSWORD_SUCCESS });
  } else {
    dispatch({ type: AUTH_CONSTANTS.CHANGE_PASSWORD_FAIL });
  }
};

const finishSignUp = (data) => {
  return {
    type: data.responseStatus,
    payload: data.body,
  };
};

const startSignIn = () => {
  return {
    type: AUTH_CONSTANTS.SIGN_IN_REQUEST,
  };
};

const finishSignIn = (data) => {
  return {
    type: data.responseStatus,
  };
};

const closeConfirmationModal = {
  type: AUTH_CONSTANTS.CONFIRMATION_MODAL,
};

const setFullScreen = {
  type: AUTH_CONSTANTS.FULL_SCREEN,
};

export const authActions = {
  signUp,
  closeConfirmationModal,
  logIn,
  setFullScreen,
  forgotPassword,
  changePassword,
};
