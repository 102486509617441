import React, { useEffect, useRef } from "react";
import en from "../../../../../assets/languages/en.json";
import { useSelector } from "react-redux";
import styles from "./MediaWindow.module.scss";
import { useParams } from "react-router-dom";

const MediaWindow = ({ stepCount, type }) => {
  const { id } = useParams();
  const containerRef = useRef(null);
  const imageLink = useSelector(
    (state) => state.dashboardReducer.stepImage[stepCount - 1]
  );

  const videoLink = useSelector(
    (state) => state.dashboardReducer.stepVideo[stepCount - 1]
  );

  const embeddedLink = useSelector(
    (state) => state.dashboardReducer.stepEmbeddedUrl[stepCount - 1]
  );
  const pdfLink = useSelector(
    (state) => state.dashboardReducer.stepPDF[stepCount - 1]
  );

  const generateMediaType = () => {
    if (containerRef?.current && type !== en.lesson.tabs[3]) {
      containerRef.current.innerHTML = "";
    }
    if (type === en.lesson.tabs[1] && imageLink) {
      return (
        <img
          src={imageLink.split("name")[0]}
          className={!id ? styles.container__media : styles.fullWindow__media}
        />
      );
    }
    if (type === en.lesson.tabs[2] && videoLink) {
      setTimeout(() => {
        window.requestAnimationFrame(function () {
          const node = containerRef.current;

          if (node !== undefined && node !== null) {
            containerRef.current.innerHTML = videoLink;
          }
        });
      }, 0);
    }

    if (type === en.lesson.tabs[4] && pdfLink) {
      return (
        <embed
          src={pdfLink.split("name")[0]}
          className={!id ? styles.container__pdf : styles.fullWindow__pdf}
        />
      );
    }
  };

  useEffect(() => {
    if (type === en.lesson.tabs[3] && embeddedLink) {
      containerRef.current.innerHTML = embeddedLink;
      return;
    }
    containerRef.current.innerHTML = "";
  }, [type]);

  return (
    <div className={styles.container}>
      {generateMediaType()}
      <div
        className={!id ? styles.frame : styles.frameFullWindow}
        ref={containerRef}
      ></div>
    </div>
  );
};

export default MediaWindow;
