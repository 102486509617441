const getDateAndTime = (dateTime) => {
  const today = new Date(dateTime);
  const date = today.toUTCString().substring(0, 16);

  let hours = today.getHours();
  let minutes = today.getMinutes();

  hours = hours % 12;
  hours = hours ? hours : 12;
  minutes = minutes < 10 ? "0" + minutes : minutes;

  const ampm = hours >= 12 ? "pm" : "am";
  const time = hours + ":" + minutes + " " + ampm;

  return { date, time };
};

const convertMinutesToHours = (totalMinutes) => {
  const hours = totalMinutes / 60;
  const roundedHours = Math.floor(hours);
  const minutes = Math.round((hours - roundedHours) * 60);

  return roundedHours + " hour(s) and " + minutes + " minute(s).";
};

export const datetime = { getDateAndTime, convertMinutesToHours };
