import React, { useRef, useState, useEffect } from "react";
import {
  Dialog,
  Grid,
  Checkbox,
  makeStyles,
  Divider,
  Box,
  Typography,
  TextField,
  CircularProgress,
  Tabs,
  Tab,
  Button,
  MenuItem,
  Select,
} from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import stepEditStyles from "./StepEdit.styles";
import en from "../../../../../assets/languages/en.json";
import { dashboardActions } from "../../dashboard.actions";
import SearchRoundedIcon from "@material-ui/icons/SearchRounded";
import { dashboardService } from "../../dashboard.service";
import { googleSearchBuilder } from "../../../../shared/helpers/googleSearchBuilder";
import DropZone from "../../components/DropZone/DropZone";
import { GLOBAL_CONSTANTS } from "../../../../shared/constants/global.constants";
import HighlightOffRoundedIcon from "@material-ui/icons/HighlightOffRounded";
import YouTubeIcon from "@material-ui/icons/YouTube";
import CoverStepEdit from "../CoverStepEdit/CoverStepEdit";
import blobToBase64 from "blob-to-base64";
import Notes from "./Notes";
import { useHistory } from "react-router-dom";
import stepType from "../../../../shared/constants/step.constants";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import LearningTip from "../../../../shared/components/Modal/LearningTip";

const useStyles = makeStyles(stepEditStyles);

const StepEdit = ({
  setIsStepEditOpen,
  isStepEditOpen,
  stepCount,
  title,
  defaultTitle,
  active,
}) => {
  const styles = useStyles();
  const { location } = useHistory();
  const topicRef = useRef(null);
  const linkRef = useRef(null);
  const containerRef = useRef(null);
  const videoRef = useRef(null);
  const submitRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);

  const [uploadedImage, setUploadedImage] = useState("");
  const [uploadedVideo, setUploadedVideo] = useState("");
  const [uploadedPDF, setUploadedPDF] = useState("");
  const [value, setValue] = useState(
    active.activeSubStep ? active.activeSubStep : location.state?.subStep || 0
  );
  const [isLoaderOpen, setIsLoaderOpen] = useState(false);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);

  const urls = useSelector((state) => state.dashboardReducer);
  const stepDescriptions = useSelector(
    (state) => state.dashboardReducer.stepDescriptions
  );
  const stepEmbeddedUrl = useSelector((state) => {
    if (state.dashboardReducer.stepEmbeddedUrl[stepCount - 1]) {
      return state.dashboardReducer.stepEmbeddedUrl[stepCount - 1];
    }
  });
  const stepVideoEmbeddedUrl = useSelector((state) => {
    if (state.dashboardReducer.stepVideo[stepCount - 1]) {
      return state.dashboardReducer.stepVideo[stepCount - 1];
    }
  });
  const lesson = useSelector((state) => state.dashboardReducer);
  const id = useSelector((state) => state.dashboardReducer.id);
  const stepImage = useSelector((state) => state.dashboardReducer.stepImage);
  const stepVideo = useSelector((state) => state.dashboardReducer.stepVideo);
  const stepPDF = useSelector((state) => state.dashboardReducer.stepPDF);
  const [deleteRefButton, setDeleteRefButton] = useState(false);
  const [deleteLinkRefButton, setDeleteLinkRefButton] = useState(false);

  const substepOrder = useSelector((state) => {
    if (!state.dashboardReducer.substepOrder[stepCount - 1]) {
      return GLOBAL_CONSTANTS.DEAFULT_SUBSTEP_ORDER;
    }
    return state.dashboardReducer.substepOrder[stepCount - 1];
  });

  useEffect(() => {
    if (stepImage.hasOwnProperty([stepCount - 1]) && stepImage[stepCount - 1]) {
      const imageData = stepImage[stepCount - 1].split(`name${stepCount - 1}:`);
      setUploadedImage({
        source: imageData[0],
        name: imageData[1],
      });
    }
  }, [stepImage]);

  useEffect(() => {
    if (stepVideo.hasOwnProperty([stepCount - 1]) && stepVideo[stepCount - 1]) {
      const videoData = stepVideo[stepCount - 1].split(`name${stepCount - 1}:`);
      setUploadedVideo({
        source: videoData[0],
        name: videoData[1],
      });
    }
  }, [stepVideo]);

  useEffect(() => {
    if (stepPDF.hasOwnProperty([stepCount - 1]) && stepPDF[stepCount - 1]) {
      const pdfData = stepPDF[stepCount - 1].split(`name${stepCount - 1}:`);
      setUploadedPDF({
        source: pdfData[0],
        name: pdfData[1],
      });
    }
  }, [stepPDF]);

  useEffect(() => {
    if (stepVideo[stepCount - 1]?.length > 1) {
      setDeleteRefButton(true);
    }
  }, [stepCount]);

  const handleImageUpload = (file) => {
    if (file?.type?.includes("image")) {
      blobToBase64(file, function (error, base64) {
        if (!error) {
          const newStepImage = {
            [stepCount - 1]: `${base64}name${stepCount - 1}:${file.name}`,
          };
          dispatch(dashboardActions.uploadStepImage(newStepImage));
        }
      });
    }

    if (file?.type?.includes("pdf")) {
      blobToBase64(file, function (error, base64) {
        if (!error) {
          const newStepPDF = {
            [stepCount - 1]: `${base64}name${stepCount - 1}:${file.name}`,
          };
          dispatch(dashboardActions.uploadStepPDF(newStepPDF));
        }
      });
    }
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleTabStep = (type) => {
    const lastTab = en.lesson.tabs.length - 1;
    const firstTab = en.lesson.tabs.length - lastTab - 1;
    const newValue =
      type == "increase"
        ? value == lastTab
          ? firstTab
          : value + 1
        : value == firstTab
        ? lastTab
        : value - 1;

    setValue(newValue);
  };

  const dispatch = useDispatch();

  const handleInputChange = (text) => {
    dispatch(
      dashboardActions.changeStepDescription(
        text,
        stepCount,
        en.lesson.tabs[value]
      )
    );
  };

  const isDraft = useSelector((state) => state.dashboardReducer.isDraft);

  const handleStepEditClose = async () => {
    setIsLoaderOpen(true);
    if (submitRef.current) {
      submitRef.current.innerText = "Submitting...";
    }

    let query = id
      ? dashboardService.patchLessonById
      : dashboardService.createLesson;

    let newLesson = { ...lesson, isDraft };

    newLesson.content[stepCount - 2] = {
      ...newLesson.content[stepCount - 2],
      isEdited: 1,
    };

    const params = {
      ...(id && { id: id }),
      lesson: { ...newLesson, content: [...newLesson.content] },
    };

    const response = await query(params);

    if (response.success) {
      setSuccess(true);
      setIsStepEditOpen(false);
    } else {
      setError(true);
    }
  };

  const handleCheckedChange = (index) => {
    if (urls.selectedContent[stepCount - 2][index].checked) return;

    for (const item of urls.selectedContent[stepCount - 2]) {
      item.checked = false;
    }

    urls.selectedContent[stepCount - 2][index].checked = true;
    dispatch(dashboardActions.setSelectedContent(urls.selectedContent));
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    if (event.target.value) {
      return false;
    }

    setIsLoading(true);
    const query = googleSearchBuilder.singleSearch(
      topicRef?.current?.value,
      stepCount
    );

    const response = await dashboardService.fetchResult(query);
    if (response.success) {
      urls.content[stepCount - 2] = response.success;
      urls.selectedContent[stepCount - 2] = response.success.data.items;
      urls.selectedContent[stepCount - 2][0].checked = true;

      dispatch(dashboardActions.setCustomContent(urls));
    }
    setIsLoading(false);
  };

  const handleLinkSubmit = (event) => {
    event.preventDefault();

    let rawLink = linkRef.current.value;
    rawLink = rawLink.replace("width=", "");
    rawLink = rawLink.replace("height=", "");
    const position = rawLink.lastIndexOf("></iframe>");

    const newLink = `${rawLink.substring(
      0,
      position
    )} height="450" width="100%"  ${rawLink.substring(position)}`;

    let html = "";

    if (newLink.length > GLOBAL_CONSTANTS.EMBEDDED_LINK.LENGTH) {
      html = newLink;
    }

    containerRef.current.innerHTML = html;
    setDeleteLinkRefButton(true);
    dispatch(
      dashboardActions.setStepEmbeddedUrl({
        [stepCount - 1]: html,
      })
    );
  };
  const handleVideoLinkSubmit = (event) => {
    event.preventDefault();

    let rawLink = linkRef.current.value;
    let html = "";
    if (rawLink.includes("iframe")) {
      rawLink = rawLink.replace("width=", "");
      rawLink = rawLink.replace("height=", "");
      const position = rawLink.lastIndexOf("></iframe>");

      const newLink = `${rawLink.substring(
        0,
        position
      )} height="450" width="100%"  ${rawLink.substring(position)}`;

      if (newLink.length > GLOBAL_CONSTANTS.EMBEDDED_LINK.LENGTH) {
        html = newLink;
      }
    } else {
      html = `<iframe width="100%" height="450px" src=${rawLink}  allowfullscreen></iframe>`;
    }
    videoRef.current.innerHTML = html;
    setDeleteRefButton(true);
    dispatch(
      dashboardActions.uploadStepVideo({
        [stepCount - 1]: html,
      })
    );
  };

  // useEffect(() => {
  //   if (deleteRefButton) {
  //     console.log(videoRef?.current?.innerHTML, "running");

  //   }
  // }, [deleteRefButton]);

  const handleOrderChange = (event, newKey) => {
    const newOrder = {
      ...substepOrder,
      [newKey]: event.target.value,
    };

    for (const [key, value] of Object.entries(newOrder)) {
      if (value === event.target.value && key != newKey) {
        newOrder[key] = substepOrder[newKey];
      }
    }

    const order = {
      [stepCount - 1]: newOrder,
    };

    dispatch(dashboardActions.changeSubstepOrder(order));
  };

  useEffect(() => {
    if (value === GLOBAL_CONSTANTS.DEAFULT_SUBSTEP_ORDER[3]) {
      setTimeout(() => {
        if (stepEmbeddedUrl !== "") {
          containerRef.current.innerHTML = stepEmbeddedUrl ?? "";
          return;
        }

        containerRef.current.innerHTML = null;
      }, 0);
    }
  }, [value]);

  useEffect(() => {
    if (value === GLOBAL_CONSTANTS.DEAFULT_SUBSTEP_ORDER[2]) {
      setTimeout(() => {
        if (stepVideoEmbeddedUrl !== "") {
          videoRef.current.innerHTML = stepVideoEmbeddedUrl ?? "";
          return;
        }

        videoRef.current.innerHTML = " ";
      }, 0);
    }
  }, [value]);

  const handleQuitClick = () => {
    setIsStepEditOpen(false);
  };

  const handleDeleteRefButtonClick = () => {
    setDeleteRefButton(false);
    const html = " ";
    videoRef.current.innerHTML = html;
    dispatch(
      dashboardActions.uploadStepVideo({
        [stepCount - 1]: html,
      })
    );
  };

  const handleDeleteLinkRefButtonClick = () => {
    setDeleteLinkRefButton(false);
    const html = " ";
    containerRef.current.innerHTML = html;
    dispatch(
      dashboardActions.setStepEmbeddedUrl({
        [stepCount - 1]: html,
      })
    );
  };

  return (
    <Dialog
      disableBackdropClick
      onClose={handleStepEditClose}
      open={isStepEditOpen}
      maxWidth="md"
      fullWidth
    >
      {stepCount !== 1 ? (
        <>
          <div className={styles.tabContainer}>
            <NavigateBeforeIcon
              fontSize="large"
              onClick={() => handleTabStep("decrease")}
            />
            <Tabs
              value={value}
              onChange={handleChange}
              classes={{
                root: styles.tabs,
                scroller: styles.scroll,
                indicator: styles.indicator,
              }}
              disableHideScrollButtonsMobile
            >
              {en.lesson.tabs.map((tab, _index) => {
                return <Tab key={_index} label={tab} />;
              })}
            </Tabs>
            <NavigateNextIcon
              fontSize="large"
              onClick={() => handleTabStep("increase")}
            />
            <span className={styles.quitButton}>
              <HighlightOffRoundedIcon
                fontSize="large"
                onClick={handleQuitClick}
              />
            </span>
          </div>

          <div>
            <Box padding={1.5} className={styles.stepCountContainer}>
              <Typography variant="h6">
                {en.label.step + stepCount + `: ` + defaultTitle}
              </Typography>
            </Box>
          </div>
          {value === 0 ? (
            <div className={styles.container}>
              <div className={styles.containerDiv}>
                <div>
                  <form onSubmit={handleFormSubmit}>
                    <Grid container justify="center" />
                    <Grid container spacing={3}>
                      <Grid item xs={12} sm={6}>
                        <Typography align="left" variant="h5" color="secondary">
                          {en.lesson.customWording}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <div className={styles.order}>
                          <Box mr={1}>
                            <Typography color="secondary">
                              {en.label.stepOrder}
                            </Typography>
                          </Box>
                          <Select
                            value={substepOrder[value]}
                            onChange={(event) =>
                              handleOrderChange(event, value)
                            }
                            classes={{ selectMenu: styles.selectMenu }}
                          >
                            {en.order.map((order, _index) => (
                              <MenuItem key={_index} value={_index}>
                                {order}
                              </MenuItem>
                            ))}
                          </Select>
                        </div>
                      </Grid>
                    </Grid>

                    <Box mt={3}>
                      <Grid
                        item
                        xs={12}
                        classes={{ root: styles.customSearch }}
                      >
                        <TextField
                          type="text"
                          name="topic"
                          fullWidth
                          inputRef={topicRef}
                          disabled={!urls.selectedContent.length > 0}
                          placeholder={
                            urls.selectedContent.length > 0
                              ? ""
                              : en.lesson.stepEditForm
                          }
                        />
                        {isLoading ? (
                          <CircularProgress color="primary" size={35} />
                        ) : (
                          <SearchRoundedIcon
                            onClick={handleFormSubmit}
                            fontSize="large"
                            type="submit"
                          />
                        )}
                      </Grid>
                    </Box>

                    <Grid />
                    <input type="submit" className={styles.hiddenSubmit} />
                  </form>
                </div>
                <Grid container justify="center">
                  {stepCount === 3
                    ? urls?.content[stepCount - 2]?.data?.items?.map(
                        (url, _index) => (
                          <Grid key={_index} item xs={12}>
                            <Grid container spacing={2} justify="center">
                              <Grid item xs={12}>
                                <a
                                  href={url?.image?.contextLink}
                                  target="_blank"
                                  rel="noreferrer"
                                  className={styles.anchor}
                                >
                                  {url.title}
                                </a>
                              </Grid>
                              <Grid item xs={2}>
                                <Checkbox
                                  color="primary"
                                  checked={
                                    urls?.selectedContent[stepCount - 2][_index]
                                      .checked
                                  }
                                  onChange={() => handleCheckedChange(_index)}
                                />
                              </Grid>
                              <Grid item xs={6}>
                                <img
                                  className={styles.images}
                                  src={url.link ? url.link : null}
                                  alt={url.title}
                                />
                              </Grid>
                              <Grid item xs={4}>
                                {url.snippet}
                              </Grid>
                            </Grid>
                            <Box m={1}>
                              <Divider />
                            </Box>
                          </Grid>
                        )
                      )
                    : urls?.content[stepCount - 2]?.data?.items?.map(
                        (url, _index) => (
                          <Grid key={_index} item xs={12}>
                            <Grid container spacing={3} justify="center">
                              <Grid item xs={12}>
                                <a
                                  href={url.link}
                                  target="_blank"
                                  rel="noreferrer"
                                  className={styles.anchor}
                                >
                                  {url.title}
                                </a>
                              </Grid>
                              <Grid item xs={2}>
                                <Checkbox
                                  color="primary"
                                  checked={
                                    urls?.selectedContent[stepCount - 2][_index]
                                      .checked
                                  }
                                  onChange={() => handleCheckedChange(_index)}
                                />
                              </Grid>
                              <Grid item xs={4}>
                                <img
                                  className={styles.thumbnail}
                                  src={
                                    url.pagemap?.cse_thumbnail
                                      ? url.pagemap?.cse_thumbnail[0].src
                                      : null
                                  }
                                  alt={url.title}
                                />
                              </Grid>
                              <Grid item xs={6}>
                                {url.snippet}
                              </Grid>
                            </Grid>
                            <Box m={1}>
                              <Divider />
                            </Box>
                          </Grid>
                        )
                      )}
                </Grid>
              </div>
              <div>
                <Notes
                  handleInputChange={handleInputChange}
                  stepCount={stepCount}
                  tab={en.lesson.tabs[value] || 0}
                />
              </div>
              <Box mt={8}>
                <LearningTip />
              </Box>
            </div>
          ) : null}
          {value === 1 ? (
            <div className={styles.container}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={8}>
                  <DropZone
                    description={en.lesson.dropZoneStepEdit}
                    handleImageUpload={handleImageUpload}
                    file={uploadedImage}
                    accept={GLOBAL_CONSTANTS.INPUT_TYPE.IMAGE}
                    stepCount={stepCount}
                    stepTypeFile={stepType.IMAGE}
                  />
                </Grid>

                <Grid item xs={12} sm={4}>
                  <div className={styles.order}>
                    <Box mr={1}>
                      <Typography color="secondary">
                        {en.label.stepOrder}
                      </Typography>
                    </Box>
                    <Select
                      value={substepOrder[value]}
                      onChange={(event) => handleOrderChange(event, value)}
                      classes={{ selectMenu: styles.selectMenu }}
                    >
                      {en.order.map((order, _index) => (
                        <MenuItem key={_index} value={_index}>
                          {order}
                        </MenuItem>
                      ))}
                    </Select>
                  </div>
                </Grid>
                <Grid item xs={12}>
                  {uploadedImage?.source ? (
                    <img
                      className={styles.uploadedImage}
                      src={uploadedImage.source}
                      alt={en.label.alt}
                    />
                  ) : null}
                  <Notes
                    handleInputChange={handleInputChange}
                    stepCount={stepCount}
                    tab={en.lesson.tabs[value] || 0}
                  />
                </Grid>
                <Box mt={8}>
                  <LearningTip />
                </Box>
              </Grid>
            </div>
          ) : null}
          {value === 2 ? (
            <div className={styles.container}>
              <form onSubmit={handleVideoLinkSubmit}>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      type="text"
                      placeholder={en.lesson.videoLink}
                      inputRef={linkRef}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={2}>
                    <Button
                      type="submit"
                      disableRipple
                      variant="contained"
                      color="primary"
                    >
                      {en.button.submit}
                    </Button>
                  </Grid>

                  <Grid item xs={12} sm={4}>
                    <div className={styles.order}>
                      <Box mr={1}>
                        <Typography color="secondary">
                          {en.label.stepOrder}
                        </Typography>
                      </Box>
                      <Select
                        value={substepOrder[value]}
                        onChange={(event) => handleOrderChange(event, value)}
                        classes={{ selectMenu: styles.selectMenu }}
                      >
                        {en.order.map((order, _index) => (
                          <MenuItem key={_index} value={_index}>
                            {order}
                          </MenuItem>
                        ))}
                      </Select>
                    </div>
                  </Grid>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      paddingLeft: "6vw",
                    }}
                  >
                    <YouTubeIcon
                      fontSize="large"
                      color="error"
                      onClick={(e) => {
                        linkRef.current.value = "https://www.youtube.com/";
                        handleVideoLinkSubmit(e);
                      }}
                    />
                  </div>

                  <Grid item xs={12}>
                    <Box m={2}>
                      <div className={styles.refContainer}>
                        <div ref={videoRef}></div>
                        {deleteRefButton && (
                          <div
                            className={styles.delete}
                            onClick={handleDeleteRefButtonClick}
                          >
                            <HighlightOffRoundedIcon />
                          </div>
                        )}
                      </div>
                    </Box>

                    <Notes
                      handleInputChange={handleInputChange}
                      stepCount={stepCount}
                      tab={en.lesson.tabs[value] || 0}
                    />
                  </Grid>
                  <Box mt={8}>
                    <LearningTip />
                  </Box>
                </Grid>
              </form>
            </div>
          ) : null}
          {value === 3 ? (
            <div className={styles.container}>
              <form onSubmit={handleLinkSubmit}>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      type="text"
                      placeholder={en.lesson.link}
                      inputRef={linkRef}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={2}>
                    <Button
                      type="submit"
                      disableRipple
                      variant="contained"
                      color="primary"
                    >
                      {en.button.submit}
                    </Button>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <div className={styles.order}>
                      <Box mr={1}>
                        <Typography color="secondary">
                          {en.label.stepOrder}
                        </Typography>
                      </Box>
                      <Select
                        value={substepOrder[value]}
                        onChange={(event) => handleOrderChange(event, value)}
                        classes={{ selectMenu: styles.selectMenu }}
                      >
                        {en.order.map((order, _index) => (
                          <MenuItem key={_index} value={_index}>
                            {order}
                          </MenuItem>
                        ))}
                      </Select>
                    </div>
                  </Grid>
                </Grid>
              </form>

              <Grid item xs={12}>
                <h3>Instructions how to publish slides to the web: </h3>
                <ol className={styles.listStyles}>
                  <li>
                    From{" "}
                    <a
                      href={GLOBAL_CONSTANTS.GOOGLE_SLIDES_LINK}
                      target="_blank"
                    >
                      {" "}
                      Slides
                    </a>{" "}
                    , select your document and then{" "}
                    <span className={styles.boldTextWeight}>File</span>{" "}
                    <ArrowForwardIcon className={styles.arrowDescription} />
                    <span className={styles.boldTextWeight}>Publish</span> to
                    the web.
                  </li>
                  <li>
                    Go to <span className={styles.boldTextWeight}>Embed</span>{" "}
                    tab <ArrowForwardIcon className={styles.arrowDescription} />
                    click <span className={styles.boldTextWeight}>Publish</span>
                    .{" "}
                  </li>
                  <li>Copy the link and paste it in the form above.</li>
                </ol>
              </Grid>

              <Box m={2}>
                <div className={styles.refContainer}>
                  <div ref={containerRef}></div>
                  {deleteLinkRefButton && (
                    <div
                      className={styles.delete}
                      onClick={handleDeleteLinkRefButtonClick}
                    >
                      <HighlightOffRoundedIcon />
                    </div>
                  )}
                </div>
              </Box>
              <Box m={2} className={styles.boxStyle}></Box>
              <Notes
                handleInputChange={handleInputChange}
                stepCount={stepCount}
                tab={en.lesson.tabs[value] || 0}
              />
              <Box mt={8}>
                <LearningTip />
              </Box>
            </div>
          ) : null}
          {value === 4 ? (
            <div className={styles.container}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={8}>
                  <DropZone
                    description={en.lesson.dropZonePDF}
                    handleImageUpload={handleImageUpload}
                    file={uploadedPDF}
                    accept={GLOBAL_CONSTANTS.INPUT_TYPE.PDF}
                    stepCount={stepCount}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <div className={styles.order}>
                    <Box mr={1}>
                      <Typography color="secondary">
                        {en.label.stepOrder}
                      </Typography>
                    </Box>
                    <Select
                      value={substepOrder[value]}
                      onChange={(event) => handleOrderChange(event, value)}
                      classes={{ selectMenu: styles.selectMenu }}
                    >
                      {en.order.map((order, _index) => (
                        <MenuItem key={_index} value={_index}>
                          {order}
                        </MenuItem>
                      ))}
                    </Select>
                  </div>
                </Grid>
                <Grid item xs={12}>
                  {uploadedPDF?.source ? (
                    <embed
                      src={uploadedPDF.source}
                      className={styles.pdf}
                      type="application/pdf"
                    />
                  ) : null}

                  <Notes
                    handleInputChange={handleInputChange}
                    stepCount={stepCount}
                    tab={en.lesson.tabs[value] || 0}
                  />
                </Grid>
                <Box mt={8}>
                  <LearningTip />
                </Box>
              </Grid>
            </div>
          ) : null}
          <Box p={2}>
            <Grid container>
              <Grid item xs={12}></Grid>
              <Grid item xs={10} />

              <Grid item xs={2} justify="flex-end" container>
                <Box mt={1.5}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleStepEditClose}
                  >
                    <Typography color="secondary" ref={submitRef}>
                      {en.button.submit}
                    </Typography>
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </>
      ) : (
        <>
          <Tabs
            classes={{ root: styles.tabs, indicator: styles.indicator }}
            disableHideScrollButtonsMobile
          >
            {
              <span className={styles.quitButton}>
                <HighlightOffRoundedIcon
                  fontSize="large"
                  onClick={handleQuitClick}
                />
              </span>
            }
          </Tabs>
          <div className={styles.container}>
            <Grid item xs={6}>
              <Typography variant="h6">
                {en.label.step + stepCount + `: ` + title}
              </Typography>
            </Grid>
            <CoverStepEdit
              handleQuitClick={handleQuitClick}
              handleImageUpload={handleImageUpload}
              uploadedImage={uploadedImage}
              handleInputChange={handleInputChange}
              stepDescriptions={stepDescriptions}
              stepCount={stepCount}
              handleStepEditClose={handleStepEditClose}
              stepImage={stepImage}
              setUploadedImage={setUploadedImage}
            />
          </div>
          <Box p={2}>
            <Grid container>
              <Grid item xs={12} className={styles.divStyle}>
                <div>
                  <Notes
                    handleInputChange={handleInputChange}
                    stepCount={stepCount}
                    tab={en.lesson.tabs[value] || 0}
                  />
                </div>
              </Grid>

              <Grid item xs={10} />

              <Grid item xs={2} justify="flex-end" container>
                <Box mt={1.5}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleStepEditClose}
                    ref={submitRef}
                  >
                    <Typography color="secondary">
                      {en.button.submit}
                    </Typography>
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </>
      )}
    </Dialog>
  );
};

export default StepEdit;
