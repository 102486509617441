import customClasses from "../../../../shared/helpers/customClasses";

const stepEditStyles = {
  thumbnail: {
    width: "100px",
  },
  container: {
    overflow: "auto",
    height: "600px",
    padding: "0.8em",
  },
  containerDiv: {
    height: "50%",
    overflow: "hidden auto",
  },
  images: {
    width: "250px",
  },
  textArea: {
    height: "55%",
    marginTop: "30px",
    height: "165px",
    whiteSpace: "pre-wrap !important",
  },
  customWording: {
    textAlign: "center",
    marginBottom: "32px",
  },
  tabs: {
    padding: "8px",
    backgroundColor: "#f7931f",
    color: "#675A3B",
    position: "relative",
    display: "flex",
    justifyContent: "center",
  },
  tabContainer: {
    backgroundColor: "#f7931f",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    paddingTop: "15px",
  },
  uploadedImage: {
    maxWidth: "0%",
    height: "auto",
  },
  indicator: {
    color: "#675A3B",
    backgroundColor: "#675A3B",
  },
  anchor: {
    textDecoration: "none",
  },
  customSearch: {
    display: "flex",
  },
  order: {
    display: "flex",
    alignItems: "baseline",
    justifyContent: "flex-end",
  },
  selectMenu: {
    width: "70px",
  },
  hiddenSubmit: {
    visibility: "hidden",
  },
  quitButton: {
    ...customClasses.quitButton,
    position: "fixed",
    top: "20px",
    right: "20px",
    color: "white",
  },
  scroll: {
    flex: "unset",
    width: "100%",
  },
  pdf: {
    width: "100%",
    height: "600px",
    objectFit: "scale-down",
  },

  stepCountContainer: {
    display: "flex",
    justifyContent: "space-between",
    paddingRight: "50px",
  },
  boxStyle: {
    marginBottom: "2rem",
    marginTop: "5rem",
  },
  divStyle: {
    marginTop: "-15rem",
  },
  quill: {
    "@media(max-width:1300px)": {
      height: "50px",
      marginTop: "5rem",
      marginBottom: "6rem",
    },
  },
  listStyles: { lineHeight: "2" },
  arrowDescription: { fontSize: "15px" },
  boldTextWeight: { fontWeight: "bolder" },
  refContainer: {
    position: "relative",
  },
  delete: {
    position: "absolute",
    top: "-25px",
    right: "-15px",
    color: "red",
    fontWeight: "900",
    cursor: "pointer",
    color: "red",
  },
  divContainer: {
    display: "flex",

    alignContent: "center",
    alignItems: "center",
  },
};

export default stepEditStyles;
