import React, { Fragment } from "react";
import AuthCard from "./app/modules/login/components/AuthCard/AuthCard";
import { Redirect, Route, Switch } from "react-router-dom";
import PrivateRoute from "./app/shared/components/PrivateRoute/PrivateRoute";
import ThemeProviderHOC from "./app/shared/components/Theme/ThemeProviderHOC";
import Homepage from "./app/modules/Dashboard/pages/Homepage/Homepage";
import Profile from "./app/modules/Dashboard/pages/Profile/Profile";
import CreateLesson from "./app/modules/Dashboard/pages/CreateLesson/CreateLesson";
import ListLessons from "./app/modules/Dashboard/pages/ListLessons/ListLessons";
import AssignedLesson from "./app/modules/Dashboard/pages/AssignedLesson/AssignedLesson";
import ConfirmAccount from "./app/shared/components/ConfirmAccount/ConfirmAccount";

function App() {
  return (
    <ThemeProviderHOC>
      <Fragment>
        <Switch>
          <Route path="/verify/:verifyToken" component={ConfirmAccount} />
          <Route exact path="/" component={AuthCard} />
          <Route path="/forgot-password" component={AuthCard} />
          <Route path="/change-password/:token" component={AuthCard} />
          <PrivateRoute path="/dashboard" component={Homepage} />
          <PrivateRoute path="/profile" component={Profile} />
          <PrivateRoute path="/create-lesson" component={CreateLesson} />
          <PrivateRoute exact path="/lessons" component={ListLessons} />
          <PrivateRoute path="/lessons/:id" component={AssignedLesson} />
          <Redirect exact to="/" />
        </Switch>
      </Fragment>
    </ThemeProviderHOC>
  );
}

export default App;
