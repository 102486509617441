const stepStyles = {
  popover: {
    pointerEvents: "none",
  },
  paper: {
    padding: "0.3em",
    maxWidth: "150px",
  },
  file: {
    display: "block",
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
  },
  colors: {
    Acquiring: "#FFEECA",
    Inquiring: "#C3FCF2",
    Producing: "#FD8B7C",
    Discussing: "#FF7EBC",
    Collaborating: "#00D8FF",
    Practising: "#00D6B1",
  },

  customStyles: {
    dropdownIndicator: (provided, state) => ({
      ...provided,
      padding: 0,
      margin: 0,
    }),
    indicatorSeparator: () => ({
      display: "none",
    }),
    control: (provided, state) => ({
      ...provided,
      border: "none",
      outline: "none",
      boxShadow: "none",
      "&:hover": {
        cursor: "pointer",
      },
      "&:focus": {
        border: "none",
        outline: "none",
      },
    }),
    container: (provided, state) => ({
      ...provided,
      border: "none",
      outline: "none",
      width: "100px",
      "&:focus": {
        border: "none",
        outline: "none",
      },
      "&:hover": {
        cursor: "pointer",
      },
    }),
  },
};

export default stepStyles;
