import React, { useState, useEffect } from "react";
import { Card, CardContent, Grid, Typography, Box, CircularProgress } from "@material-ui/core";
import logo from "../../../../assets/svg/logo.svg";
import styles from "./confirmAccount.module.scss";
import en from "../../../../assets/languages/en.json";
import { useParams, useHistory } from "react-router-dom";
import { dashboardService } from "../../../modules/Dashboard/dashboard.service";

const ConfirmAccount = () => {
  const [counter, setCounter] = useState(3);
  const [success, setSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const params = useParams();
  const history = useHistory();
  let interval = null;

  const fetchData = async () => {
    const response = await dashboardService.verifyAccount(params.verifyToken);
    if (response.success) {
      setSuccess(true);
    } else {
      setSuccess(false);
    }
    interval = setInterval(() => {
      setCounter((prevCounter) => prevCounter - 1);
    }, 1000);

    setIsLoading(false);
  };

  useEffect(() => {
    fetchData();
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (counter === 0) {
      history.push("/");
    }
  }, [counter]);

  return (
    <div className={styles["container"]}>
      {isLoading ? (
        <CircularProgress color="primary" />
      ) : (
        <Card>
          <CardContent>
            <Grid container>
              <Grid item container xs={12}>
                <img className={styles["container__hero"]} src={logo} />
              </Grid>
              <Grid item xs={12}>
                <Box my={2}>
                  <Typography align="center" textColor="primary" variant="h6">
                    {success ? en.redirectSuccess : en.redirectError}
                    {counter}
                    {en.seconds}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      )}
    </div>
  );
};

export default ConfirmAccount;
