import React, { useState } from "react";
import ReactQuill from "react-quill";
import en from "../../../../../assets/languages/en.json";
import stepEditStyles from "./StepEdit.styles";
import { Grid, makeStyles } from "@material-ui/core";
import { useSelector } from "react-redux";
const useStyles = makeStyles(stepEditStyles);

const Notes = ({ handleInputChange, stepCount, tab }) => {
  const styles = useStyles();
  const stepDescription = useSelector(
    (state) => state.dashboardReducer.stepDescriptions[stepCount + tab]
  );

  return (
    <Grid container>
      <Grid item xs={12}>
        <ReactQuill
          className={styles.textArea}
          value={stepDescription || " "}
          aria-label="empty textarea"
          formats={[
            "header",
            "font",
            "size",
            "bold",
            "italic",
            "underline",
            "strike",
            "list",
            "bullet",
            "indent",
            "link",
          ]}
          modules={{
            toolbar: [
              [{ header: "1" }, { header: "2" }, { font: [] }],
              [{ size: [] }],
              ["bold", "italic", "underline", "strike", "blockquote"],
              [
                { list: "ordered" },
                { list: "bullet" },
                { indent: "-1" },
                { indent: "+1" },
              ],
              ["link"],
              ["clean"],
            ],
            clipboard: {
              // toggle to add extra line breaks when pasting HTML:
              matchVisual: false,
            },
          }}
          placeholder={en.label.description}
          onChange={(content, delta, source, editor) => {
            handleInputChange(content);
          }}
          name="description"
          id="step-eight"
          theme={"snow"}
        />
      </Grid>
    </Grid>
  );
};

export default Notes;
