import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import AllInboxOutlinedIcon from "@material-ui/icons/AllInboxOutlined";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import Quote from "../../components/Quote/Quote";
import { makeStyles, Button, Typography, Box } from "@material-ui/core";
import homePageStyles from "./Homepage.styles";
import Carousel from "../../components/Carousel/Carousel";
import en from "../../../../../assets/languages/en.json";
import { Steps } from "intro.js-react";
import { tutorial } from "../../../../shared/constants/tutorial.constants";
import { actionHelpers } from "../../../../shared/helpers/actionHelpers";
import { authService } from "../../../login/auth.service";

const useStyles = makeStyles(homePageStyles);

const Homepage = () => {
  const styles = useStyles();
  const history = useHistory();

  const handleLessonCreation = () => {
    history.push("/create-lesson");
  };
  const handleLessonRedirect = () => {
    history.push("/lessons");
  };
  const user = JSON.parse(actionHelpers.getLocalStorage.user());

  return (
    <>
      <div className={styles.wrapper}>
        <div className={styles.container}>
          <Box m={4} width="100%">
            <div className={styles.container__quote}>
              <Quote />
            </div>
          </Box>
          <Box m={3}>
            <Button
              variant="contained"
              color="primary"
              disableRipple={true}
              onClick={handleLessonCreation}
              className="step-one"
            >
              <AddCircleIcon className={styles.buttons} />
              <Typography className={styles.buttons} variant="h4">
                <Box p={2}>{en.button.addLesson}</Box>
              </Typography>
            </Button>
          </Box>
          <Box m={3}>
            <Button
              variant="contained"
              color="primary"
              disableRipple={true}
              onClick={handleLessonRedirect}
              className="step-two"
            >
              <AllInboxOutlinedIcon className={styles.buttons} />
              <Typography className={styles.buttonsSmall} variant="h4">
                <Box p={2}>{en.button.myLessons}</Box>
              </Typography>
            </Button>
          </Box>
          <Carousel />
        </div>
      </div>
      <Steps
        enabled={true}
        steps={user.isTutorialChecked ? [] : tutorial.dashboardSteps}
        initialStep={0}
        onExit={() => console.log("done")}
        options={{
          showStepNumbers: true,
          showBullets: false,
          exitOnOverlayClick: true,
          doneLabel: "Done",
          nextLabel: "Next",
          showProgress: true,
        }}
      />
    </>
  );
};

export default Homepage;
