import axios from "axios";

const createAxiosInstance = (endpoint) => {
  return axios.create({
    baseURL: endpoint,
  });
};

export const AXIOS_CONSTANTS = {
  createAxiosInstance,
};
