const learningTipStyles = {
  divContainer: {
    display: "flex",

    alignContent: "center",
    alignItems: "center",
    marginBottom: "15px",
  },
  imgStyles: {
    width: "70px",
    marginRight: "2rem",
  },
  linkStyles: {
    textDecoration: "none",
  },
  divStyle: {
    display: "flex",
  },
  pRight: {
    paddingRight: "1rem",
  },
  activityStyles: {
    width: "500px",
    borderLeft: "1px solid #333",
    paddingLeft: "1rem",
  },
  divStyle: {
    display: "flex",
    paddingBottom: "1rem",
  },
  mLeft: {
    marginLeft: "16rem",
  },
};
export default learningTipStyles;
