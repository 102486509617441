import customClasses from "../../../shared/helpers/customClasses";
import { makeStyles } from "@material-ui/core";

const modalStyles = makeStyles((theme) => ({
  indicator: {
    color: theme.palette.primary.tabs,
    backgroundColor: theme.palette.primary.tabs,
  },
  default: {
    padding: "8px",
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.tabs,
    position: "relative",
  },
  container: {
    width: "100%",
    //padding: "8px",
    margin: "0 auto",
    position: "relative",
    // height: "100%",
  },
  containerContent: {
    width: "100%",
    height: "960px",
    // padding: "8px",
    margin: "0 auto",
    position: "relative",
  },
  tabContainer: {
    backgroundColor: theme.palette.primary.main,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    justifyContent: "center",
  },
  iconsContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
  },
  editIcon: {
    height: "24px",
    width: "24px",
    marginRight: "10px",
  },
  notes: {
    backgroundColor: theme.palette.primary.notes,
    padding: "8px",
    width: "100%",
    height: "100%",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    minHeight: "160px",
  },
  heroGrid: {
    height: "700px",
    paddingTop: "16px!important",
  },
  notesGrid: {
    display: "inline-block",
  },
  notesContent: {
    whiteSpace: "normal",
    wordWrap: "break-word",
  },
  notesLabel: {
    borderBottom: "1px solid black",
  },
  anchor: {
    fontWeight: 900,
    "&:hover": {
      textDecoration: "underline",
      cursor: "pointer",
    },
  },
  Acquiring: {
    padding: "8px",
    backgroundColor: theme.palette.primary.acquiring,
    color: theme.palette.primary.tabs,
  },
  Inquiring: {
    padding: "8px",
    backgroundColor: "#C3FCF2",
    color: theme.palette.primary.tabs,
  },
  Producing: {
    padding: "8px",
    backgroundColor: theme.palette.primary.producing,
    color: theme.palette.primary.tabs,
  },
  Discussing: {
    padding: "8px",
    backgroundColor: theme.palette.primary.discussing,
    color: theme.palette.primary.tabs,
  },
  Collaborating: {
    padding: "8px",
    backgroundColor: theme.palette.primary.collaborating,
    color: theme.palette.primary.tabs,
  },
  Practising: {
    padding: "8px",
    backgroundColor: theme.palette.primary.practising,
    color: theme.palette.primary.tabs,
  },
  nextArrow: {
    position: "absolute",
    color: "#aaa",
    top: "220px",
    right: "0px",
  },
  previousArrow: {
    color: "#aaa",
    transform: "rotate(-180deg)",
    position: "absolute",
    top: "220px",
    left: "2px",
  },
  arrowSizing: {
    fontSize: "3rem",
  },
  quitButton: {
    ...customClasses.quitButton,
    position: "fixed",
    top: "20px",
    right: "20px",
    color: "white",
  },
  quitButtonFullWindow: {
    position: "fixed",
    top: "0",
    right: "0",
    color: "white",
  },

  moveButton: {
    height: "50px",
    width: "70px",
    position: "fixed",
    bottom: "50px",
    right: "50px",
  },
  noIndicator: {
    display: "none",
  },
  stepDescription: {
    paddingTop: "16px",
    paddingLeft: "32px",
    paddingRight: "32px",
    "& :nth-child(2)": {
      textAlign: "right",
    },
  },
  windowSize: {
    "&:hover": {
      cursor: "pointer",
    },
  },
  uploadedItems: {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "red!important",
  },
  divStyle: {
    display: "flex",
    paddingBottom: "1rem",
  },
  mLeft: {
    marginLeft: "15rem",
  },
  linkStyles: {
    display: "flex",
    alignItems: "center",
  },
  mLinkIcon: {
    marginLeft: "1rem",
  },
}));

export default modalStyles;
