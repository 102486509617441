import React, { useState } from "react";
import { useSelector } from "react-redux";
import {
  Card,
  CardContent,
  Typography,
  TextField,
  Box,
  makeStyles,
  Popover,
  Button,
  Snackbar,
  Grid,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import profileCardStyles from "./ProfileCard.styles";
import en from "../../../../../assets/languages/en.json";
import LockOpenRoundedIcon from "@material-ui/icons/LockOpenRounded";
import { GLOBAL_CONSTANTS } from "../../../../shared/constants/global.constants";
import { dashboardService } from "../../dashboard.service";

const useStyles = makeStyles(profileCardStyles);
let severity = GLOBAL_CONSTANTS.SEVERITY.SUCCESS;

const ProfileCard = () => {
  const styles = useStyles();
  const user = useSelector((state) =>
    JSON.parse(state.baseAuthReducer.authenticatedUser)
  );

  const [inputs, setInputs] = useState({
    newPassword: "",
    confirmNewPassword: "",
  });

  const [passwordError, setPasswordError] = useState({
    isPasswordStrong: false,
    isPasswordMatching: false,
  });

  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [anchorEl, setAnchorEl] = useState(null);

  const handlePopover = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handlePasswordChange = (e) => {
    setInputs((prevInputs) => {
      return {
        ...prevInputs,
        [e.target.name]: e.target.value,
      };
    });
  };

  const validatePassword = (password, confirmPassword) => {
    const arePasswordsMatching = password === confirmPassword;

    const hasPasswordSpecialChars = new RegExp(
      GLOBAL_CONSTANTS.REGEX.PASSWORD_VALIDATION
    ).test(password);

    const isPasswordLong =
      password.length > GLOBAL_CONSTANTS.PASSWORD_MINIMUM_LENGTH;

    const isPasswordStrong = hasPasswordSpecialChars && isPasswordLong;

    return {
      arePasswordsMatching,
      isPasswordStrong,
    };
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    setPasswordError({
      isPasswordStrong: false,
      isPasswordMatching: false,
    });

    const validation = validatePassword(
      inputs.newPassword,
      inputs.confirmNewPassword
    );

    if (!validation.arePasswordsMatching) {
      setPasswordError((prevPasswordError) => {
        return {
          ...prevPasswordError,
          isPasswordMatching: true,
        };
      });
    }

    if (!validation.isPasswordStrong) {
      setPasswordError((prevPasswordError) => {
        return {
          ...prevPasswordError,
          isPasswordStrong: true,
        };
      });
    }

    if (validation.arePasswordsMatching && validation.isPasswordStrong) {
      setIsLoading(true);
      const response = await dashboardService.updateUser(inputs);

      if (response.success) {
        setSuccess(true);
      }

      if (response.error) {
        severity = GLOBAL_CONSTANTS.SEVERITY.ERROR;

        setError(true);
      }
      setIsLoading(false);
    }
  };

  const handleCloseAlert = (event, reason) => {
    if (reason === GLOBAL_CONSTANTS.CLICK_EVENTS.CLICKAWAY) {
      return;
    }

    success ? setSuccess(false) : setError(false);
  };

  const isOpen = Boolean(anchorEl);
  const id = isOpen ? en.label.alt : undefined;

  return (
    <>
      <Card classes={{ root: styles.root }}>
        <CardContent>
          <Box p={2}>
            <Typography variant="h5" color="primary">
              {en.label.myProfile}
            </Typography>
          </Box>
          <div>
            <form className={styles.form} onSubmit={handleSubmit}>
              <TextField
                label={en.label.name}
                fullWidth
                required
                type="text"
                defaultValue={user.name}
                inputProps={{
                  readOnly: true,
                }}
                aria-describedby={id}
                onClick={handlePopover}
              />
              <TextField
                label={en.label.surname}
                fullWidth
                required
                type="text"
                defaultValue={user.surname}
                inputProps={{
                  readOnly: true,
                }}
                aria-describedby={id}
                onClick={handlePopover}
              />
              <TextField
                label={en.label.email}
                fullWidth
                required
                type="text"
                defaultValue={user.email}
                inputProps={{
                  readOnly: true,
                }}
                aria-describedby={id}
                onClick={handlePopover}
              />
              <Box mt={3} p={1} display="flex" alignItems="center">
                <LockOpenRoundedIcon color="primary" />
                <Typography
                  variant="body1"
                  color="secondary"
                  className={styles.form__passLabel}
                >
                  {en.label.changePassword}
                </Typography>
              </Box>
              <TextField
                label={en.label.newPassword}
                fullWidth
                required
                type="password"
                name="newPassword"
                onChange={handlePasswordChange}
                error={passwordError.isPasswordStrong ? true : false}
                helperText={
                  passwordError.isPasswordStrong
                    ? en.authentication.passwordStrength
                    : null
                }
              />
              <TextField
                label={en.label.confirmNewPassword}
                fullWidth
                required
                type="password"
                name="confirmNewPassword"
                onChange={handlePasswordChange}
                error={passwordError.isPasswordMatching ? true : false}
                helperText={
                  passwordError.isPasswordMatching
                    ? en.authentication.passwordMatch
                    : null
                }
              />
              <Button
                type="submit"
                disableRipple
                variant="contained"
                color="primary"
                className={styles.form__button}
                disabled={isLoading}
              >
                <Typography color="secondary">{en.button.submit}</Typography>
              </Button>
            </form>
            <Popover
              id={id}
              open={isOpen}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
            >
              <Box p={1}>
                <Typography color="secondary">{en.label.readOnly}</Typography>
              </Box>
            </Popover>
          </div>
        </CardContent>
      </Card>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        open={success || error}
        autoHideDuration={GLOBAL_CONSTANTS.DELAY.SNACKBAR}
        onClose={handleCloseAlert}
      >
        <Alert severity={severity}>
          {success && en.alert.passwordSuccess}
          {error && en.alert.passwordError}
        </Alert>
      </Snackbar>
    </>
  );
};

export default ProfileCard;
