import React, { useEffect, useState } from "react";
import { Card, CardContent, makeStyles, Box, Typography } from "@material-ui/core";
import lessonSnippetStyles from "./LessonSnippet.styles";
import logo from "../../../../../assets/svg/logo.svg";
import en from "../../../../../assets/languages/en.json";
import LinkIcon from "@material-ui/icons/Link";
import SettingsOverscanRoundedIcon from "@material-ui/icons/SettingsOverscanRounded";
import LessonPreview from "../../components/LessonPreview/LessonPreview";
import { useHistory } from "react-router-dom";
import DeleteForeverOutlinedIcon from "@material-ui/icons/DeleteForeverOutlined";
import { dashboardService } from "../../dashboard.service";
import editIcon from "../../../../../assets/svg/edit.svg";
import Tooltip from "@material-ui/core/Tooltip";
import DraftsIcon from "@material-ui/icons/Drafts";
import FileCopyIcon from "@material-ui/icons/FileCopy";

const useStyles = makeStyles(lessonSnippetStyles);

const LessonSnippet = ({ lesson, fetchLessons, setIsOpen, confirm, setConfirm, isOpen }) => {
  const styles = useStyles();
  const [preview, setPreview] = useState(false);
  const [linkColor, setLinkColor] = useState("null");
  const [showCopyText, setShowCopyText] = useState(false);
  const history = useHistory();
  let { formContent, topic, id: lessonId } = lesson;
  const { image, title, description, subject, week, date, day, hour } = JSON.parse(formContent);

  topic = topic.slice(1, topic.length - 1);

  const handlePreviewClick = () => {
    setPreview(true);
  };

  const handleEditClick = () => {
    history.push("/create-lesson", { lessonId: lessonId });
  };

  const handleDeleteClick = async () => {
    setIsOpen({ state: true, id: lessonId });
  };

  const handleDelete = async () => {
    const response = await dashboardService.deleteLessonById(lessonId);
    if (response.success) {
      fetchLessons();
      setIsOpen(false);
    }
    setConfirm(false);
  };

  useEffect(() => {
    if (confirm && isOpen.id === lessonId) {
      handleDelete();
    }
  }, [confirm]);

  const hoverOn = () => {
    setLinkColor("secondary");
  };

  const hoverOff = () => {
    setLinkColor("null");
  };

  const handleCopyText = () => {
    setShowCopyText(true);
    setTimeout(() => {
      setShowCopyText(false);
    }, 1000);
  };

  const handleCopyLessonLink = () => {
    navigator.clipboard.writeText(`${window.location.origin}/lessons/${lessonId}`);
    handleCopyText();
  };

  const duplicateLesson = async () => {
    await dashboardService.duplicateLesson(lesson.id).then(() => {
      fetchLessons();
    });
  };

  return (
    <>
      <Box p={1}>
        <Card classes={{ root: styles.container }}>
          <CardContent>
            <div className={styles.containerBackground}>
              {image ? (
                <img src={image} className={styles.background} />
              ) : (
                <img src={logo} className={styles.background} />
              )}
              <div className={styles.shade}>
                <div>
                  <Typography variant="subtitle1">{topic ? topic : "No topic yet"}</Typography>
                </div>
                <div className={styles.subject}>
                  <Typography variant="subtitle2" color="textSecondary">
                    {subject + en.label.noteColon + title}
                  </Typography>
                  <Typography variant="subtitle2" color="textSecondary">
                    {day.length > 0 ? day : ""}
                  </Typography>
                </div>
                <div className={styles.description}>
                  <Typography variant="caption" color="textSecondary">
                    {description.length > 0 ? description : en.label.noDescription}
                  </Typography>
                </div>
                <div className={styles.footer}>
                  <div>
                    <Typography variant="caption" component="span">
                      {en.lesson.academicWeek} {week ? week : en.lesson.na}
                    </Typography>
                  </div>

                  <div className={styles.icons}>
                    {showCopyText && <div className={styles.copyText}>{en.label.copied}</div>}

                    {lesson.is_draft ? (
                      <button disabled className={styles.draft}>
                        Draft
                      </button>
                    ) : (
                      <div onMouseEnter={hoverOn} onMouseLeave={hoverOff}>
                        <Tooltip title="Copy Link">
                          <LinkIcon fontSize={"50px"} color={linkColor} onClick={() => handleCopyLessonLink()} />
                        </Tooltip>
                      </div>
                    )}
                    <FileCopyIcon onClick={duplicateLesson} fontSize="small" className={styles.duplicateLessonIcon} />
                    <div title={en.lesson.present}>
                      <Tooltip title="Preview">
                        <SettingsOverscanRoundedIcon onClick={handlePreviewClick} />
                      </Tooltip>
                    </div>
                    <div title={en.lesson.edit}>
                      <Tooltip title="Edit">
                        <img className={styles.editIcon} src={editIcon} onClick={handleEditClick} />
                      </Tooltip>
                    </div>
                    <div title={en.lesson.delete}>
                      <Tooltip title="Delete">
                        <DeleteForeverOutlinedIcon onClick={handleDeleteClick} />
                      </Tooltip>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </CardContent>
        </Card>
      </Box>
      {preview && <LessonPreview setPreview={setPreview} lessonId={lessonId} />}
    </>
  );
};

export default LessonSnippet;
