import React, { useState } from "react";
import SignUp from "../SignUp/SignUp";
import SignIn from "../SignIn/SignIn";
import Card from "@material-ui/core/Card";
import { useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import en from "../../../../../assets/languages/en.json";
import authCardStyles from "./AuthCard.styles";
import { useHistory } from "react-router-dom";
import ForgotPassword from "../ForgotPassword/ForgotPassword";
import logo from "../../../../../assets/svg/logo.svg";
import people from "./img/people.png";
import circleWithIcons from "./img/circleWithIcons.png";

const useStyles = makeStyles(authCardStyles);

const AuthCard = () => {
  const styles = useStyles();
  const history = useHistory();
  const [form, setForm] = useState(false);
  const [resetPassword, setResetPassword] = useState(
    history.location.pathname.includes("forgot-password")
  );
  const isAuthenticated = useSelector(
    (state) => state.baseAuthReducer.isAuthenticated
  );
  const authenticatedUser = useSelector(
    (state) => state.baseAuthReducer.authenticatedUser
  );

  const newPassword = history.location.pathname.includes("change");

  const handleFormChange = (reset) => {
    if (reset) {
      history.push("/forgot-password");
      setForm(false);
      setResetPassword(true);
    } else {
      history.push("/");
      !resetPassword && setForm((prevForm) => !prevForm);
      setResetPassword(false);
    }
  };

  return !!(isAuthenticated && authenticatedUser) ? (
    <Redirect to="/dashboard" />
  ) : (
    <div className={styles.authBody}>
      <img src={logo} className={styles.logo} />
      <img src={people} className={styles.people} />
      <img src={circleWithIcons} className={styles.circleIcons} />
      <Grid container display="flex" justify="flex-end">
        <Grid item>
          <Card className={styles.card}>
            <Grid
              container
              className={styles.container}
              display="flex"
              justify="center"
            >
              <Grid item xs={12}>
                <CardContent>
                  {newPassword ? (
                    <ForgotPassword />
                  ) : form ? (
                    <SignUp setForm={setForm} />
                  ) : (
                    <SignIn isReset={resetPassword} />
                  )}
                </CardContent>
              </Grid>
              <Grid item xs={12} style={{ marginTop: "5px" }}>
                <Typography
                  onClick={() => handleFormChange()}
                  variant="body2"
                  color="textPrimary"
                  className={styles.underline}
                >
                  {form || resetPassword
                    ? en.authentication.signIn
                    : en.authentication.signUp}
                </Typography>

                <Typography
                  onClick={() => handleFormChange("reset")}
                  variant="body2"
                  color="textPrimary"
                  className={styles.underline}
                >
                  Reset password
                </Typography>
              </Grid>
            </Grid>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
};

export default AuthCard;
