import React from "react";
import { Grid, Box } from "@material-ui/core";
import ProfileCard from "../../components/ProfileCard/ProfileCard";
import SubjectLessonList from "../../components/SubjectLessonList/SubjectLessonList";

const Profile = () => {
  return (
    <Box mt={5} p={0}>
      <Grid container display="flex" justify="center" spacing={2}>
        <Grid item md={4} sm={6} xs={12}>
          <ProfileCard />
        </Grid>
        <Grid item md={3} sm={6} xs={12}>
          <SubjectLessonList />
        </Grid>
      </Grid>
    </Box>
  );
};

export default Profile;
