const dashboardSteps = [
  {
    title: "Create a lesson",
    intro:
      'Click here to create a new lesson',
    element: ".step-one",
  },
  {
    title: "View your saved lessons",
    intro: "Click here to view all your existing lessons",
    element: ".step-two",
  },
];

const lessonFormSteps = [
  {
    title: "Lesson topic",
    intro:
      'Insert topic of new lesson (ex. "Cyber security")',
    element: ".step-one",
  },
  {
    title: "Lesson Unit",
    intro:
      'Insert unit of new lesson (ex. "Unit 1")',
    element: ".step-two",
  },
  {
    title: "Lesson subject",
    intro: "Insert subject to which this lesson will belong to (ex.'Information theory')",
    element: ".step-three",
  },
  {
    title: "Academic week",
    intro: "Assign this lesson to an academic week if applicable",
    element: ".step-four",
  },
  {
    title: "Day of week",
    intro: "Assign this lesson to an academic day if applicable",
    element: ".step-five",
  },
  {
    title: "Hour",
    intro: "Assign this lesson to an academic hour if applicable",
    element: ".step-six",
  },
   {
    title: "Upload lesson image",
    intro: "Upload an image that will be displayed as a thumbnail in your lesson list",
    element: ".step-seven",
  },
  {
    title: "Lesson description",
    intro: "Type a short descriptive text that may come in handy when distinguishing lessons of similar content",
    element: "#step-eight",
  },
];

const lessonCircleSteps = [
   {
    title: "Search topic of interest",
    intro:
      'Insert the topic of your lesson and press enter to trigger automatic search results for each colored box named as "Step"</br> You can not do any other action in this interface unless you type a lesson topic here',
    element: ".step-one",
   
  },
  {
    title: "First step",
    intro:
      'Unlike the other steps, the first step will not fetch data from google, here you can put your own input that will be displayed in the very first slide of the presantation of the lesson',
    element: ".step-three",
  },
   {
    title: "Edit results for this step",
    intro:
      'After the search is done, you can click on the top right corner (pencil icon) and edit this step',
    element: ".step-two",
   
  },
   {
    title: "Preview step",
    intro:
      'Click on the left down corner (rectangular icon) to  check the final result of this single step, this is how this step will display when the lesson will be presented',
    element: ".step-two",
  },
   
   {
    title: "Preview final lesson",
    intro:
      'This is the final preview of all the  steps of this lesson organized in a slide show like presantation',
    element: ".step-four",
  },
   {
    title: "Save lesson",
    intro:
      'Once you are satisfied with the final lesson preview click here to save your lesson and then you will be redirected to your existing lessons list',
    element: ".step-five",
  },
];

export const tutorial = {
  dashboardSteps,
  lessonFormSteps,
  lessonCircleSteps,
};
